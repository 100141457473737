import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
// import OrganizationDataTable from "../../components/datatable/OrganizationDataTable";
import UsageProgressionReportDataTable from "../../components/datatable/UsageProgressionReportDataTable";
const UsageProgressionReportPage = (props) => {
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <UsageProgressionReportDataTable  />
      </div>
    </div>
  )
}

export default UsageProgressionReportPage