import "./RegionalServerTest.scss";

import React from 'react';
import {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import AssessmentControllersTables from './AssessmentControllersTables';
import BaseControllersTables from './BaseControllersTables';
import LoginPanel from './LoginPanel';
import ODataFilter from './ODataFilter';
import { AssessmentListData } from '../../data/AssessmentListData';
import ControllerFilter from './ControllerFilter';
import { BaseControllerData } from '../../data/BaseControllersData';
import AssessmentGroupControllersTables from './AssessmentGroupControllerTables';
import { AssessmentGroupListData } from '../../data/AssessmentGroupListData';
import ProtocolControllersTables from './ProtocolControllersTables';
import { ProtocolListData } from '../../data/ProtocolListData';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CustomFieldsListData } from "../../data/CustomFieldsListData";
import CustomFieldControllersTables from "./CustomFieldControllersTables";
import { set } from "date-fns";


const RegionalServerTest = () => {

    const [value, setValue] = useState('1');
    const [skip, setSkip] = useState(0);
    const [top, setTop] = useState(1000);
    const [filter, setFilter] = useState("");
    const [orderby, setOrderby] = useState("UpdatedAt");
    // const [count, setCount] = useState(0);
    const [token, setToken] = useState("");
    const [displayLoginMessage, setDisplayLoginMessage] = useState(false);
    const [displayLoginError,setDisplayLoginError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serverURL, setServerURL] = useState("");
    const [search, setSearch] = useState(false);
    const [syncMode, setSyncMode] = useState(false);
    const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);
    
    const [controller, setController] = useState("All");
    const [clinicList, setClinicList] = useState([]);
    const [practitionerList, setPractitionerList] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [balanceList, setBalanceList] = useState([]);
    const [functionalList, setFunctionalList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [uploadFileList, setUploadFileList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [cfMetadataList, setCfMetadataList] = useState([]);

    const [ohsList, setOhsList] = useState([]);
    const [revLungeList, setRevLungeList] = useState([]);
    const [romList, setRomList] = useState([]);
    const [sidePostList, setSidePostList] = useState([]);
    const [slhList, setSlhList] = useState([]);
    const [vjList, setVjList] = useState([]);
    const [waList, setWaList] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [balanceGroupList, setBalanceGroupList] = useState([]);
    const [functionalGroupList, setFunctionalGroupList] = useState([]);
    const [ohsGroupList, setOhsGroupList] = useState([]);
    const [revLungeGroupList, setRevLungeGroupList] = useState([]);
    const [romGroupList, setRomGroupList] = useState([]);
    const [sidePostGroupList, setSidePostGroupList] = useState([]);
    const [slhGroupList, setSlhGroupList] = useState([]);
    const [vjGroupList, setVjGroupList] = useState([]);
    const [waGroupList, setWaGroupList] = useState([]);
    const [assessmentGroupList, setAssessmentGroupList] = useState([]);
    const [assessmentFrameList, setAssessmentFrameList] = useState([]);
    const [workflowList, setWorkflowList] = useState([]);
    
    const [equipmentList, setEquipmentList] = useState([]);
    const [equipmentTextList, setEquipmentTextList] = useState([]);
    const [exerciseList, setExerciseList] = useState([]);
    const [exerciseTextList, setExerciseTextList] = useState([]);
    const [protocolList, setProtocolList] = useState([]);
    const [protocolSessionList, setProtocolSessionList] = useState([]);
    const [correctivesList, setCorrectivesList] = useState([]);
    const [correctiveProtocolList, setCorrectiveProtocolList] = useState([]);
    
    const [clinicCFList, setClinicCFList] = useState([]);
    const [practitionerCFList, setPractitionerCFList] = useState([]);
    const [patientCFList, setPatientCFList] = useState([]);
    const [assessmentCFList, setAssessmentCFList] = useState([]);
    const [workflowCFList, setWorkflowCFList] = useState([]);

    const [skipBalance, setSkipBalance] = useState(0);
    const [skipFunctional, setSkipFunctional] = useState(0);
    const [skipAddress, setSkipAddress] = useState(0);
    const [skipPhoneNumber, setSkipPhoneNumber] = useState(0);
    const [skipOhs, setSkipOhs] = useState(0);
    const [skipRevLunge, setSkipRevLunge] = useState(0);
    const [skipRom, setSkipRom] = useState(0);
    const [skipSidePost, setSkipSidePost] = useState(0);
    const [skipSlh, setSkipSlh] = useState(0);
    const [skipVj, setSkipVj] = useState(0);
    const [skipWa, setSkipWa] = useState(0);
    const [skipClinic, setSkipClinic] = useState(0);
    const [skipPractitioner, setSkipPractitioner] = useState(0);
    const [skipPatient, setSkipPatient] = useState(0);
    const [skipSubscriptions, setSkipSubscriptions] = useState(0);
    const [skipUploadFiles, setSkipUploadFiles] = useState(0);
    const [skipPermission, setSkipPermission] = useState(0);
    const [skipBalanceGroup, setSkipBalanceGroup] = useState(0);
    const [skipFunctionalGroup, setSkipFunctionalGroup] = useState(0);
    const [skipOhsGroup, setSkipOhsGroup] = useState(0);
    const [skipRevLungeGroup, setSkipRevLungeGroup] = useState(0);
    const [skipRomGroup, setSkipRomGroup] = useState(0);
    const [skipSidePostGroup, setSkipSidePostGroup] = useState(0);
    const [skipSlhGroup, setSkipSlhGroup] = useState(0);
    const [skipVjGroup, setSkipVjGroup] = useState(0);
    const [skipWaGroup, setSkipWaGroup] = useState(0);
    const [skipAssessmentGroup, setSkipAssessmentGroup] = useState(0);
    const [skipAssessmentFrame, setSkipAssessmentFrame] = useState(0);
    const [skipWorkflow, setSkipWorkflow] = useState(0);
    const [skipEquipment, setSkipEquipment] = useState(0);
    const [skipEquipmentText, setSkipEquipmentText] = useState(0);
    const [skipExercise, setSkipExercise] = useState(0);
    const [skipExerciseText, setSkipExerciseText] = useState(0);
    const [skipProtocol, setSkipProtocol] = useState(0);
    const [skipProtocolSession, setSkipProtocolSession] = useState(0);
    const [skipCorrectives, setSkipCorrectives] = useState(0);
    const [skipCorrectiveProtocol, setSkipCorrectiveProtocol] = useState(0);
    const [skipClinicCF, setSkipClinicCF] = useState(0);
    const [skipPractitionerCF, setSkipPractitionerCF] = useState(0);
    const [skipPatientCF, setSkipPatientCF] = useState(0);
    const [skipAssessmentCF, setSkipAssessmentCF] = useState(0);
    const [skipWorkflowCF, setSkipWorkflowCF] = useState(0);
    const [skipOrganization, setSkipOrganization] = useState(0);
    const [skipCfMetadata, setSkipCfMetadata] = useState(0);


    const [clinicError, setClinicError] = useState("");
    const [practitionerError, setPractitionerError] = useState("");
    const [patientError, setPatientError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [subscriptionError, setSubscriptionError] = useState("");
    const [uploadFileError, setUploadFileError] = useState("");
    const [balanceError, setBalanceError] = useState("");
    const [functionalError, setFunctionalError] = useState("");
    const [ohsError, setOhsError] = useState("");
    const [revLungeError, setRevLungeError] = useState("");
    const [romError, setRomError] = useState("");
    const [sidePostError, setSidePostError] = useState("");
    const [slhError, setSlhError] = useState("");
    const [vjError, setVjError] = useState("");
    const [waError, setWaError] = useState("");
    const[permissionError, setPermissionError] = useState("");
    const [balanceGroupError, setBalanceGroupError] = useState("");
    const [functionalGroupError, setFunctionalGroupError] = useState("");
    const [ohsGroupError, setOhsGroupError] = useState("");
    const [revLungeGroupError, setRevLungeGroupError] = useState("");
    const [romGroupError, setRomGroupError] = useState("");
    const [sidePostGroupError, setSidePostGroupError] = useState("");
    const [slhGroupError, setSlhGroupError] = useState("");
    const [vjGroupError, setVjGroupError] = useState("");
    const [waGroupError, setWaGroupError] = useState("");
    const [assessmentGroupError, setAssessmentGroupError] = useState("");
    const [assessmentFrameError, setAssessmentFrameError] = useState("");
    const [workflowError, setWorkflowError] = useState("");
    const [equipmentError, setEquipmentError] = useState("");
    const [equipmentTextError, setEquipmentTextError] = useState("");
    const [exerciseError, setExerciseError] = useState("");
    const [exerciseTextError, setExerciseTextError] = useState("");
    const [protocolError, setProtocolError] = useState("");
    const [protocolSessionError, setProtocolSessionError] = useState("");
    const [correctivesError, setCorrectivesError] = useState("");
    const [correctiveProtocolError, setCorrectiveProtocolError] = useState("");
    const [clinicCFError, setClinicCFError] = useState("");
    const [practitionerCFError, setPractitionerCFError] = useState("");
    const [patientCFError, setPatientCFError] = useState("");
    const [assessmentCFError, setAssessmentCFError] = useState("");
    const [workflowCFError, setWorkflowCFError] = useState("");
    const [organizationError, setOrganizationError] = useState("");
    const [cfMetadataError, setCfMetadataError] = useState("");


    const [clinicLoading, setClinicLoading] = useState(false);
    const [practitionerLoading, setPractitionerLoading] = useState(false);
    const [patientLoading, setPatientLoading] = useState(false);
    const [addressLoading, setAddressLoading] = useState(false);
    const [phoneNumberLoading, setPhoneNumberLoading] = useState(false);
    const [subscriptionLoading, setSubscriptionLoading] = useState(false);
    const [uploadFileLoading, setUploadFileLoading] = useState(false);
    const [balanceLoading, setBalanceLoading] = useState(false);
    const [functionalLoading, setFunctionalLoading] = useState(false);
    const [ohsLoading, setOhsLoading] = useState(false);
    const [revLungeLoading, setRevLungeLoading] = useState(false);
    const [romLoading, setRomLoading] = useState(false);
    const [sidePostLoading, setSidePostLoading] = useState(false);
    const [slhLoading, setSlhLoading] = useState(false);
    const [vjLoading, setVjLoading] = useState(false);
    const [waLoading, setWaLoading] = useState(false);
    const [permissionLoading, setPermissionLoading] = useState(false);
    const [balanceGroupLoading, setBalanceGroupLoading] = useState(false);
    const [functionalGroupLoading, setFunctionalGroupLoading] = useState(false);
    const [ohsGroupLoading, setOhsGroupLoading] = useState(false);
    const [revLungeGroupLoading, setRevLungeGroupLoading] = useState(false);
    const [romGroupLoading, setRomGroupLoading] = useState(false);
    const [sidePostGroupLoading, setSidePostGroupLoading] = useState(false);
    const [slhGroupLoading, setSlhGroupLoading] = useState(false);
    const [vjGroupLoading, setVjGroupLoading] = useState(false);
    const [waGroupLoading, setWaGroupLoading] = useState(false);
    const [assessmentGroupLoading, setAssessmentGroupLoading] = useState(false);
    const [assessmentFrameLoading, setAssessmentFrameLoading] = useState(false);
    const [workflowLoading, setWorkflowLoading] = useState(false);
    const [equipmentLoading, setEquipmentLoading] = useState(false);
    const [equipmentTextLoading, setEquipmentTextLoading] = useState(false);
    const [exerciseLoading, setExerciseLoading] = useState(false);
    const [exerciseTextLoading, setExerciseTextLoading] = useState(false);
    const [protocolLoading, setProtocolLoading] = useState(false);
    const [protocolSessionLoading, setProtocolSessionLoading] = useState(false);
    const [correctivesLoading, setCorrectivesLoading] = useState(false);
    const [correctiveProtocolLoading, setCorrectiveProtocolLoading] = useState(false);
    const [clinicCFLoading, setClinicCFLoading] = useState(false);
    const [practitionerCFLoading, setPractitionerCFLoading] = useState(false);
    const [patientCFLoading, setPatientCFLoading] = useState(false);
    const [assessmentCFLoading, setAssessmentCFLoading] = useState(false);
    const [workflowCFLoading, setWorkflowCFLoading] = useState(false);
    const [organizationLoading, setOrganizationLoading] = useState(false);
    const [cfMetadataLoading, setCfMetadataLoading] = useState(false);

    const [clinicQuery, setClinicQuery] = useState(false);
    const [practitionerQuery, setPractitionerQuery] = useState(false);
    const [patientQuery, setPatientQuery] = useState(false);
    const [addressQuery, setAddressQuery] = useState(false);
    const [phoneNumberQuery, setPhoneNumberQuery] = useState(false);
    const [subscriptionQuery, setSubscriptionQuery] = useState(false);
    const [uploadFileQuery, setUploadFileQuery] = useState(false);
    const [balanceQuery, setBalanceQuery] = useState(false);
    const [functionalQuery, setFunctionalQuery] = useState(false);
    const [ohsQuery, setOhsQuery] = useState(false);
    const [revLungeQuery, setRevLungeQuery] = useState(false);
    const [romQuery, setRomQuery] = useState(false);
    const [sidePostQuery, setSidePostQuery] = useState(false);
    const [slhQuery, setSlhQuery] = useState(false);
    const [vjQuery, setVjQuery] = useState(false);
    const [waQuery, setWaQuery] = useState(false);
    const [permissionQuery, setPermissionQuery] = useState(false);
    const [organizationQuery, setOrganizationQuery] = useState(false);
    const [cfMetadataQuery, setCfMetadataQuery] = useState(false);
    const [balanceGroupQuery, setBalanceGroupQuery] = useState(false);
    const [functionalGroupQuery, setFunctionalGroupQuery] = useState(false);
    const [ohsGroupQuery, setOhsGroupQuery] = useState(false);
    const [revLungeGroupQuery, setRevLungeGroupQuery] = useState(false);
    const [romGroupQuery, setRomGroupQuery] = useState(false);
    const [sidePostGroupQuery, setSidePostGroupQuery] = useState(false);
    const [slhGroupQuery, setSlhGroupQuery] = useState(false);
    const [vjGroupQuery, setVjGroupQuery] = useState(false);
    const [waGroupQuery, setWaGroupQuery] = useState(false);
    const [assessmentGroupQuery, setAssessmentGroupQuery] = useState(false);
    const [assessmentFrameQuery, setAssessmentFrameQuery] = useState(false);
    const [workflowQuery, setWorkflowQuery] = useState(false);
    const [equipmentQuery, setEquipmentQuery] = useState(false);
    const [equipmentTextQuery, setEquipmentTextQuery] = useState(false);
    const [exerciseQuery, setExerciseQuery] = useState(false);
    const [exerciseTextQuery, setExerciseTextQuery] = useState(false);
    const [protocolQuery, setProtocolQuery] = useState(false);
    const [protocolSessionQuery, setProtocolSessionQuery] = useState(false);
    const [correctivesQuery, setCorrectivesQuery] = useState(false);
    const [correctiveProtocolQuery, setCorrectiveProtocolQuery] = useState(false);
    const [clinicCFQuery, setClinicCFQuery] = useState(false);
    const [practitionerCFQuery, setPractitionerCFQuery] = useState(false);
    const [patientCFQuery, setPatientCFQuery] = useState(false);
    const [assessmentCFQuery, setAssessmentCFQuery] = useState(false);
    const [workflowCFQuery, setWorkflowCFQuery] = useState(false);
    




    useEffect (() => {
        setClinicQuery(false);
        setPractitionerQuery(false);
        setPatientQuery(false);
        setAddressQuery(false);
        setPhoneNumberQuery(false);
        setSubscriptionQuery(false);
        setUploadFileQuery(false);
        setOrganizationQuery(false);
        setCfMetadataQuery(false);
        setBalanceQuery(false);
        setFunctionalQuery(false);
        setOhsQuery(false);
        setRevLungeQuery(false);
        setRomQuery(false);
        setSidePostQuery(false);
        setSlhQuery(false);
        setVjQuery(false);
        setWaQuery(false);
        setPermissionQuery(false);
        setBalanceGroupQuery(false);
        setFunctionalGroupQuery(false);
        setOhsGroupQuery(false);
        setRevLungeGroupQuery(false);
        setRomGroupQuery(false);
        setSidePostGroupQuery(false);
        setSlhGroupQuery(false);
        setVjGroupQuery(false);
        setWaGroupQuery(false);
        setAssessmentGroupQuery(false);
        setAssessmentFrameQuery(false);
        setWorkflowQuery(false);
        setEquipmentQuery(false);
        setEquipmentTextQuery(false);
        setExerciseQuery(false);
        setExerciseTextQuery(false);
        setProtocolQuery(false);
        setProtocolSessionQuery(false);
        setCorrectivesQuery(false);
        setCorrectiveProtocolQuery(false);
        setClinicCFQuery(false);
        setPractitionerCFQuery(false);
        setPatientCFQuery(false);
        setAssessmentCFQuery(false);
        setWorkflowCFQuery(false);

        switch (controller) {
            case "All":
                setClinicQuery(true);
                setPractitionerQuery(true);
                setPatientQuery(true);
                setAddressQuery(true);
                setPhoneNumberQuery(true);
                setSubscriptionQuery(true);
                setUploadFileQuery(true);
                setOrganizationQuery(true);
                setBalanceQuery(true);
                setFunctionalQuery(true);
                setOhsQuery(true);
                setRevLungeQuery(true);
                setRomQuery(true);
                setSidePostQuery(true);
                setSlhQuery(true);
                setVjQuery(true);
                setWaQuery(true);
                setPermissionQuery(true);
                setBalanceGroupQuery(true);
                setFunctionalGroupQuery(true);
                setOhsGroupQuery(true);
                setRevLungeGroupQuery(true);
                setRomGroupQuery(true);
                setSidePostGroupQuery(true);
                setSlhGroupQuery(true);
                setVjGroupQuery(true);
                setWaGroupQuery(true);
                setAssessmentGroupQuery(true);
                setAssessmentFrameQuery(true);
                setWorkflowQuery(true);
                setEquipmentQuery(true);
                setEquipmentTextQuery(true);
                setExerciseQuery(true);
                setExerciseTextQuery(true);
                setProtocolQuery(true);
                setProtocolSessionQuery(true);
                setCorrectivesQuery(true);
                setCorrectiveProtocolQuery(true);
                setClinicCFQuery(true);
                setPractitionerCFQuery(true);
                setPatientCFQuery(true);
                setAssessmentCFQuery(true);
                setWorkflowCFQuery(true);
                setCfMetadataQuery(true);
                break;
            case "Clinic":
                setClinicQuery(true);
                break;
            case "Practitioner":
                setPractitionerQuery(true);
                break;
            case "Patient":
                setPatientQuery(true);
                break;
            case "Address":
                setAddressQuery(true);
                break;
            case "Phone":
                setPhoneNumberQuery(true);
                break;
            case "Subscription":
                setSubscriptionQuery(true);
                break;
            case "UploadFile":
                setUploadFileQuery(true);
                break;
            case "Organization":
                setOrganizationQuery(true);
                break;
            case "Balance":
                setBalanceQuery(true);
                break;
            case "Functional":
                setFunctionalQuery(true);
                break;
            case "OverheadSquat":
                setOhsQuery(true);
                break;
            case "ReverseLunge":
                setRevLungeQuery(true);
                break;
            case "ROM":
                setRomQuery(true);
                break;
            case "SidePostural":
                setSidePostQuery(true);
                break;
            case "SingleLegHop":
                setSlhQuery(true);
                break;
            case "VerticalJump":
                setVjQuery(true);
                break;
            case "WallAngel":
                setWaQuery(true);
                break;
            case "Permissions":
                setPermissionQuery(true);
                break;
            case "GroupBalance":
                setBalanceGroupQuery(true);
                break;
            case "GroupFunctional":
                setFunctionalGroupQuery(true);
                break;
            case "GroupOverheadSquat":
                setOhsGroupQuery(true);
                break;
            case "GroupReverseLunge":
                setRevLungeGroupQuery(true);
                break;
            case "GroupROM":
                setRomGroupQuery(true);
                break;
            case "GroupSidePostural":
                setSidePostGroupQuery(true);

                break;
            case "GroupSingleLegHop":
                setSlhGroupQuery(true);
                break;
            case "GroupVerticalJump":
                setVjGroupQuery(true);
                break;
            case "GroupWallAngel":
                setWaGroupQuery(true);
                break;
            case "AssessmentGroup":
                setAssessmentGroupQuery(true);
                break;
            case "AssessmentFrame":
                setAssessmentFrameQuery(true);
                break;
            case "Workflow":
                setWorkflowQuery(true);
                break;
            case "Equipment":
                setEquipmentQuery(true);
                break;
            case "EquipmentText":
                setEquipmentTextQuery(true);
                break;
            case "Exercise":
                setExerciseQuery(true);
                break;
            case "ExerciseText":
                setExerciseTextQuery(true);
                break;
            case "Protocol":
                setProtocolQuery(true);
                break;
            case "ProtocolSession":
                setProtocolSessionQuery(true);
                break;
            case "Correctives":
                setCorrectivesQuery(true);
                break;
            case "CorrectiveProtocol":
                setCorrectiveProtocolQuery(true);
                break;
            case "Clinic CF":
                setClinicCFQuery(true);
                break;
            case "Practitioner CF":
                setPractitionerCFQuery(true);
                break;
            case "Patient CF":
                setPatientCFQuery(true);
                break;
            case "Assessment CF":
                setAssessmentCFQuery(true);
                break;
            case "Workflow CF":
                setWorkflowCFQuery(true);
                break;
            case "CF Metadata":
                setCfMetadataQuery(true);
                break;

            default:
                break;
        }
    }, [controller]);

     useEffect (() => {
        try {
            if (token.length > 0) {
                if (controller === "All" || controller === "Clinic") {
                    var fetchURL = serverURL+ `/tables/clinic/?$filter=${filter}&$orderby=${orderby}&$skip=${skipClinic}&$top=${top}&__includeDeleted=true&machineKey=2df4f86c184a2fc1381de54f797c78721506baa7&syncSession=95ed5fbbfe`;
                fetch(fetchURL, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        // throw new Error(response.status);
                        setClinicError(response.status);
                        setClinicLoading(false);
                    }
                    })
                .then ((data) => {
                setClinicList(clinicList.concat(data));
                setSkip(skip + data.length);
                if (data.length !== 0) {
                    if (syncMode) {
                        setClinicList(clinicList.concat(data));
                        setSkipClinic(skipClinic + data.length);
                    } else {
                        setClinicList(data);
                        setClinicLoading(false);
                    }
                } else {
                    setClinicLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setClinicError(error.message);
                });  
            } else {
                setClinicLoading(false);
            }
        }
        } catch (error) {
            setClinicError(error.message);
            setClinicLoading(false);
        }
      
    }, [search, skipClinic]);

    useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "Practitioner") {
                fetch(serverURL+ `/tables/practitioner/?$filter=${filter}&$orderby=${orderby}&$skip=${skipPractitioner}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        // throw new Error(response.status);
                        setPractitionerError(response.status);
                        setPractitionerLoading(false);
                    }
                    })
                .then ((data) => {
                setPractitionerList(practitionerList.concat(data));
                setSkip(skip + data.length);
                if (data.length !== 0) {
                        if (syncMode) {
                            setPractitionerList(practitionerList.concat(data));
                            setSkipPractitioner(skipPractitioner + data.length);
                        } else {
                            setPractitionerList(data);
                            setPractitionerLoading(false);
                        }
                    } else {
                        setPractitionerLoading(false);
                    }
                }).catch ((error) => {
                    console.log(error);
                    setPractitionerError(error.message);
                });  
            } else {
                setPractitionerLoading(false);
            }
            } 
        } catch (error) {
            setPractitionerError(error.message);
            setPractitionerLoading(false);
        }
    }, [search, skipPractitioner]);
   
    useEffect (() => {
        try {
                if (token.length > 0) {
                    if (controller === "All" || controller === "Patient") {
                    fetch(serverURL+ `/tables/patient/?$filter=${filter}&$orderby=${orderby}&$skip=${skipPatient}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setPatientError(response.status);
                            setPatientLoading(false);
                        }
                        })
                    .then ((data) => {
                    setPatientList(patientList.concat(data));
                    setSkip(skip + data.length);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setPatientList(patientList.concat(data));
                            setSkipPatient(skipPatient + data.length);
                        } else {
                            setPatientList(data);
                            setPatientLoading(false);
                        }
                    } else {
                        setPatientLoading(false);
                    }
                    }).catch ((error) => {
                        console.log(error);
                        setPatientError(error.message);
                    });  
                } else {
                    setPatientLoading(false);
                }
            }
        } catch (error) {
            setPatientError(error.message);
            setPatientLoading(false);
        }
    }, [search, skipPatient]);

 // Wall Angel
 useEffect (() => {
    try {
            if (token.length > 0) {
                if (controller === "All" || controller === "Address") {
                    fetch(serverURL+ `/tables/address/?$filter=${filter}&$orderby=${orderby}&$skip=${skipAddress}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        // throw new Error(response.status);
                        setAddressError(response.status);
                        setAddressLoading(false);
                    }
                    })
                    .then ((data) => {
                        // console.log("WA Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setAddressList(addressList.concat(data));
                                setSkipAddress(skipAddress + data.length);
                            } else {
                                setAddressList(data);
                                setAddressLoading(false);
                            }
                        } else {
                            setAddressLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setAddressError(error.message);
                    });
                } else {
                    setAddressLoading(false);
                }
            }
    } catch (error) {
        setAddressError(error.message);
        setAddressLoading(false);
    }

}, [search, skipAddress]);

useEffect (() => {
    try {
        if (token.length > 0) {
            if (controller === "All" || controller === "Phone") {
                fetch(serverURL+ `/tables/phone/?$filter=${filter}&$orderby=${orderby}&$skip=${skipPhoneNumber}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setPhoneNumberError(response.status);
                            setPhoneNumberLoading(false);
                        }
                        })
                .then ((data) => {
                        // console.log("WA Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setPhoneNumberList(phoneNumberList.concat(data));
                            setSkipPhoneNumber(skipPhoneNumber + data.length);
                        } else {
                            setPhoneNumberList(data);
                            setPhoneNumberLoading(false);
                        }
                    } else {
                        setPhoneNumberLoading(false);
                    }
                }).catch ((error) => {
                    console.log(error);
                    setPhoneNumberError(error.message);
                });
            } else {
                setPhoneNumberLoading(false);
            }
        }
    } catch (error) {
        setPhoneNumberError(error.message);
        setPhoneNumberLoading(false);
    }
}, [search, skipPhoneNumber]);

// Subscriptions
useEffect (() => {
    try {
        if (serverURL.length !== 0) {
            if (controller === "All" || controller === "Subscription") {
                fetch(serverURL+ `/tables/subscription/?$filter=${filter}&$orderby=${orderby}&$skip=${skipSubscriptions}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setSubscriptionError(response.status);
                            setSubscriptionLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setSubscriptionList(subscriptionList.concat(data));
                            setSkipSubscriptions(skipSubscriptions + data.length);
                        } else {
                            setSubscriptionList(data);
                            setSubscriptionLoading(false);
                        }
                    } else {
                        setSubscriptionLoading(false);
                    }
                }).catch ((error) => {
                    console.log(error);
                    setSubscriptionError(error.message);
                });
            } else {
                setSubscriptionLoading(false);
            }
        }
    } catch (error) {
        setSubscriptionError(error.message);
        setSubscriptionLoading(false);
    }
}, [search, skipSubscriptions]);

// Upload Files
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "UploadFile") {
                fetch(serverURL+ `/tables/uploadFile/?$filter=${filter}&$orderby=${orderby}&$skip=${skipUploadFiles}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setUploadFileError(response.status);
                            setUploadFileLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setUploadFileList(uploadFileList.concat(data));
                            setSkipUploadFiles(skipUploadFiles + data.length);
                        } else {
                            setUploadFileList(data);
                            setUploadFileLoading(false);
                        }
                    } else {
                        setUploadFileLoading(false);
                    }
                }).catch ((error) => {
                    console.log(error);
                    setUploadFileError(error.message);
                });
            } else {
                setUploadFileLoading(false);
            }
        }
    } catch (error) {
        setUploadFileError(error.message);
        setUploadFileLoading(false);
    }
}, [search, skipUploadFiles]);


    // Balance Assessment
    useEffect (() => {
        try{
            if (token.length > 0) { 
                if (controller === "All" || controller === "Balance") {
                    fetch(serverURL+ `/tables/BalanceAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipBalance}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setBalanceError(response.status);
                            setBalanceLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("Balance Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setBalanceList(balanceList.concat(data));
                                setSkipBalance(skipBalance + data.length);
                            } else {
                                setBalanceList(data);
                                setBalanceLoading(false);
                            }
                        } else {
                            setBalanceLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setBalanceError(error.message);
                    });
                } else {
                    setBalanceLoading(false);
                }
            }
        } catch (error) {
            setBalanceError(error.message);
            setBalanceLoading(false);
        }
    }, [search, skipBalance]);
     // FUnctional Assessments
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "Functional") {
                    fetch(serverURL+ `/tables/FunctionalAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipFunctional}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setFunctionalError(response.status);
                            setFunctionalLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("functional Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setFunctionalList(functionalList.concat(data));
                                setSkipFunctional(skipFunctional + data.length);
                            } else {
                                setFunctionalList(data);
                                setFunctionalLoading(false);
                            }
                        } else {
                            setFunctionalLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setFunctionalLoading(false)
                        setFunctionalError(error.message);
                    });
                } else {
                    setFunctionalLoading(false);
                }
            }
        } catch (error) {
            setFunctionalError(error.message);
            setFunctionalLoading(false);
        }
    }, [search, skipFunctional]);
     // Modified Veles Assessments
  
  
     // overhead squat Assessments
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "OverheadSquat") {
                    fetch(serverURL+ `/tables/OverheadSquatAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipOhs}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setOhsError(response.status);
                            setOhsLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("ohs Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setOhsList(ohsList.concat(data));
                                setSkipOhs(skipOhs + data.length);
                            } else {
                                setOhsList(data);
                                setOhsLoading(false);
                            }
                        } else {
                            setOhsLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setOhsError(error.message);
                    });
                } else {
                    setOhsLoading(false);
                }
            }
        } catch (error) {
            setOhsError(error.message);
            setOhsLoading(false);
        }
    }, [search, skipOhs]);
     // Reverse lunge squat Assessments
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "ReverseLunge") {
                    fetch(serverURL+ `/tables/ReverseLungeAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipRevLunge}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setRevLungeError(response.status);
                            setRevLungeLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("Reverse Lunge Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setRevLungeList(revLungeList.concat(data));
                                setSkipRevLunge(skipRevLunge + data.length);
                            } else {
                                setRevLungeList(data);
                                setRevLungeLoading(false);
                            }
                        } else {
                            setRevLungeLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setRevLungeError(error.message);
                    });
                    
                } else {
                    setRevLungeLoading(false);
                }
            }
        } catch (error) {
            setRevLungeError(error.message);
            setRevLungeLoading(false);
        }
    }, [search, skipRevLunge]);

     // ROM Assessments
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "ROM") {
                    fetch(serverURL+ `/tables/ROMAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipRom}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setRomError(response.status);
                            setRomLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("ROM Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setRomList(romList.concat(data));
                                setSkipRom(skipRom + data.length);
                            } else {
                                setRomList(data);
                                setRomLoading(false);
                            }
                        } else {
                            setRomLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setRomError(error.message);
                    });
                } else {
                    setRomLoading(false);
                }
            } 
        } catch (error) {
            setRomError(error.message);
            setRomLoading(false);
        }
    }, [search, skipRom]);

       // ROM Assessments
       useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "SidePostural") {
                    fetch(serverURL+ `/tables/SidePosturalAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipSidePost}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setSidePostError(response.status);
                            setSidePostLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("Side Postural Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setSidePostList(sidePostList.concat(data));
                                setSkipSidePost(skipSidePost + data.length);
                            } else {
                                setSidePostList(data);
                                setSidePostLoading(false);
                            }
                        } else {
                            setSidePostLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setSidePostError(error.message);
                    });
                } else {
                    setSidePostLoading(false);
                }
            } 
        } catch (error) {
            setSidePostError(error.message);
            setSidePostLoading(false);
        }
    }, [search, skipSidePost]);

    // Single leg hop Assessments
    useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "SingleLegHop") {
                    fetch(serverURL+ `/tables/SingleLegHopAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipSlh}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setSlhError(response.status);
                            setSlhLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("SLH Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setSlhList(slhList.concat(data));
                                setSkipSlh(skipSlh + data.length);
                            } else {    
                                setSlhList(data);
                                setSlhLoading(false);
                            }
                        } else {
                            setSlhLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setSlhError(error.message);
                    });
                
                } else {
                    setSlhLoading(false);
                }
            }
        } catch (error) {
            setSlhError(error.message);
            setSlhLoading(false);
        }
    }, [search, skipSlh]);

     // Vertical JumpAssessments
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "VerticalJump") {
                    fetch(serverURL+ `/tables/VerticalJumpAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipVj}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setVjError(response.status);
                            setVjLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("VJ Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setVjList(vjList.concat(data));
                            setSkipVj(skipVj + data.length);
                        } else {   
                            setVjList(data);
                            setVjLoading(false);
                        }
                    } else {
                        setVjLoading(false);
                    }
                    }).catch ((error) => {
                        console.log(error);
                        setVjError(error.message);
                    });
                } else {
                    setVjLoading(false);
                }
            }
        } catch (error) {
            setVjError(error.message);
            setVjLoading(false);
        }
    }, [search, skipVj]);

       // Wall Angel
       useEffect (() => {
        try {
                if (token.length > 0) { 
                    if (controller === "All" || controller === "WallAngel") {
                        fetch(serverURL+ `/tables/WallAngelAssessment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipWa}&$top=${top}`, {
                            method: 'GET',
                            headers:{
                                "X-ZUMO-AUTH": token,
                                "Content-type":"application/json"
                            }
                        }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setWaError(response.status);
                            setWaLoading(false);
                        }
                        })
                        .then ((data) => {
                            // console.log("WA Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setWaList(waList.concat(data));
                                setSkipWa(skipWa + data.length);
                            } else {
                                setWaList(data);
                                setWaLoading(false);
                            }
                        } else {
                            setWaLoading(false);
                        }
                        }).catch ((error) => {
                            console.log(error);
                            setWaError(error.message);
                        });
                    } else {
                        setWaLoading(false);
                    }
                }
        } catch (error) {
            setWaError(error.message);
            setWaLoading(false);
        }
    }, [search, skipWa]);

      // permissions
      useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "Permissions") {
                    fetch(serverURL+ `/tables/permissions/?$filter=${filter}&$orderby=${orderby}&$skip=${skipPermission}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setPermissionError(response.status);
                            setPermissionLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("WA Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setPermissionList(permissionList.concat(data));
                            setSkipPermission(skipPermission + data.length);
                        } else {
                            setPermissionList(data);
                            setPermissionLoading(false);
                        }
                    } else {
                        setPermissionLoading(false);
                    }
                    }).catch ((error) => {
                        console.log(error);
                        setPermissionError(error.message);
                    });
                } else {
                    setPermissionLoading(false);
                }
            }
        } catch (error) {
            setPermissionError(error.message);
            setPermissionLoading(false);
        }
    }, [search, skipPermission]);


    // Balance Group
    useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "GroupBalance") {
                    fetch(serverURL+ `/tables/GroupBalance/?$filter=${filter}&$orderby=${orderby}&$skip=${skipBalance}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setBalanceGroupError(response.status);
                            setBalanceGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("Balance Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setBalanceGroupList(balanceGroupList.concat(data));
                                setSkipBalanceGroup(skipBalanceGroup + data.length);
                            } else {
                                setBalanceGroupList(data);
                                setBalanceGroupLoading(false);
                            }
                        } else {
                            setBalanceGroupLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setBalanceGroupError(error.message);
                    });
                } else {
                    setBalanceGroupLoading(false);
                }
            }
        } catch (error) {
            setBalanceGroupError(error.message);
            setBalanceGroupLoading(false);
        }
    }, [search, skipBalanceGroup]);
   
    // Functional Assessments
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "GroupFunctional") {
                    fetch(serverURL+ `/tables/GroupFunctional/?$filter=${filter}&$orderby=${orderby}&$skip=${skipFunctional}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setFunctionalGroupError(response.status);
                            setFunctionalGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("functional Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setFunctionalGroupList(functionalGroupList.concat(data));
                                setSkipFunctionalGroup(skipFunctionalGroup + data.length);
                            } else {
                                setFunctionalGroupList(data);
                                setFunctionalGroupLoading(false);
                            }
                        } else {
                            setFunctionalGroupLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setFunctionalGroupLoading(false)
                        setFunctionalGroupError(error.message);
                    });
                } else {
                    setFunctionalGroupLoading(false);
                }
            }
        } catch (error) {
            setFunctionalGroupError(error.message);
            setFunctionalGroupLoading(false);
        }
    }, [search, skipFunctionalGroup]);
  
     // overhead squat Group
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "GroupOverheadSquat") {
                    fetch(serverURL+ `/tables/GroupOverheadSquat/?$filter=${filter}&$orderby=${orderby}&$skip=${skipOhs}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setOhsGroupError(response.status);
                            setOhsGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("ohs Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setOhsGroupList(ohsGroupList.concat(data));
                                setSkipOhsGroup(skipOhsGroup + data.length);
                            } else {
                                setOhsGroupList(data);
                                setOhsGroupLoading(false);
                            }
                        } else {
                            setOhsGroupLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setOhsGroupError(error.message);
                    });
                } else {
                    setOhsGroupLoading(false);
                }
            }
        } catch (error) {
            setOhsGroupError(error.message);
            setOhsGroupLoading(false);
        }
    }, [search, skipOhsGroup]);
     
    // Reverse lunge squat Group
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "GroupReverseLunge") {
                    fetch(serverURL+ `/tables/GroupReverseLunge/?$filter=${filter}&$orderby=${orderby}&$skip=${skipRevLunge}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setRevLungeGroupError(response.status);
                            setRevLungeGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("Reverse Lunge Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setRevLungeGroupList(revLungeGroupList.concat(data));
                                setSkipRevLungeGroup(skipRevLungeGroup + data.length);
                            } else {
                                setRevLungeGroupList(data);
                                setRevLungeGroupLoading(false);
                            }
                        } else {
                            setRevLungeGroupLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setRevLungeGroupError(error.message);
                    });
                } else {
                    setRevLungeGroupLoading(false);
                }
            }
        } catch (error) {
            setRevLungeGroupError(error.message);
            setRevLungeGroupLoading(false);
        }
    }, [search, skipRevLunge]);

     // ROM Assessments
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "GroupROM") {
                    fetch(serverURL+ `/tables/GroupROM/?$filter=${filter}&$orderby=${orderby}&$skip=${skipRom}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setRomGroupError(response.status);
                            setRomGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("ROM Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setRomGroupList(romGroupList.concat(data));
                                setSkipRomGroup(skipRomGroup + data.length);
                            } else {
                                setRomGroupList(data);
                                setRomGroupLoading(false);
                            }
                        } else {
                            setRomGroupLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setRomGroupError(error.message);
                    });
                } else {
                    setRomGroupLoading(false);
                }
            }
        } catch (error) {
            setRomGroupError(error.message);
            setRomGroupLoading(false);
        }
    }, [search, skipRomGroup]);

       // Side Postural Group
       useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "SidePostural") {
                    fetch(serverURL+ `/tables/GroupSidePostural/?$filter=${filter}&$orderby=${orderby}&$skip=${skipSidePost}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setSidePostGroupError(response.status);
                            setSidePostGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("Side Postural Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setSidePostGroupList(sidePostGroupList.concat(data));
                                setSkipSidePostGroup(skipSidePostGroup + data.length);
                            } else {
                                setSidePostGroupList(data);
                                setSidePostGroupLoading(false);
                            }
                        } else {
                            setSidePostGroupLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setSidePostGroupError(error.message);
                    });
                } else {
                    setSidePostGroupLoading(false);
                }
            }
        } catch (error) {
            setSidePostGroupError(error.message);
            setSidePostGroupLoading(false);
        }
    }, [search, skipSidePostGroup]);

    // Single leg hop Group
    useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "GroupSingleLegHop") {
                    fetch(serverURL+ `/tables/GroupSingleLegHop/?$filter=${filter}&$orderby=${orderby}&$skip=${skipSlh}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setSlhGroupError(response.status);
                            setSlhGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("SLH Data: ", data);
                        if (data.length !== 0) {
                            if (syncMode) {
                                setSlhGroupList(slhGroupList.concat(data));
                                setSkipSlhGroup(skipSlhGroup + data.length);
                            } else {    
                                setSlhGroupList(data);
                                setSlhGroupLoading(false);
                            }
                        } else {
                            setSlhGroupLoading(false);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setSlhGroupError(error.message);
                    });
                } else {
                    setSlhGroupLoading(false);
                }
            }
        } catch (error) {
            setSlhGroupError(error.message);
            setSlhGroupLoading(false);
        }
    }, [search, skipSlhGroup]);

     // Vertical Jump Group
     useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "GroupVerticalJump") {
                    fetch(serverURL+ `/tables/GroupVerticalJump/?$filter=${filter}&$orderby=${orderby}&$skip=${skipVj}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setVjGroupError(response.status);
                            setVjGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("VJ Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setVjGroupList(vjGroupList.concat(data));
                            setSkipVjGroup(skipVjGroup + data.length);
                        } else {   
                            setVjGroupList(data);
                            setVjGroupLoading(false);
                        }
                    } else {
                        setVjGroupLoading(false);
                    }
                    }).catch ((error) => {
                        console.log(error);
                        setVjGroupError(error.message);
                    });
                } else {
                    setVjGroupLoading(false);
                }
            }
        } catch (error) {
            setVjGroupError(error.message);
            setVjGroupLoading(false);
        }
    }, [search, skipVjGroup]);

       // Wall Angel
       useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "GroupWallAngel") {
                    fetch(serverURL+ `/tables/GroupWallAngel/?$filter=${filter}&$orderby=${orderby}&$skip=${skipWa}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setWaGroupError(response.status);
                            setWaGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("WA Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setWaGroupList(waGroupList.concat(data));
                            setSkipWaGroup(skipWaGroup + data.length);
                        } else {
                            setWaGroupList(data);
                            setWaGroupLoading(false);
                        }
                    } else {
                        setWaGroupLoading(false);
                    }
                    }).catch ((error) => {
                        console.log(error);
                        setWaGroupError(error.message);
                    });
                } else {
                    setWaGroupLoading(false);
                }
            }
        } catch (error) {
            setWaGroupError(error.message);
            setWaGroupLoading(false);
        }
    }, [search, skipWaGroup]);

       // Assessment Group
       useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "AssessmentGroup") {
                    fetch(serverURL+ `/tables/AssessmentsGroup/?$filter=${filter}&$orderby=${orderby}&$skip=${skipAssessmentGroup}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setAssessmentGroupError(response.status);
                            setAssessmentGroupLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("WA Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setAssessmentGroupList(assessmentGroupList.concat(data));
                            setSkipAssessmentGroup(skipAssessmentGroup + data.length);
                        } else {
                            setAssessmentGroupList(data);
                            setAssessmentGroupLoading(false);
                        }
                    } else {
                        setAssessmentGroupLoading(false);
                    }
                    }).catch ((error) => {
                        console.log(error);
                        setAssessmentGroupError(error.message);
                    });
                } else {
                    setAssessmentGroupLoading(false);
                }
            }
        } catch (error) {
            setAssessmentGroupError(error.message);
            setAssessmentGroupLoading(false);
        }
    }, [search, skipAssessmentGroup]);

       // Assessment Frames
       useEffect (() => {
        try {
            if (token.length > 0) { 
                if (controller === "All" || controller === "Assessment Frame") {
                    fetch(serverURL+ `/tables/Assessmentframe/?$filter=${filter}&$orderby=${orderby}&$skip=${skipAssessmentFrame}&$top=${top}`, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": token,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setAssessmentFrameError(response.status);
                            setAssessmentFrameLoading(false);
                        }
                        })
                    .then ((data) => {
                        // console.log("WA Data: ", data);
                    if (data.length !== 0) {
                        if (syncMode) {
                            setAssessmentFrameList(assessmentFrameList.concat(data));
                            setSkipAssessmentFrame(skipAssessmentFrame + data.length);
                        } else {
                            setAssessmentFrameList(data);
                            setAssessmentFrameLoading(false);
                        }
                    } else {
                        setAssessmentFrameLoading(false);
                    }
                    }).catch ((error) => {
                        console.log(error);
                        setAssessmentFrameError(error.message);
                    });
                } else {
                    setAssessmentFrameLoading(false);
                }
            }
        } catch (error) {
            setAssessmentFrameError(error.message);
            setAssessmentFrameLoading(false);
        }
    }, [search, skipAssessmentFrame]);

  // workflow
  useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Workflow") {
                fetch(serverURL+ `/tables/workflow/?$filter=${filter}&$orderby=${orderby}&$skip=${skipWorkflow}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setWorkflowError(response.status);
                            setWorkflowLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setWorkflowList(workflowList.concat(data));
                        setSkipWorkflow(skipWorkflow + data.length);
                    } else {
                        setWorkflowList(data);
                        setWorkflowLoading(false);
                    }
                } else {
                    setWorkflowLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setWorkflowError(error.message);
                });
            } else {
                setWorkflowLoading(false);
            }
        }
    } catch (error) {
        setWorkflowError(error.message);
        setWorkflowLoading(false);
    }
}, [search, skipWorkflow]);
 
// Equipment
  useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Equipment") {
                fetch(serverURL+ `/tables/equipment/?$filter=${filter}&$orderby=${orderby}&$skip=${skipEquipment}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setEquipmentError(response.status);
                            setEquipmentLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setEquipmentList(equipmentList.concat(data));
                        setSkipEquipment(skipEquipment + data.length);
                    } else {
                        setEquipmentList(data);
                        setEquipmentLoading(false);
                    }
                } else {
                    setEquipmentLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setEquipmentError(error.message);
                });
            } else {
                setEquipmentLoading(false);
            }
        }
    } catch (error) {
        setEquipmentError(error.message);
        setEquipmentLoading(false);
    }
}, [search, skipEquipment]);

// Equipment text
  useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "EquipmentText") {
                fetch(serverURL+ `/tables/equipmentText/?$filter=${filter}&$orderby=${orderby}&$skip=${skipEquipmentText}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setEquipmentTextError(response.status);
                            setEquipmentTextLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setEquipmentTextList(equipmentTextList.concat(data));
                        setSkipEquipmentText(skipEquipmentText + data.length);
                    } else {
                        setEquipmentTextList(data);
                        setEquipmentTextLoading(false);
                    }
                } else {
                    setEquipmentTextLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setEquipmentTextError(error.message);
                });
            } else {
                setEquipmentTextLoading(false);
            } 
        }
    } catch (error) {
        setEquipmentTextError(error.message);
        setEquipmentTextLoading(false);
    }
}, [search, skipEquipmentText]);

// Exercise
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Exercise") {
                fetch(serverURL+ `/tables/exercise/?$filter=${filter}&$orderby=${orderby}&$skip=${skipExercise}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setExerciseError(response.status);
                            setExerciseLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setExerciseList(exerciseList.concat(data));
                        setSkipExercise(skipExercise + data.length);
                    } else {
                        setExerciseList(data);
                        setExerciseLoading(false);
                    }
                } else {
                    setExerciseLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setExerciseError(error.message);
                });
            } else {
                setExerciseLoading(false);
            }
        }
    } catch (error) {
        setExerciseError(error.message);
        setExerciseLoading(false);
    }
}, [search, skipExercise]);

// Exercise text
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "ExerciseText") {
                fetch(serverURL+ `/tables/exerciseText/?$filter=${filter}&$orderby=${orderby}&$skip=${skipExerciseText}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setExerciseTextError(response.status);
                            setExerciseTextLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setExerciseTextList(exerciseTextList.concat(data));
                        setSkipExerciseText(skipExerciseText + data.length);
                    } else {
                        setExerciseTextList(data);
                        setExerciseTextLoading(false);
                    }
                } else {
                    setExerciseTextLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setExerciseTextError(error.message);
                });
            } else {
                setExerciseTextLoading(false);
            }
        }
    } catch (error) {
        setExerciseTextError(error.message);
        setExerciseTextLoading(false);
    }
}, [search, skipExerciseText]);

// Correctives
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Correctives") {
                fetch(serverURL+ `/tables/corrective/?$filter=${filter}&$orderby=${orderby}&$skip=${skipCorrectives}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setCorrectivesError(response.status);
                            setCorrectivesLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setCorrectivesList(correctivesList.concat(data));
                        setSkipCorrectives(skipCorrectives + data.length);
                    } else {
                        setCorrectivesList(data);
                        setCorrectivesLoading(false);
                    }
                } else {
                    setCorrectivesLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setCorrectivesError(error.message);
                });
            } else {
                setCorrectivesLoading(false);
            }
        }
    } catch (error) {
        setCorrectivesError(error.message);
        setCorrectivesLoading(false);
    }
}, [search, skipCorrectives]);

// Corrective protocol
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "CorrectiveProtocol") {
                fetch(serverURL+ `/tables/correctiveProtocol/?$filter=${filter}&$orderby=${orderby}&$skip=${skipCorrectiveProtocol}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setCorrectiveProtocolError(response.status);
                            setCorrectiveProtocolLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                    // console.log("--------------------Corrective Protocol syncmode: ", syncMode);
                if (data.length !== 0) {
                    if (syncMode) {
                        // console.log("--------------------Corrective Protocol Data: ", data);
                        // console.log("--------------------Corrective Protocol skip: ", skipCorrectiveProtocol); 
                        setCorrectiveProtocolList(correctiveProtocolList.concat(data));
                        setSkipCorrectiveProtocol(skipCorrectiveProtocol + data.length);
                    } else {
                        setCorrectiveProtocolList(data);
                        setCorrectiveProtocolLoading(false);
                    }
                } else {
                    setCorrectiveProtocolLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setCorrectiveProtocolError(error.message);
                });
            } else {
                setCorrectiveProtocolLoading(false);
            }
        }
    } catch (error) {
        setCorrectiveProtocolError(error.message);
        setCorrectiveProtocolLoading(false);
    }
}, [search, skipCorrectiveProtocol]);

// Protocols
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Protocol") {
                fetch(serverURL+ `/tables/protocol/?$filter=${filter}&$orderby=${orderby}&$skip=${skipProtocol}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setProtocolError(response.status);
                            setProtocolLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setProtocolList(protocolList.concat(data));
                        setSkipProtocol(skipProtocol + data.length);
                    } else {
                        setProtocolList(data);
                        setProtocolLoading(false);
                    }
                } else {
                    setProtocolLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setProtocolError(error.message);
                });
            } else {
                setProtocolLoading(false);
            }
        }
    } catch (error) {
        setProtocolError(error.message);
        setProtocolLoading(false);
    }
}, [search, skipProtocol]);

// Protocol Session
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "ProtocolSession") {
                fetch(serverURL+ `/tables/ProtocolSession/?$filter=${filter}&$orderby=${orderby}&$skip=${skipProtocolSession}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setProtocolSessionError(response.status);
                            setProtocolSessionLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setProtocolSessionList(protocolSessionList.concat(data));
                        setSkipProtocolSession(skipProtocolSession + data.length);
                    } else {
                        setProtocolSessionList(data);
                        setProtocolSessionLoading(false);
                    }
                } else {
                    setProtocolSessionLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setProtocolSessionError(error.message);
                });
            } else {
                setProtocolSessionLoading(false);
            }
        }
    } catch (error) {
        setProtocolSessionError(error.message);
        setProtocolSessionLoading(false);
    }
}, [search, skipProtocolSession]);

// Clinic CF
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Clinic CF") {
                fetch(serverURL+ `/tables/ClinicCustomField/?$filter=${filter}&$orderby=${orderby}&$skip=${skipClinicCF}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setClinicCFError(response.status);
                            setClinicCFLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setClinicCFList(clinicCFList.concat(data));
                        setSkipClinicCF(skipClinicCF + data.length);
                    } else {
                        setClinicCFList(data);
                        setClinicCFLoading(false);
                    }
                } else {
                    setClinicCFLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setClinicCFError(error.message);
                });
            } else {
                setClinicCFLoading(false);
            }
        }
    } catch (error) {
        setClinicCFError(error.message);
        setClinicCFLoading(false);
    }
}, [search, skipClinicCF]);

// Practitioner CF
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Practitioner CF") {
                fetch(serverURL+ `/tables/PractitionerCustomField/?$filter=${filter}&$orderby=${orderby}&$skip=${skipPractitionerCF}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setPractitionerCFError(response.status);
                            setPractitionerCFLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setPractitionerCFList(practitionerCFList.concat(data));
                        setSkipPractitionerCF(skipPractitionerCF + data.length);
                    } else {
                        setPractitionerCFList(data);
                        setPractitionerCFLoading(false);
                    }
                } else {
                    setPractitionerCFLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setPractitionerCFError(error.message);
                });
            } else {
                setPractitionerCFLoading(false);
            }
        }
    } catch (error) {
        setPractitionerCFError(error.message);
        setPractitionerCFLoading(false);
    }
}, [search, skipPractitionerCF]);

// Patient CF
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Patient CF") {
                fetch(serverURL+ `/tables/PatientCustomField/?$filter=${filter}&$orderby=${orderby}&$skip=${skipPatientCF}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setPatientCFError(response.status);
                            setPatientCFLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setPatientCFList(patientCFList.concat(data));
                        setSkipPatientCF(skipPatientCF + data.length);
                    } else {
                        setPatientCFList(data);
                        setPatientCFLoading(false);
                    }
                } else {
                    setPatientCFLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setPractitionerCFError(error.message);
                });
            } else {
                setPatientCFLoading(false);
            }
        }
    } catch (error) {
        setPatientCFError(error.message);
        setPatientCFLoading(false);
    }
}, [search, skipPatientCF]);

// Assessment CF
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Assessment CF") {
                fetch(serverURL+ `/tables/AssessmentCustomField/?$filter=${filter}&$orderby=${orderby}&$skip=${skipAssessmentCF}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setAssessmentCFError(response.status);
                            setAssessmentCFLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setAssessmentCFList(assessmentCFList.concat(data));
                        setSkipAssessmentCF(skipAssessmentCF + data.length);
                    } else {
                        setAssessmentCFList(data);
                        setAssessmentCFLoading(false);
                    }
                } else {
                    setAssessmentCFLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setAssessmentCFError(error.message);
                });
            } else {
                setAssessmentCFLoading(false);
            }
        }
    } catch (error) {
        setAssessmentCFError(error.message);
        setAssessmentCFLoading(false);
    }
}, [search, skipAssessmentCF]);

// workflow CF
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Workflow CF") {
                fetch(serverURL+ `/tables/AssessmentsGroupCustomField/?$filter=${filter}&$orderby=${orderby}&$skip=${skipWorkflowCF}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setWorkflowCFError(response.status);
                            setWorkflowCFLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setWorkflowCFList(workflowCFList.concat(data));
                        setSkipWorkflowCF(skipWorkflowCF + data.length);
                    } else {
                        setWorkflowCFList(data);
                        setWorkflowCFLoading(false);
                    }
                } else {
                    setWorkflowCFLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setWorkflowCFError(error.message);
                });
            } else {
                setWorkflowCFLoading(false);
            }
        }
    } catch (error) {
        setWorkflowCFError(error.message);
        setWorkflowCFLoading(false);
    }
}, [search, skipWorkflowCF]);


// Organization
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "Organization") {
                fetch(serverURL+ `/tables/organization/?$filter=${filter}&$orderby=${orderby}&$skip=${skipOrganization}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setWorkflowCFError(response.status);
                            setWorkflowCFLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setOrganizationList(organizationList.concat(data));
                        setSkipOrganization(skipOrganization + data.length);
                    } else {
                        setOrganizationList(data);
                        setOrganizationLoading(false);
                    }
                } else {
                    setOrganizationLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setOrganizationError(error.message);
                });
            } else {
                setOrganizationLoading(false);
            }
        }
    } catch (error) {
        setOrganizationError(error.message);
        setOrganizationLoading(false);
    }
}, [search, skipOrganization]);

// cf metadata
useEffect (() => {
    try {
        if (token.length > 0) { 
            if (controller === "All" || controller === "CF Metadata") {
                fetch(serverURL+ `/tables/CustomFieldsMetaData/?$filter=${filter}&$orderby=${orderby}&$skip=${skipCfMetadata}&$top=${top}`, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                        if(response.status>= 200 && response.status < 300){
                            return response.json();
                        } else {
                            // throw new Error(response.status);
                            setCfMetadataError(response.status);
                            setCfMetadataLoading(false);
                        }
                        })
                .then ((data) => {
                    // console.log("WA Data: ", data);
                if (data.length !== 0) {
                    if (syncMode) {
                        setCfMetadataList(organizationList.concat(data));
                        setSkipCfMetadata(skipCfMetadata + data.length);
                    } else {
                        setCfMetadataList(data);
                        setCfMetadataLoading(false);
                    }
                } else {
                    setCfMetadataLoading(false);
                }
                }).catch ((error) => {
                    console.log(error);
                    setOrganizationError(error.message);
                });
            } else {
                setCfMetadataLoading(false);
            }
        }
    } catch (error) {
        setOrganizationError(error.message);
        setCfMetadataLoading(false);
    }
}, [search, skipCfMetadata]);



    const handleLogin = (serverURL, username, password) => {
        console.log("Server URL: ", serverURL);
        setDisplayLoginError(false);
        setDisplayLoginMessage(false);
        resetLists();
        setServerURL(serverURL);
        const httpBody = { username: username, 
            password: password, 
            version: "6.0.0.1", 
            app: "Kinetisense", 
            platform: "windows"};
         try {
          fetch(serverURL+'/api/login', {
            method: 'POST',
            headers:{"Content-type":"application/json"},
            body: JSON.stringify(httpBody)
          }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                    })
          .then ((data) => {
            console.log(data.mobileServiceAuthenticationToken);
            setToken(data.mobileServiceAuthenticationToken);
            setDisplayLoginMessage(true);
            setDisplayLoginError(false);
            console.log("login successful");
          }).catch ((error) => {
            console.log(error);
            setDisplayLoginError(true);
            setDisplayLoginMessage(false);
          });
        } catch (error) {
          console.log(error);
          setDisplayLoginError(true);
          setDisplayLoginMessage(false);
        }
    }

    const handleLoginSecure = (serverURL, username, password) => {
        console.log("Secure Server URL: ", serverURL);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleFilterUpdate = (filter, top, skip, orderby, count) => {
        // console.log("Filter: ", filter);
        // console.log("Top: ", top);
        // console.log("Skip: ", skip);
        // console.log("Orderby: ", orderby);
        // console.log("Count: ", count);
        setFilter(filter);
        setTop(top);
        setSkip(skip);
        setOrderby(orderby);
        setLoading(true)
        resetLists();
        setSearch(!search);
        resetLoading();

        setSkipClinic(skip);
        setSkipPatient(skip);
        setSkipPractitioner(skip);
        setSkipBalance(skip);
        setSkipFunctional(skip);
        setSkipOhs(skip);
        setSkipRevLunge(skip);
        setSkipRom(skip);
        setSkipSidePost(skip);
        setSkipSlh(skip);
        setSkipVj(skip);
        setSkipWa(skip);
        setSkipAddress(skip);
        setSkipPhoneNumber(skip);
        setSkipSubscriptions(skip);
        setSkipUploadFiles(skip);
        setSkipPermission(skip);
        setSkipBalanceGroup(skip);
        setSkipFunctionalGroup(skip);
        setSkipOhsGroup(skip);
        setSkipRevLungeGroup(skip);
        setSkipRomGroup(skip);
        setSkipSidePostGroup(skip);
        setSkipSlhGroup(skip);
        setSkipVjGroup(skip);
        setSkipWaGroup(skip);
        setSkipAssessmentGroup(skip);
        setSkipAssessmentFrame(skip);
        setSkipWorkflow(skip);
        setSkipEquipment(skip);
        setSkipEquipmentText(skip);
        setSkipExercise(skip);
        setSkipExerciseText(skip);
        setSkipCorrectives(skip);
        setSkipCorrectiveProtocol(skip);
        setSkipProtocol(skip);
        setSkipProtocolSession(skip);
        setSkipWorkflow(skip);
        setSkipClinicCF(skip);
        setSkipPatientCF(skip);
        setSkipPractitionerCF(skip);
        setSkipAssessmentCF(skip);
        setSkipWorkflowCF(skip);
        setSkipOrganization(skip);
        setSkipCfMetadata(skip);
    }

    const resetLists = () => {
        setClinicList([]);
        setPatientList([]);
        setPractitionerList([]);
        setBalanceList([]);
        setFunctionalList([]);
        setOhsList([]);
        setRevLungeList([]);
        setRomList([]);
        setSidePostList([]);
        setSlhList([]);
        setVjList([]);
        setWaList([]);
        setAddressList([]);
        setPhoneNumberList([]);
        setSubscriptionList([]);
        setUploadFileList([]);
        setPermissionList([]);
        setBalanceGroupList([]);
        setFunctionalGroupList([]);
        setOhsGroupList([]);
        setRevLungeGroupList([]);
        setRomGroupList([]);
        setSidePostGroupList([]);
        setSlhGroupList([]);
        setVjGroupList([]);
        setWaGroupList([]);
        setAssessmentGroupList([]);
        setAssessmentFrameList([]);
        setWorkflowList([]);
        setEquipmentList([]);
        setEquipmentTextList([]);
        setExerciseList([]);
        setExerciseTextList([]);
        setCorrectivesList([]);
        setCorrectiveProtocolList([]);
        setProtocolList([]);
        setProtocolSessionList([]);
        setClinicCFList([]);
        setPatientCFList([]);
        setPractitionerCFList([]);
        setAssessmentCFList([]);
        setWorkflowCFList([]);
        setOrganizationList([]);

        setClinicError("");
        setPatientError("");
        setPractitionerError("");
        setBalanceError("");
        setFunctionalError("");
        setOhsError("");
        setRevLungeError("");
        setRomError("");
        setSidePostError("");
        setSlhError("");
        setVjError("");
        setWaError("");
        setAddressError("");
        setPhoneNumberError("");
        setSubscriptionError("");
        setUploadFileError("");
        setPermissionError("");
        setBalanceGroupError("");
        setFunctionalGroupError("");
        setOhsGroupError("");
        setRevLungeGroupError("");
        setRomGroupError("");
        setSidePostGroupError("");
        setSlhGroupError("");
        setVjGroupError("");
        setWaGroupError("");
        setAssessmentGroupError("");
        setAssessmentFrameError("");
        setWorkflowError("");
        setEquipmentError("");
        setEquipmentTextError("");
        setExerciseError("");
        setExerciseTextError("");
        setCorrectivesError("");
        setCorrectiveProtocolError("");
        setProtocolError("");
        setProtocolSessionError("");
        setClinicCFError("");
        setPatientCFError("");
        setPractitionerCFError("");
        setAssessmentCFError("");
        setWorkflowCFError("");
        setOrganizationError("");
        setCfMetadataError("");
    }

    // signal start querying the server
    const resetLoading = () => {
        setClinicLoading(true);
        setPatientLoading(true);
        setPractitionerLoading(true);
        setBalanceLoading(true);
        setFunctionalLoading(true);
        setOhsLoading(true);
        setRevLungeLoading(true);
        setRomLoading(true);
        setSidePostLoading(true);
        setSlhLoading(true);
        setVjLoading(true);
        setWaLoading(true);
        setAddressLoading(true);
        setPhoneNumberLoading(true);
        setSubscriptionLoading(true);
        setUploadFileLoading(true);
        setPermissionLoading(true);
        setBalanceGroupLoading(true);
        setFunctionalGroupLoading(true);
        setOhsGroupLoading(true);
        setRevLungeGroupLoading(true);
        setRomGroupLoading(true);
        setSidePostGroupLoading(true);
        setSlhGroupLoading(true);
        setVjGroupLoading(true);
        setWaGroupLoading(true);
        setAssessmentGroupLoading(true);
        setAssessmentFrameLoading(true);
        setWorkflowLoading(true);
        setEquipmentLoading(true);
        setEquipmentTextLoading(true);
        setExerciseLoading(true);
        setExerciseTextLoading(true);
        setCorrectivesLoading(true);
        setCorrectiveProtocolLoading(true);
        setProtocolLoading(true);
        setProtocolSessionLoading(true);
        setClinicCFLoading(true);
        setPatientCFLoading(true);
        setPractitionerCFLoading(true);
        setAssessmentCFLoading(true);
        setWorkflowCFLoading(true);
        setOrganizationLoading(true);
        setCfMetadataLoading(true);
    }

    const handleReset = () => {
        setDisplayLoginError(false);
        setDisplayLoginMessage(false);
        setToken("");
        resetLists();
    }


    const buildBaseControllerList = () => {
        var list = BaseControllerData;
        list.clinicList = clinicList;
        list.practitionerList = practitionerList;
        list.patientList = patientList;
        list.addressList = addressList;
        list.phoneNumberList = phoneNumberList;
        list.subscriptionList = subscriptionList;
        list.uploadFileList = uploadFileList;
        list.organizationList = organizationList;

        list.clinicError = clinicError;
        list.practitionerError = practitionerError;
        list.patientError = patientError;
        list.addressError = addressError;
        list.phoneNumberError = phoneNumberError;
        list.subscriptionError = subscriptionError;
        list.uploadFileError = uploadFileError;
        list.organizationError = organizationError;

        list.clinicLoading = clinicLoading;
        list.practitionerLoading = practitionerLoading;
        list.patientLoading = patientLoading;
        list.addressLoading = addressLoading;
        list.phoneNumberLoading = phoneNumberLoading;
        list.subscriptionLoading = subscriptionLoading;
        list.uploadFileLoading = uploadFileLoading;
        list.permissionLoading = permissionLoading;
        list.organizationLoading = organizationLoading;

        list.clinicQuery = clinicQuery;
        list.practitionerQuery = practitionerQuery;
        list.patientQuery = patientQuery;
        list.addressQuery = addressQuery;
        list.phoneNumberQuery = phoneNumberQuery;
        list.subscriptionQuery = subscriptionQuery;
        list.uploadFileQuery = uploadFileQuery;
        list.permissionQuery = permissionQuery;
        list.organizationQuery = organizationQuery;


        return list;
    }

    const buildAssessmentList = () => {
        var list = AssessmentListData;
        list.balance = balanceList;
        list.functional = functionalList;
        list.overheadSquat = ohsList;
        list.reverseLunge = revLungeList;
        list.rom = romList;
        list.sidePostural = sidePostList;
        list.singleLegHop = slhList;
        list.verticalJump = vjList;
        list.wallAngel = waList;
        list.permission = permissionList;
        list.assessmentFrame = assessmentFrameList;

        list.balanceError = balanceError;
        list.functionalError = functionalError;
        list.overheadSquatError = ohsError;
        list.reverseLungeError = revLungeError;
        list.romError = romError;
        list.sidePosturalError = sidePostError;
        list.singleLegHopError = slhError;
        list.verticalJumpError = vjError;
        list.wallAngelError = waError;
        list.permissionError = permissionError;
        list.assessmentFrameError = assessmentFrameError;

        list.balanceLoading = balanceLoading;
        list.functionalLoading = functionalLoading;
        list.overheadSquatLoading = ohsLoading;
        list.reverseLungeLoading = revLungeLoading;
        list.romLoading = romLoading;
        list.sidePosturalLoading = sidePostLoading;
        list.singleLegHopLoading = slhLoading;
        list.verticalJumpLoading = vjLoading;
        list.wallAngelLoading = waLoading;
        list.permissionLoading = permissionLoading;
        list.assessmentFrameLoading = assessmentFrameLoading

        list.balanceQuery = balanceQuery;
        list.functionalQuery = functionalQuery;
        list.overheadSquatQuery = ohsQuery;
        list.reverseLungeQuery = revLungeQuery;
        list.romQuery = romQuery;
        list.sidePosturalQuery = sidePostQuery;
        list.singleLegHopQuery = slhQuery;
        list.verticalJumpQuery = vjQuery;
        list.wallAngelQuery = waQuery;
        list.permissionQuery = permissionQuery;
        list.assessmentFrameQuery = assessmentFrameQuery


        return list;
    }

    const buildAssessmentGroupList = () => {
        var list = AssessmentGroupListData;
        list.assessmentGroup = assessmentGroupList;
        list.groupBalance = balanceGroupList;
        list.groupFunctional = functionalGroupList;
        list.groupOverheadSquat = ohsGroupList;
        list.groupReverseLunge = revLungeGroupList;
        list.groupRom = romGroupList;
        list.groupSidePostural = sidePostGroupList;
        list.groupSingleLegHop = slhGroupList;
        list.groupVerticalJump = vjGroupList;
        list.groupWallAngel = waGroupList;
        list.assessmentFrame = assessmentFrameList;
        list.workflow = workflowList;

        list.groupBalanceError = balanceGroupError;
        list.groupFunctionalError = functionalGroupError;
        list.groupOverheadSquatError = ohsGroupError;
        list.groupReverseLungeError = revLungeGroupError;
        list.groupRomError = romGroupError;
        list.groupSidePosturalError = sidePostGroupError;
        list.groupSingleLegHopError = slhGroupError;
        list.groupVerticalJumpError = vjGroupError;
        list.groupWallAngelError = waGroupError;
        list.assessmentGroupError = assessmentGroupError;
        list.assessmentFrameError = assessmentFrameError;
        list.workflowError = workflowError;
        
        list.groupBalanceLoading = balanceGroupLoading;
        list.groupFunctionalLoading = functionalGroupLoading;
        list.groupOverheadSquatLoading = ohsGroupLoading;
        list.groupReverseLungeLoading = revLungeGroupLoading;
        list.groupRomLoading = romGroupLoading;
        list.groupSidePosturalLoading = sidePostGroupLoading;
        list.groupSingleLegHopLoading = slhGroupLoading;
        list.groupVerticalJumpLoading = vjGroupLoading;
        list.groupWallAngelLoading = waGroupLoading;
        list.assessmentGroupLoading = assessmentGroupLoading;
        list.assessmentFrameLoading = assessmentFrameLoading;
        list.workflowLoading = workflowLoading;

        list.groupBalanceQuery = balanceGroupQuery;
        list.groupFunctionalQuery = functionalGroupQuery;
        list.groupOverheadSquatQuery = ohsGroupQuery;
        list.groupReverseLungeQuery = revLungeGroupQuery;
        list.groupRomQuery = romGroupQuery;
        list.groupSidePosturalQuery = sidePostGroupQuery;
        list.groupSingleLegHopQuery = slhGroupQuery;
        list.groupVerticalJumpQuery = vjGroupQuery;
        list.groupWallAngelQuery = waGroupQuery;
        list.assessmentGroupQuery = assessmentGroupQuery;
        list.assessmentFrameQuery = assessmentFrameQuery;
        list.workflowQuery = workflowQuery;

        return list;
    }


    const buildProtocolControllerList = () => {
        var list = ProtocolListData;
        list.equipmentList = equipmentList;
        list.equipmentTextList = equipmentTextList;
        list.protocolList = protocolList;
        list.protocolSessionList = protocolSessionList;
        list.exerciseList = exerciseList;
        list.exerciseTextList = exerciseTextList;
        list.correctivesList = correctivesList;
        list.correctiveProtocolList = correctiveProtocolList;

        list.equipmentError = equipmentError;
        list.equipmentTextError = equipmentTextError;
        list.protocolError = protocolError;
        list.protocolSessionError = protocolSessionError;
        list.exerciseError = exerciseError;
        list.exerciseTextError = exerciseTextError;
        list.correctivesError = correctivesError;
        list.correctiveProtocolError = correctiveProtocolError;

        list.equipmentLoading = equipmentLoading;
        list.equipmentTextLoading = equipmentTextLoading;
        list.protocolLoading = protocolLoading;
        list.protocolSessionLoading = protocolSessionLoading;
        list.exerciseLoading = exerciseLoading;
        list.exerciseTextLoading = exerciseTextLoading;
        list.correctivesLoading = correctivesLoading;
        list.correctiveProtocolLoading = correctiveProtocolLoading;

        list.equipmentQuery = equipmentQuery;
        list.equipmentTextQuery = equipmentTextQuery;
        list.protocolQuery = protocolQuery;
        list.protocolSessionQuery = protocolSessionQuery;
        list.exerciseQuery = exerciseQuery;
        list.exerciseTextQuery = exerciseTextQuery;
        list.correctivesQuery = correctivesQuery;
        list.correctiveProtocolQuery = correctiveProtocolQuery;

        
        console.log("Protocol List: ", list);
        return list;
    }

    const buildCustomFieldList = () => {
        var list = CustomFieldsListData;
        list.clinicCFList = clinicCFList;
        list.practitionerCFList = practitionerCFList;
        list.patientCFList = patientCFList;
        list.assessmentCFList = assessmentCFList;
        list.workflowCFList = workflowCFList;
        list.cfMetadataList = cfMetadataList;

        list.clinicCFError = clinicCFError;
        list.practitionerCFError = practitionerCFError;
        list.patientCFError = patientCFError;
        list.assessmentCFError = assessmentCFError;
        list.workflowCFError = workflowCFError;
        list.cfMetadataError = cfMetadataError;

        list.clinicCFLoading = clinicCFLoading;
        list.practitionerCFLoading = practitionerCFLoading;
        list.patientCFLoading = patientCFLoading;
        list.assessmentCFLoading = assessmentCFLoading;
        list.workflowCFLoading = workflowCFLoading;
        list.cfMetadataLoading = cfMetadataLoading;

        list.clinicCFQuery = clinicCFQuery;
        list.practitionerCFQuery = practitionerCFQuery;
        list.patientCFQuery = patientCFQuery;
        list.assessmentCFQuery = assessmentCFQuery;
        list.workflowCFQuery = workflowCFQuery;
        list.cfMetadataQuery = cfMetadataQuery;

        return list;
    }

    const onFilterFieldChange = (e, value, id) => {
        console.log("id: ", id);
        console.log("value: ", value);
        if (id === "controller") {
            setController(value);
        } else if (id === "syncMode") {
            setSyncMode(value);
        } else if (id === "reset") {
            resetLists();
        }
    }

    const handleHeaderClick = (e, id) => {
        // e.preventDefault();
        setIsHeaderCollapsed(!isHeaderCollapsed);
    }
    return (
        <div>
            {/* <h2>Regional Server Test</h2> */}
                <div>
                    {!isHeaderCollapsed && <div className='collapse' onClick={handleHeaderClick}><div className="left">Regional Server Test Tool</div><ExpandMoreIcon /> </div>}
                    {isHeaderCollapsed && <div className='collapse' onClick={handleHeaderClick}><div className="left">Regional Server Test Tool</div><ChevronRightIcon /></div>}
                    <LoginPanel 
                        handleLogin={handleLogin}
                        displayLoginMessage={displayLoginMessage}
                        displayLoginError={displayLoginError}
                        reset={false}
                        isCollapsed={isHeaderCollapsed}
                    />
                    {displayLoginMessage && <ControllerFilter
                        onFieldChange={onFilterFieldChange}
                        controller={controller}
                        syncMode={syncMode}
                        isCollapsed={isHeaderCollapsed}
                        base = {buildBaseControllerList()}
                        assessment = {buildAssessmentList()}
                        group = {buildAssessmentGroupList()}
                        correctives = {buildProtocolControllerList()}
                        customFields = {buildCustomFieldList()}
                    />}
                    {displayLoginMessage && <ODataFilter
                        handleFilterUpdate={handleFilterUpdate}
                        isCollapsed={isHeaderCollapsed}
                    />}
                </div>
            <Box style={{width:1300}}>
                  <TabContext value={value} >
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="Base" value="1" />
                          <Tab label="Assessments" value="2" />
                          <Tab label="Assessment Groups" value="3" />
                          <Tab label="Correctives" value="4" />
                          <Tab label="Custom Fields" value="5" />
                          </Tabs>
                      </Box>
                      <TabPanel value='1'>
                          <BaseControllersTables 
                               data ={(buildBaseControllerList() || BaseControllerData)}
                               serverURL={serverURL}
                               token={token}
                            />
                      </TabPanel>
                        
                      <TabPanel value='2'>
                          <AssessmentControllersTables 
                            data ={(buildAssessmentList() || AssessmentListData)}
                            serverURL={serverURL}
                            token={token}
                          />
                      </TabPanel> 
                      <TabPanel value='3'>
                        <AssessmentGroupControllersTables 
                                data ={(buildAssessmentGroupList() || AssessmentGroupListData)}
                                serverURL={serverURL}
                                token={token}
                            />
                        </TabPanel> 
                      <TabPanel value='4'>
                            <ProtocolControllersTables 
                                data ={(buildProtocolControllerList() || ProtocolListData)}
                                serverURL={serverURL}
                                token={token}
                            />
                      </TabPanel> 
                      <TabPanel value='5'>
                            <CustomFieldControllersTables 
                                data ={(buildCustomFieldList() || CustomFieldsListData)}
                                serverURL={serverURL}
                                token={token}
                            />
                      </TabPanel> 
                  </TabContext>
              </Box>
        </div>
    );
}

export default RegionalServerTest;