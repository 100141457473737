export const BaseControllerData = {
    "clinicList": [],
    "practitionerList": [],
    "patientList": [],
    "addressList": [],
    "phoneNumberList": [],
    "subscriptionList": [],
    "uploadFileList": [],
    "organizationList": [],
    "clinicError": "",
    "practitionerError": "",
    "patientError": "",
    "addressError": "",
    "phoneNumberError": "",
    "subscriptionError": "",
    "uploadFileError": "",
    "organizationError": "",
    "clinicLoading": false,
    "practitionerLoading": false,
    "patientLoading": false,
    "addressLoading": false,
    "phoneNumberLoading": false,
    "subscriptionLoading": false,
    "uploadFileLoading": false,
    "organizationLoading": false,
}