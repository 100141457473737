import React from "react";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { ClinicColumns, PractitionerColumns, PatientColumns, PhoneColumns, SubscriptionColumns, UploadFileColumns, RSTOrganizationColumns } from "../../datatablesource";
import RSTAssessmentTable from "../datatable/RSTAssessmentTable";

const BaseControllersTables = ({ data, serverURL, token }) => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("Base Controller Data: ", data);

    return (
    <Box style={{width:1250}}>
    <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Clinic" value="1" />
                <Tab label="Practitioner" value="2" />
                <Tab label="Patient" value="3" />
                <Tab label="Address" value="4" />
                <Tab label="Phone Number" value="5" />
                <Tab label="Subscriptions" value="6" />
                <Tab label="Upload Files" value="7" />
                <Tab label="Organization" value="8" />
            </Tabs>
        </Box>
        <TabPanel value='1'>
        {data.clinicError === "" && 
        <RSTAssessmentTable 
                rows ={(data.clinicList || [])}
                isLoading={data.clinicLoading}
                columns ={ClinicColumns}
                serverURL={serverURL}
                token={token}
                type="Clinic"
            />
        }
        {data.clinicError !== "" && <div>{data.clinicError}</div>}
        </TabPanel>
        <TabPanel value='2'>
            {data.practitionerError === "" && <RSTAssessmentTable 
                rows ={(data.practitionerList || [])}
                isLoading={data.practitionerLoading}
                columns ={PractitionerColumns}
                serverURL={serverURL}
                token={token}
                type="Practitioner"
                />
            }
            {data.practitionerError !== "" && <div>{data.practitionerError}</div>}
        </TabPanel>
        <TabPanel value='3'>
            {data.patientError === "" && <RSTAssessmentTable 
                    rows ={(data.patientList || [])}
                    isLoading={data.patientLoading}
                    columns ={PatientColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Patient"
                    />
            }
            {data.patientError !== "" && <div>{data.patientError}</div>}
        </TabPanel> 
        <TabPanel value='4'>
            {data.addressError === "" && <RSTAssessmentTable 
                    rows ={(data.addressList || [])}
                    isLoading={data.addressLoading}
                    columns ={PhoneColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Address"
                />
            }
            {data.addressError !== "" && <div>{data.addressError}</div>}
        </TabPanel> 
        <TabPanel value='5'>
            {data.phoneNumberError === "" && <RSTAssessmentTable 
                    rows ={(data.phoneNumberList || [])}
                    isLoading={data.phoneNumberLoading}
                    columns ={PhoneColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Phone Number"
                />
            }
            {data.phoneNumberError !== "" && <div>{data.phoneNumberError}</div>}
        </TabPanel> 
        <TabPanel value='6'>
            {data.subscriptionError === "" && <RSTAssessmentTable 
                    rows ={(data.subscriptionList || [])}
                    isLoading={data.subscriptionLoading}
                    columns ={SubscriptionColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Subscription"
                />
            }
            {data.subscriptionError !== "" && <div>{data.subscriptionError}</div>}
        </TabPanel> 
        <TabPanel value='7'>
            {data.uploadFileError === "" && <RSTAssessmentTable 
                    rows ={(data.uploadFileList || [])}
                    isLoading={data.uploadFileLoading}
                    columns ={UploadFileColumns}
                    serverURL={serverURL}
                    token={token}
                    type="UploadFile"
                />
            }
            {data.uploadFileError !== "" && <div>{data.uploadFileError}</div>}
        </TabPanel>
        <TabPanel value='8'>
            {data.organizationError === "" && <RSTAssessmentTable 
                    rows ={(data.organizationList || [])}
                    isLoading={data.organizationUploading}
                    columns ={RSTOrganizationColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Organization"
                />
            }
            {data.organizationError !== "" && <div>{data.organizationError}</div>}
        </TabPanel>
      
    </TabContext>
</Box>
)
}

export default BaseControllersTables;