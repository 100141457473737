//import "./licencelist.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import MergeClinics from "../../components/clinics/MergeClinics";
const MergeClinicsPage = (props) => {
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <MergeClinics  />
      </div>
    </div>
  )
}

export default MergeClinicsPage