import "../chart/chart.scss";
import React from 'react';
import {useState, useEffect} from 'react';
import { useSession } from "../../context/SessionContext";
import LicenceData from "../../data/LicenceData";
import { useAuthContext } from '../../context/AuthContext';
import { useLoginServer } from '../../context/LoginServerContext';
import PieChartLic from '../chart/PieChartLic';
import LineChartLic from "../chart/LineChartLic";
import { formatRFC3339 } from "date-fns";
import PieChartLicG from "../chart/PieChartLicG";
import LineChartLicG from "../chart/LineChartLicG";

// const data1 =  [
//     { id: 0, value: 10, label: 'Bal' },
//     { id: 1, value: 15, label: 'Func' },
//     { id: 2, value: 20, label: 'ModVel' },
//     { id: 3, value: 35, label: 'OvHdSq' },
//     { id: 4, value: 10, label: 'RevL' },
//     { id: 5, value: 22, label: 'ROM' },
//     { id: 6, value: 30, label: 'SideP' },
//     { id: 7, value: 38, label: 'SLH' },
//     { id: 8, value: 60, label: 'VertJ' },
//     { id: 9, value: 10, label: 'WallA' },
// ];

// const data2 =  [
//     { id: 0, value: 10, label: '2022-07' },
//     { id: 1, value: 15, label: '2022-08' },
//     { id: 2, value: 20, label: '2022-09' },
//     { id: 3, value: 35, label: '2022-10' },
//     { id: 4, value: 10, label: '2022-11' },
//     { id: 5, value: 22, label: '2022-12' },
//     { id: 6, value: 30, label: '2023-01' },
//     { id: 7, value: 38, label: '2023-02' },
//     { id: 8, value: 60, label: '2023-03' },
//     { id: 9, value: 10, label: '2023-04' },
//     { id: 10, value: 10, label: '2023-05' },
//     { id: 11, value: 10, label: '2023-06' },
// ];

// const pData = [5,8,3,9,15,17,20,25,30,15,20,5];
// const pData1 = [1,2,8,6,4,8,3,7,2,15,8,12];
// const pData2 = [9,2,7,3,6,8,1,9,3,15,13,10];
// const pData3 = [10,14,2,8,20,16,10,9,14,15,24,10];
// const pData4 = [1,5,9,3,6,7,10,5,15,10,9,20];
// const pData5 = [4,5,7,3,1,9,10,21,10,15,5,9];
// const pData6 = [20,13,18,10,5,20,14,19,17,10,2,6];
// const pData7 = [1,2,8,3,9,4,10,15,2,14,3,20];
// const pData8 = [5,1,9,3,8,20,18,13,15,10,14,6];
// const pData9 = [1,9,3,2,5,7,8,1,6,4,0,8];

// const xLabels = [
//     '2022-07',
//     '2022-28' ,
//     '2022-09',
//     '2022-10',
//     '2022-11',
//     '2022-12',
//     '2023-01',
//     '2023-02',
//     '2023-03',
//     '2023-04',
//     '2023-05',
//     '2023-06'
// ];

// const series = [
//     { data: pData, label: "Bal" },
//     { data: pData1, label: "Func" },
//     { data: pData2, label: "MoVel" },
//     { data: pData3, label: "OvHdSq" },
//     { data: pData4, label: "RevL" },
//     { data: pData5, label: "ROM" },
//     { data: pData6, label: "SideP" },
//     { data: pData7, label: "SLH" },
//     { data: pData8, label: "VertJ" },
//     { data: pData9, label: "WallA" }
//   ];

const graphByType = (array) => {
    const data = [];
    array.forEach(element => {
        data.push({ value:element.series.reduce((total, item) =>  total + item,0), label:element.type});
    });
    return data;
}

const graphByDate = (array) => {
    const data = [];
    array.forEach(element => {
        data.push({ value:element.series.reduce((total, item) => total + item,0), label:element.date});
    });
    return data;
}

const graphSeries = (array) => {
    const data = [];
    array.forEach(element => {
        data.push({ data:element.series, label:element.type});
    });
    return data;
}

const lineGraphSeries = (array) => {
    const data = [];
    array.forEach(element => {
        data.push({  name: element.date,
        Bal: element.series[0],
        BridPos: element.series[1],
        CliGait: element.series[2],
        Funct: element.series[3],
        FXSTS: element.series[4],
        GlfPos: element.series[5],
        GlfStPlvcRot: element.series[6],
        GlfSwAn: element.series[7],
        HRaise: element.series[8],
        KAMS: element.series[9],
        KAMSIL: element.series[10],
        KAMSExp: element.series[11],
        KAMSGolf: element.series[12],
        KAMSGolfExp: element.series[13],
        MCtSib: element.series[14],
        OHSQ: element.series[15],
        PAngel: element.series[16],
        Posture: element.series[17],
        PrPlkPos: element.series[18],
        RevL:  element.series[19],
        ROF:  element.series[20],
        ROFGait: element.series[21],
        ROM: element.series[22],
        SdPlkPos: element.series[23],
        SLH: element.series[24],
        STS: element.series[25],
        TUAG: element.series[26],
        VertJ: element.series[27],
        WChair: element.series[28]
        });
    });
    return data;
}

const graphReChart = (array) => {
    const data = [];
    array.forEach(element => {
        data.push({ name:element.label, value:element.value});
    });
    return data;
}
const graphXlabels = (array) => {
    const data = [];
    array.forEach(element => {
        data.push(element.date);
    });
    return data;
}

const LicenceUsage = ({id, source}) => {

    const session = useSession();
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [row, setRow] = useState(LicenceData);
    const [assessmentData, setAssessmentData] = useState([]);


    // console.log("Licence: ",row);
    // console.log("Assessment Data: ",assessmentData);
    console.log("source: ",source);
    

    useEffect (() => {
      if (source === "licence") {
        CallSubscriptionServer(id);
      } else {
        CallRegionalServer(id);
        }
    },[id]);


    const CallSubscriptionServer = (id) => {
        try { 
            fetch(session.subscriptionServer + "/tables/subscription/" + id, {
                method: 'GET',
                headers:{"Content-type":"application/json"}
            }).then ((response) => response.json())
            .then ((data) => {
                setRow(data.queryable[0]);
                getAssessmentData(data.queryable[0]);
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
    }

    const CallRegionalServer = (id) => {
        try { 
        fetch(loginServerURL + "/tables/subscription/" + id, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
        .then ((data) => {
            setRow(data.queryable[0]);
            getAssessmentData(data.queryable[0]);
        }).catch ((error) => {
            console.log(error);
        });  
      } catch(err) {
        console.log(err);
      }
    }

    const getAssessmentData = (licence) => {
        try {
            var dummy = licence.createdAt.split("T");
            var datePart = dummy[0].split("-");
            var startingDay = datePart[2];
            // var sqlCommand = "";
            // if (source === "licence") {
            const sqlCommand = loginServerURL + "/api/clinic/getassessments?id=" + licence.clinicId + "&startingDay="+startingDay;
            // } else if (source === "clinic") {
            //     sqlCommand = loginServerURL + "/api/clinic/getassessments?id=" + id + "&startingDay="+startingDay;
            // } else {
            //     sqlCommand = loginServerURL + "/api/clinic/getassessments?id=" + licence.clinicId + "&startingDay="+startingDay;
            // }
            console.log(sqlCommand);
            fetch(sqlCommand, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                return response.json();
              } else {
                throw new Error(response.status);
              }
            })
            .then ((data) => {
                console.log("Organization fetched: ",data);
                setAssessmentData(data)
                console.log("Assessments: ",data);
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
    }

    const handleOngraphicClick = (event, data) => {
    }

    return (
        <> 
        {assessmentData.length > 0 &&
            <div className="dashboard">
                <div className="dashboardContainer">
                    <div style={{backgroundColor:"whitesmoke"}}>
                        <center>
                            Purchase Name:
                            {`  `}
                            <b>{row.purchaseName}</b>
                            {`   -   `}
                             Purchase Email:
                             {`  `}
                           <b>{row.purchaseEmail} </b>
                           {`    -   `}
                            Purchase Date: 
                            {`  `}
                            <b>{row.purchaseDate.split("T")[0]} </b>
                            {`   -    `}
                            Expiration Date:
                            {`  `}
                            <b>{row.expirationDate.split("T")[0]}</b>
                        </center>
                    </div>
                    <div className="charts">
                        <div>
                            <h2 ><center>Assessment Summary by Type and Month</center></h2>
                            <LineChartLicG series={lineGraphSeries(assessmentData[1])} />
                        </div>
                    </div>
                    {/* <div className="charts">
                        <div>
                            <center className="title">Assessment Summary by Type and Month</center>
                            <LineChartLic series={graphSeries(assessmentData[0])} xLabels={graphXlabels(assessmentData[1])} />
                        </div>
                    </div> */}
                    <div className="charts">
                        {/* <div>
                            <center className="title">Assessment Summary by Type</center>
                            <PieChartLic data={graphByType(assessmentData[0])} />
                        </div> */}
                        {/* <div style={{marginLeft:100}}>
                            <center className="title">Assessment Summary by Month</center>
                            <PieChartLic data={graphByDate(assessmentData[1])}/>
                        </div> */}
                        <div>
                            <h2 style={{backgroundColor:"whitesmoke"}}><center>Assessment Summary by Type</center></h2>
                            <PieChartLicG width={500} height={620} data={graphReChart(graphByType(assessmentData[0]))}/>
                        </div>
                        <div>
                            <h2 style={{backgroundColor:"whitesmoke"}}><center>Assessment Summary by Month</center></h2>
                            <PieChartLicG width={500} height={620} data={graphReChart(graphByDate(assessmentData[1]))}/>
                        </div>
                    </div>
                </div>
            </div>
                }
        </>
    );
}

export default LicenceUsage;