//import "./licencelist.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import AnonymizeClinic from "../../components/clinics/AnonymizeClinic";
const AnonymizeClinicPage = (props) => {
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <AnonymizeClinic  />
      </div>
    </div>
  )
}

export default AnonymizeClinicPage