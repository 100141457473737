export const CustomFieldsListData = {
    "clinicCFList": [],
    "practitionerCFList": [],
    "patientCFList": [],
    "assessmentCFList": [],
    "workflowCFList": [],
    "cfMetadata": [],

    "clinicCFError":"",
    "practitionerCFError":"",
    "patientCFError":"",
    "assessmentCFError":"",
    "workflowCFError":"",
    "cfMetadataError":"",

    "ClinicCFLoading": false,
    "PractitionerCFLoading": false,
    "PatientCFLoading": false,
    "AssessmentCFLoading": false,
    "WorkflowCFLoading": false,
    "CFMetadataLoading": false,
}