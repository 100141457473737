import "./login.scss";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {AuthContext} from "../../context/AuthContext"
import logo from "../../images/kinetisense-logo-black.png";
import { useLoginServer, useUpdateLoginServer } from "../../context/LoginServerContext";
import { SessionData } from "../../data/SessionData";
import { GoogleLogin } from '@react-oauth/google';

const Login = () => {
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [serverURL, setServerURL] = useState("");
  const [displayMessage, setDisplayMessage] = useState(false)
  const [displayError,setDisplayError] = useState(false);
  const [token, setToken] = useState();
  const updateServer = useUpdateLoginServer();
  const navitage = useNavigate()

  const {dispatch} = useContext(AuthContext)
  console.log("ServerURL: ",serverURL)

  useEffect (() => {
    console.log("sessionData: ",SessionData);
    localStorage.setItem("KinetisenseSession", JSON.stringify(SessionData));
  },[]);
  
  const handleLogin = (e) => {

    const httpBody = { username: username, 
      password: password, 
      version: "6.0.0.1", 
      app: "Kinetisense", 
      platform: "windows"};

    e.preventDefault();
    fetch(serverURL+'/api/login', {
      method: 'POST',
      headers:{"Content-type":"application/json"},
      body: JSON.stringify(httpBody)
    }).then ((response) => response.json())
    .then ((data) => {
      console.log(data.mobileServiceAuthenticationToken);
      setToken(data.mobileServiceAuthenticationToken);
      setDisplayMessage(true);
      dispatch({type:"LOGIN", payload:data.mobileServiceAuthenticationToken});
      updateServer(username, serverURL, data.mobileServiceAuthenticationToken);
      // reset sessions settings
      navitage("/")
  
    }).catch ((error) => {
      console.log(error);
      setDisplayError(true);
    });
  }

  const ServerLogin = (token) => {

    const httpBody = { token: token, 
      provider: 'Google', 
      version: "6.0.0.1", 
      app: "Kinetisense", 
      platform: "windows",
      organizationId: ""
    };

    // e.preventDefault();
    fetch(serverURL+'/api/loginwithtoken', {
      method: 'POST',
      headers:{"Content-type":"application/json"},
      body: JSON.stringify(httpBody)
    }).then ((response) => response.json())
    .then ((data) => {
      console.log("data: ",data);
      console.log(data.mobileServiceAuthenticationToken);
      setToken(data.mobileServiceAuthenticationToken);
      setDisplayMessage(true);
      dispatch({type:"LOGIN", payload:data.mobileServiceAuthenticationToken});
      updateServer(username, serverURL, data.mobileServiceAuthenticationToken);
      // reset sessions settings
      navitage("/")
  
    }).catch ((error) => {
      console.log(error);
      setDisplayError(true);
  });
  }

  return (
    <div className="login">
      
          <form onSubmit={handleLogin}>
          <img className="img" src={logo} alt="logo" />
            <h1>Kinetisense Admin Tool</h1>
            {/* <input
              type="Text"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
            /> */}
            <select
              value={serverURL}
              onChange={(e) => setServerURL(e.target.value)}
              placeholder="Select a server"
            >
                <option value="">Select a server</option>
                <option value="https://kinetisensecloudeducation.azurewebsites.net">Education</option>
                <option value="https://kinetisensecloudDevApp.azurewebsites.net">DEV</option>
                <option value="https://kinetisensecloudeastusapp.azurewebsites.net">East US</option>
                <option value="https://kinetisensecloudcentralcanada.azurewebsites.net">Canada Central</option>
                <option value="https://kinetisensecloudwestusapp.azurewebsites.net">West US</option>
                <option value="https://kinetisensecloudeastasiaapp.azurewebsites.net">East Asia</option>
                <option value="https://kinetisensecloudwesteurope.azurewebsites.net">West Europe</option>
                <option value="https://kinetisensecloudsoutheastaustralia.azurewebsites.net">Australia</option>
                <option value="http://localhost:5000">Local - Test Only</option>
                <option value="https://kinetisensedotnet8testserver-h2dzgya2g6ema4b5.canadacentral-01.azurewebsites.net">.net8 server</option>

              </select>
            <GoogleLogin 
                  onSuccess={credentialResponse => {
                    console.log(credentialResponse.credential);
                    ServerLogin(credentialResponse.credential);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
              />
            {/* <button type="submit">Login</button> */}
            {displayMessage && <span>Login Successful</span>}
            {displayError && <span>Login Failed</span>}
          </form>
        </div>
       
    
  );
};

export default Login;
