

const DeleteClinic = () => {
    return (
      <div>
          <div>
        <h1>Delete Clinic</h1>
        </div>
      </div>
    )
  }
  export default DeleteClinic;