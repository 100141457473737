const HL7OAuthData = {
    "name": "Kinetisense 360",
    "clientId": "",
    "clientSecret": "",
    "state":"",
    "authorizedOrigin1":"http://localhost",
    "authorizedOrigin2":"",
    "hl7FHIRServerURL":"https://r4.smarthealthit.org",
    "redirect1":"http://localhost",
    "redirect2":"{RegionalServerURL}/app/callback",
}

export default HL7OAuthData;