export const ProtocolListData = {
    "equipmentList": [],
    "equipmentTextList": [],
    "exerciseList": [],
    "exerciseTextList": [],
    "correctivesList": [],
    "correctiveProtocolList": [],
    "protocolList": [],
    "protocolSessionList": [],

    "equipmentError": "",
    "equipmentTextError": "",
    "exerciseError": "",
    "exerciseTextError": "",
    "correctivesError": "",
    "correctiveSessionError": "",
    "protocolError": "",
    "protocolSessionError": "",

    "equipmentLoading": false,
    "equipmentTextLoading": false,
    "exerciseLoading": false,
    "exerciseTextLoading": false,
    "correctivesLoading": false,
    "correctiveSessionLoading": false,
    "protocolLoading": false,
    "protocolSessionLoading": false,

}