import "./ControllerFilter.scss";
import React from 'react';
import { ControllerList } from '../../data/ControlledLists';

const ControllerFilter = ({onFieldChange, controller, syncMode, isCollapsed, base, assessment, group, correctives, customFields}) => {

    return (
        <>
            {!isCollapsed && <div className="box">
                <div className="box1">
                    <div className="text">
                        <label>Controller</label>
                    </div>
                    <select
                        onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                        name="controller"
                        id="controller"
                        className ="text"
                        value={controller}
                    >
                        <option value="All">All</option>
                        {ControllerList.sort((a, b) => a.value.localeCompare(b.value)).map((controller) => {
                            return (
                                <option value={controller.key} >
                                    {controller.value}
                                </option>
                            );
                        })}
                    </select> 
                    <div className="text">
                        <label>Sync Mode</label>
                    </div>
                    <div >
                        <input
                            type="checkbox"
                            onChange={(event, value) => onFieldChange(event, event.target.checked, event.target.id)}
                            name="syncMode"
                            id="syncMode"
                            checked={syncMode}
                            // value={syncMode}
                            className ="checkbox"
                        />
                    </div>
                </div>
               
            <div className="errorBank">

                <div className="bank">
                    {(base.clinicQuery && base.clinicError !=="" && !base.clinicLoading) && <div className="yeserror"></div>}
                    {(base.clinicQuery && base.clinicError === "" && !base.clinicLoading) && <div className="noerror"></div>}
                    {base.clinicLoading && <div className="loading"></div>}
                    {!base.clinicQuery && <div className="noquery"></div>}
                    {(base.practitionerQuery && base.practitionerError !== "" && !base.practitionerLoading) && <div className="yeserror"></div>}
                    {(base.practitionerQuery && base.practitionerError === "" && !base.practitionerLoading) && <div className="noerror"></div>}
                    {base.practitionerLoading && <div className="loading"></div>}
                    {!base.practitionerQuery && <div className="noquery"></div>}
                    {(base.patientQuery && base.patientError !== "" && !base.patientLoading) && <div className="yeserror"></div>}
                    {(base.patientQuery && base.patientError === "" && !base.patientLoading) && <div className="noerror"></div>}
                    {base.patientLoading && <div className="loading"></div>}
                    {!base.patientQuery  && <div className="noquery"></div>}
                    {(base.addressQuery && base.addressError !== "" && !base.addressLoading)  && <div className="yeserror"></div>}
                    {(base.addressQuery && base.addressError === "" && !base.addressLoading) && <div className="noerror"></div>}
                    {base.addressLoading && <div className="loading"></div>}
                    {!base.addressQuery && <div className="noquery"></div>}
                    {(base.phoneNumberQuery && base.phoneNumberError !== "" && !base.phoneNumberLoading) && <div className="yeserror"></div>}
                    {(base.phoneNumberQuery && base.phoneNumberError === "" && !base.phoneNumberLoading)  && <div className="noerror"></div>}
                    {base.phoneNumberLoading && <div className="loading"></div>}
                    {!base.phoneNumberQuery && <div className="noquery"></div>}
                    {(base.subscriptionQuery && base.subscriptionError !== "" && !base.subscriptionLoading) && <div className="yeserror"></div>}
                    {(base.subscriptionQuery && base.subscriptionError === "" && !base.subscriptionLoading) && <div className="noerror"></div>}
                    {base.subscriptionLoading && <div className="loading"></div>}
                    {!base.subscriptionQuery && <div className="noquery"></div>}
                    {(base.uploadFileQuery && base.uploadFileError !== "" && !base.uploadFileLoading) && <div className="yeserror"></div>}
                    {(base.uploadFileQuery && base.uploadFileError === "" && !base.uploadFileLoading) && <div className="noerror"></div>}
                    {base.uploadFileLoading && <div className="loading"></div>}
                    {!base.uploadFileQuery && <div className="noquery"></div>}
                    {(base.organizationQuery && base.organizationError !== "" && !base.organizationLoading) && <div className="yeserror"></div>}
                    {(base.organizationQuery && base.organizationError === "" && !base.organizationLoading) && <div className="noerror"></div>}
                    {base.organizationLoading && <div className="loading"></div>}
                    {!base.organizationQuery && <div className="noquery"></div>}
                </div>

                <div className="bank">
                    {(assessment.balanceQuery && assessment.balanceError !== "" && !assessment.balanceLoading) && <div className="yeserror"></div>}
                    {(assessment.balanceQuery &&assessment.balanceError === "" && !assessment.balanceLoading) && <div className="noerror"></div>}
                    {assessment.balanceLoading && <div className="loading"></div>}
                    {!assessment.balanceQuery && <div className="noquery"></div>}
                    {(assessment.functionalQuery && assessment.functionalError !== "" && !assessment.functionalLoading) && <div className="yeserror"></div>}
                    {(assessment.functionalQuery && assessment.functionalError === "" && !assessment.functionalLoading) && <div className="noerror"></div>}
                    {assessment.functionalLoading && <div className="loading"></div>}
                    {!assessment.functionalQuery && <div className="noquery"></div>}
                    {(assessment.overheadSquatQuery && assessment.overheadSquatError !== "" && !assessment.overheadSquatLoading) && <div className="yeserror"></div>}
                    {(assessment.overheadSquatQuery && assessment.overheadSquatError === "" && !assessment.overheadSquatLoading) && <div className="noerror"></div>}
                    {assessment.overheadSquatLoading && <div className="loading"></div>}
                    {!assessment.overheadSquatQuery && <div className="noquery"></div>}
                    {(assessment.reverseLungeQuery && assessment.reverseLungeError !== "" && !assessment.reverseLungeLoading) && <div className="yeserror"></div>}
                    {(assessment.reverseLungeQuery && assessment.reverseLungeError === "" && !assessment.reverseLungeLoading) && <div className="noerror"></div>}
                    {assessment.reverseLungeLoading && <div className="loading"></div>}
                    {!assessment.reverseLungeQuery && <div className="noquery"></div>}
                    {(assessment.romQuery && assessment.romError !== "" && !assessment.romLoading) && <div className="yeserror"></div>}
                    {(assessment.romQuery && assessment.romError === "" && !assessment.romLoading) && <div className="noerror"></div>}
                    {assessment.romLoading && <div className="loading"></div>}
                    {!assessment.romQuery && <div className="noquery"></div>}
                    {(assessment.singleLegHopQuery && assessment.singleLegHopError !== "" && !assessment.singleLegHopLoading) && <div className="yeserror"></div>}
                    {(assessment.singleLegHopQuery && assessment.singleLegHopError === "" && !assessment.singleLegHopLoading) && <div className="noerror"></div>}
                    {assessment.singleLegHopLoading && <div className="loading"></div>}
                    {!assessment.singleLegHopQuery && <div className="noquery"></div>}
                    {(assessment.verticalJumpQuery && assessment.verticalJumpError !== "" && !assessment.verticalJumpLoading)&& <div className="yeserror"></div>}
                    {(assessment.verticalJumpQuery && assessment.verticalJumpError === "" && !assessment.verticalJumpLoading)&& <div className="noerror"></div>}
                    {assessment.verticalJumpLoading && <div className="loading"></div>}
                    {!assessment.verticalJumpQuery && <div className="noquery"></div>}
                    {(assessment.wallAngelQuery && assessment.wallAngelError !== "" && !assessment.wallAngelLoading) && <div className="yeserror"></div>}
                    {(assessment.wallAngelQuery && assessment.wallAngelError === "" && !assessment.wallAngelLoading) && <div className="noerror"></div>}
                    {assessment.wallAngelLoading && <div className="loading"></div>}
                    {!assessment.wallAngelQuery && <div className="noquery"></div>}
                    {(assessment.permissionQuery  && assessment.permissionError !== "" && !assessment.permissionLoading) && <div className="yeserror"></div>}
                    {(assessment.permissionQuery && assessment.permissionError === "" && !assessment.permissionLoading) && <div className="noerror"></div>}
                    {assessment.permissionLoading && <div className="loading"></div>}
                    {!assessment.permissionQuery && <div className="noquery"></div>}
                    {(assessment.assessmentFrameQuery && assessment.assessmentFrameError !== "" && !assessment.assessmentFrameLoading) && <div className="yeserror"></div>}
                    {(assessment.assessmentFrameQuery && assessment.assessmentFrameError === "" && !assessment.assessmentFrameLoading) && <div className="noerror"></div>}
                    {assessment.assessmentFrameLoading && <div className="loading"></div>}
                    {!assessment.assessmentFrameQuery && <div className="noquery"></div>}
                </div>

                <div className="bank">
                    {(group.assessmentGroupQuery && group.assessmentGroupError !== "" && !group.assessmentGroupLoading) && <div className="yeserror"></div>}
                    {(group.assessmentGroupQuery && group.assessmentGroupError === "" && !group.assessmentGroupLoading)&& <div className="noerror"></div>}
                    {group.assessmentGroupLoading && <div className="loading"></div>}
                    {!group.assessmentGroupQuery && <div className="noquery"></div>}
                    {(group.groupBalanceQuery && group.groupBalanceError !=="" && !group.groupBalanceLoading) && <div className="yeserror"></div>}
                    {(group.groupBalanceQuery && group.groupBalanceError === "" && !group.groupBalanceLoading) && <div className="noerror"></div>}
                    {group.groupBalanceLoading && <div className="loading"></div>}
                    {!group.groupBalanceQuery && <div className="noquery"></div>}
                    {(group.groupFunctionalQuery && group.groupFunctionalError !== "" && !group.groupFunctionalLoading) && <div className="yeserror"></div>}
                    {(group.groupFunctionalQuery && group.groupFunctionalError === "" && !group.groupFunctionalLoading) && <div className="noerror"></div>}
                    {group.groupFunctionalLoading && <div className="loading"></div>}
                    {!group.groupFunctionalQuery && <div className="noquery"></div>}
                    {(group.groupOverheadSquatQuery && group.groupOverheadSquatError !== "" && !group.groupOverheadSquatLoading) && <div className="yeserror"></div>}
                    {(group.groupOverheadSquatQuery && group.groupOverheadSquatError === "" && !group.groupOverheadSquatLoading) && <div className="noerror"></div>}
                    {group.groupOverheadSquatLoading && <div className="loading"></div>}
                    {!group.groupOverheadSquatQuery && <div className="noquery"></div>}
                    {(group.groupReverseLungeQuery && group.groupReverseLungeError !== "" && !group.groupReverseLungeLoading) && <div className="yeserror"></div>}
                    {(group.groupReverseLungeQuery && group.groupReverseLungeError === "" && !group.groupReverseLungeLoading) && <div className="noerror"></div>}
                    {group.groupReverseLungeLoading && <div className="loading"></div>}
                    {!group.groupReverseLungeQuery && <div className="noquery"></div>}
                    {(group.groupRomQuery && group.groupRomError !== "" && !group.groupRomLoading) && <div className="yeserror"></div>}
                    {(group.groupRomQuery && group.groupRomError === "" && !group.groupRomLoading) && <div className="noerror"></div>}
                    {group.groupRomLoading && <div className="loading"></div>}
                    {!group.groupRomQuery && <div className="noquery"></div>}
                    {(group.groupSingleLegHopQuery && group.groupSingleLegHopError !== "" && !group.groupSingleLegHopLoading) && <div className="yeserror"></div>}
                    {(group.groupSingleLegHopQuery && group.groupSingleLegHopError === "" && !group.groupSingleLegHopLoading) && <div className="noerror"></div>}
                    {group.groupSingleLegHopLoading && <div className="loading"></div>}
                    {!group.groupSingleLegHopQuery && <div className="noquery"></div>}
                    {(group.groupVerticalJumpQuery && group.groupVerticalJumpError !== "" && !group.groupVerticalJumpLoading) && <div className="yeserror"></div>}
                    {(group.groupVerticalJumpQuery && group.groupVerticalJumpError === "" && !group.groupVerticalJumpLoading) && <div className="noerror"></div>}
                    {group.groupVerticalJumpLoading && <div className="loading"></div>}
                    {!group.groupVerticalJumpQuery && <div className="noquery"></div>}
                    {(group.groupWallAngelQuery && group.groupWallAngelError !== "" && !group.groupWallAngelLoading) && <div className="yeserror"></div>}
                    {(group.groupWallAngelQuery && group.groupWallAngelError === "" && !group.groupWallAngelLoading) && <div className="noerror"></div>}
                    {group.groupWallAngelLoading && <div className="loading"></div>}
                    {!group.groupWallAngelQuery && <div className="noquery"></div>}
                    {(group.workflowQuery && group.workflowError !== "" && !group.workflowLoading) && <div className="yeserror"></div>}
                    {(group.workflowQuery && group.workflowError === "" && !group.workflowLoading) && <div className="noerror"></div>}
                    {group.workflowLoading && <div className="loading"></div>}
                    {!group.workflowQuery && <div className="noquery"></div>}
                </div>
                <div className="bank">
                    {(correctives.equipmentQuery && correctives.equipmentError !== "" && !correctives.equipmentLoading) && <div className="yeserror"></div>}
                    {(correctives.equipmentQuery && correctives.equipmentError === ""  && !correctives.equipmentLoading)&& <div className="noerror"></div>}
                    {correctives.equipmentLoading && <div className="loading"></div>}
                    {!correctives.equipmentQuery && <div className="noquery"></div>}
                    {(correctives.equipmentTextQuery && correctives.equipmentTextError !== ""  && !correctives.equipmentTextLoading) && <div className="yeserror"></div>}
                    {(correctives.equipmentTextQuery && correctives.equipmentTextError === "" && !correctives.equipmentTextLoading) && <div className="noerror"></div>}
                    {correctives.equipmentTextLoading && <div className="loading"></div>}
                    {!correctives.equipmentTextQuery && <div className="noquery"></div>}
                    {(correctives.exerciseQuery && correctives.exerciseError !== "" && !correctives.exerciseLoading) && <div className="yeserror"></div>}
                    {(correctives.exerciseQuery && correctives.exerciseError === "" && !correctives.exerciseLoading) && <div className="noerror"></div>}
                    {correctives.exerciseLoading && <div className="loading"></div>}
                    {!correctives.exerciseQuery && <div className="noquery"></div>}
                    {(correctives.exerciseTextQuery && correctives.exerciseTextError !== "" && !correctives.exerciseTextLoading) && <div className="yeserror"></div>}
                    {(correctives.exerciseTextQuery && correctives.exerciseTextError === "" && !correctives.exerciseTextLoading) && <div className="noerror"></div>}
                    {correctives.exerciseTextLoading && <div className="loading"></div>}
                    {!correctives.exerciseTextQuery && <div className="noquery"></div>}
                    {(correctives.correctivesQuery && correctives.correctivesError !== "" && !correctives.correctivesLoading) && <div className="yeserror"></div>}
                    {(correctives.correctivesQuery && correctives.correctivesError === "" && !correctives.correctivesLoading) && <div className="noerror"></div>}
                    {correctives.correctivesLoading && <div className="loading"></div>}
                    {!correctives.correctivesQuery && <div className="noquery"></div>}
                    {(correctives.correctiveProtocolQuery && correctives.correctiveProtocolError !== "" && !correctives.correctiveProtocolLoading) && <div className="yeserror"></div>}
                    {(correctives.correctiveProtocolQuery && correctives.correctiveProtocolError === "" && !correctives.correctiveProtocolLoading) && <div className="noerror"></div>}
                    {correctives.correctiveProtocolLoading && <div className="loading"></div>}
                    {!correctives.correctiveProtocolQuery && <div className="noquery"></div>}
                    {(correctives.protocolQuery && correctives.protocolError !== "" && !correctives.protocolLoading) && <div className="yeserror"></div>}
                    {(correctives.protocolQuery && correctives.protocolError === "" && !correctives.protocolLoading) && <div className="noerror"></div>}
                    {correctives.protocolLoading && <div className="loading"></div>}
                    {!correctives.protocolQuery && <div className="noquery"></div>}
                    {(correctives.protocolSessionQuery && correctives.protocolSessionError !== "" && !correctives.protocolSessionLoading) && <div className="yeserror"></div>}
                    {(correctives.protocolSessionQuery && correctives.protocolSessionError === "" && !correctives.protocolSessionLoading) && <div className="noerror"></div>}
                    {correctives.protocolSessionLoading && <div className="loading"></div>}
                    {!correctives.protocolSessionQuery && <div className="noquery"></div>}
                </div>
                <div className="bank">
                    {(customFields.clinicCFQuery && customFields.clinicCFError !== "" && !customFields.clinicCFLoading) && <div className="yeserror"></div>}
                    {(customFields.clinicCFQuery && customFields.clinicCFError === "" && !customFields.clinicCFLoading) && <div className="noerror"></div>}
                    {customFields.clinicCFLoading && <div className="loading"></div>}
                    {!customFields.clinicCFQuery && <div className="noquery"></div>}
                    {(customFields.practitionerCFQuery && customFields.practitionerCFError !== "" && !customFields.practitionerCFLoading) && <div className="yeserror"></div>}
                    {(customFields.practitionerCFQuery && customFields.practitionerCFError === "" && !customFields.practitionerCFLoading) && <div className="noerror"></div>}
                    {customFields.practitionerCFLoading && <div className="loading"></div>}
                    {!customFields.practitionerCFQuery && <div className="noquery"></div>}
                    {(customFields.patientCFQuery && customFields.patientCFError !== "" && !customFields.patientCFLoading) && <div className="yeserror"></div>}
                    {(customFields.patientCFQuery && customFields.patientCFError === "" && !customFields.patientCFLoading) && <div className="noerror"></div>}
                    {customFields.patientCFLoading && <div className="loading"></div>}
                    {!customFields.patientCFQuery && <div className="noquery"></div>}
                    {(customFields.assessmentCFQuery && customFields.assessmentCFError !== "" && !customFields.assessmentCFLoading) && <div className="yeserror"></div>}
                    {(customFields.assessmentCFQuery && customFields.assessmentCFError === "" && !customFields.assessmentCFLoading) &&  <div className="noerror"></div>}
                    {customFields.assessmentCFLoading && <div className="loading"></div>}
                    {!customFields.assessmentCFQuery && <div className="noquery"></div>}
                    {(customFields.workflowCFQuery && customFields.workflowCFError !== "" && !customFields.workflowCFLoading) && <div className="yeserror"></div>}
                    {(customFields.workflowCFQuery && customFields.workflowCFError === "" && !customFields.workflowCFLoading) && <div className="noerror"></div>}
                    {customFields.workflowCFLoading && <div className="loading"></div>}
                    {!customFields.workflowCFQuery && <div className="noquery"></div>}
                    {(customFields.cfMetadataQuery && customFields.cfMetadataError !== "" && !customFields.cfMetadataLoading) && <div className="yeserror"></div>}
                    {(customFields.cfMetadataQuery && customFields.cfMetadataError === "" && !customFields.cfMetadataLoading) && <div className="noerror"></div>}
                    {customFields.cfMetadataLoading && <div className="loading"></div>}
                    {!customFields.cfMetadataQuery && <div className="noquery"></div>}
                </div>

            </div>
            </div>
        }
        </>
    )
}

export default ControllerFilter;