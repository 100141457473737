import React from 'react';
import { PropertiesColumns } from '../../datatablesource';
import { DataGrid } from '@mui/x-data-grid';
import { RotatingLines } from 'react-loader-spinner';
const PropertiesTable = ({ data, isLoading, error }) => {

    console.log("Properties Data: ", data);   
    console.log("Properties Error: ", error);
    return (
        <div className="datatable" style={{height:660}}>
            {!isLoading && error === "" &&
                <DataGrid
                    className="datagrid"
                    rows={data} 
                    columns={PropertiesColumns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    //checkboxSelection
                    density='compact'
                />
                }
                {isLoading && 
                    <center style={{paddingTop:100}}>
                        <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
                    </center>
                }
                {!isLoading && error !== "" && <div>{error}</div>}
            </div>
    )
}

export default PropertiesTable;