export const AssessmentGroupListData = {
    "assessmentGroup": [], 
    "groupBalance": [],
    "groupFunctional": [],
    "groupOverheadSquat": [],
    "groupReverseLunge": [],
    "groupRom": [],
    "groupSidePostural": [],
    "groupSingleLegHop": [],
    "groupVerticalJump": [],
    "groupWallAngel": [],
    "workflow": [],

    "assessmentGroupError": "",
    "groupBalanceError": "",
    "groupFunctionalError": "",
    "groupOverheadSquatError": "",
    "groupReverseLungeError": "",
    "groupRomError": "",
    "groupSidePosturalError": "",
    "groupSingleLegHopError": "",
    "groupVerticalJumpError": "",
    "groupWallAngelError": "",
    
    "assessmentGroupLoading": false,
    "groupBalanceLoading": false,
    "groupFunctionalLoading": false,
    "groupOverheadSquatLoading": false,
    "groupReverseLungeLoading": false,
    "groupRomLoading": false,
    "groupSidePosturalLoading": false,
    "groupSingleLegHopLoading": false,
    "groupVerticalJumpLoading": false,
    "groupWallAngelLoading": false,
    "workflowLoading": false,
}