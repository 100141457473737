import React from "react";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import RSTAssessmentTable from "../datatable/RSTAssessmentTable";
import { AssessmentGroupColumns, GroupAssColumns, WorkflowColumns } from "../../datatablesource";

const AssessmentGroupControllersTables = ({ data, serverURL, token }) => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("Assessment group Data: ", data);

    return (
    <Box style={{width:1250}}>
    <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Ass Group" value="1" />
                <Tab label="Bal" value="2" />
                <Tab label="Funct" value="3" />
                <Tab label="OHS" value="4" />
                <Tab label="Rev Lunge" value="5" />
                <Tab label="ROM" value="6" />
                {/* <Tab label="Side Post" value="7" /> */}
                <Tab label="SLH" value="8" />
                <Tab label="VJ" value="9" />
                <Tab label="WA" value="10" />
                <Tab label="Workflow" value="11" />
            </Tabs>
        </Box>
        <TabPanel value='1'>
            {data.assessmentGroupError === "" && <RSTAssessmentTable 
                rows ={(data.assessmentGroup || [])}
                isLoading={data.assessmentGroupLoading}
                columns ={AssessmentGroupColumns}
                serverURL={serverURL}
                token={token}
                type="Assessment Group"
                />
            }
            {data.assessmentGroupError !== "" && <div>{data.assessmentGroupError}</div>}
        </TabPanel> 
        <TabPanel value='2'>
            {data.groupBalanceError === "" && <RSTAssessmentTable 
                    rows ={(data.groupBalance || [])}
                    isLoading={data.groupBalanceLoading}
                    columns ={GroupAssColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Group Balance"
                />
            }
            {data.groupBalanceError !== "" && <div>{data.groupBalanceError}</div>}
        </TabPanel>
            <TabPanel value='3'>
                {data.groupFunctionalError === "" && <RSTAssessmentTable 
                    rows ={(data.groupFunctional || [])}
                    isLoading={data.groupFunctionalLoading}
                    columns ={GroupAssColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Group Functional"
                />
                }   
                {data.groupFunctionalError !== "" && <div>{data.groupFunctionalError}</div>}
        </TabPanel>
     
        <TabPanel value='4'>
        {data.groupOverheadSquatError === "" && <RSTAssessmentTable 
                rows ={(data.groupOverheadSquat || [])}
                isLoading={data.groupOverheadSquatLoading}
                columns ={GroupAssColumns}
                serverURL={serverURL}
                token={token}
                type="Group Overhead Squat"
            />
        }
            {data.groupOverheadSquatError !== "" && <div>{data.groupOverheadSquatError}</div>}
        </TabPanel> 
        <TabPanel value='5'>
            {data.groupReverseLungeError === "" && <RSTAssessmentTable 
                rows ={(data.groupReverseLunge || [])}
                isLoading={data.groupReverseLungeLoading}
                columns ={GroupAssColumns}
                serverURL={serverURL}
                token={token}
                type="Group Reverse Lunge"
            />
            }
            {data.groupReverseLungeError !== "" && <div>{data.groupReverseLungeError}</div>}
        </TabPanel> 
        <TabPanel value='6'>
            {data.groupRomError === "" && <RSTAssessmentTable 
                    rows ={(data.groupRom || [])}
                    isLoading={data.groupRomLoading}
                    columns ={GroupAssColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Group ROM"
                />
            }
            {data.groupRomError !== "" && <div>{data.groupRomError}</div>}
        </TabPanel> 
        {/* <TabPanel value='7'>
            {data.groupSidePosturalError === "" && <RSTAssessmentTable 
                rows ={(data.groupSidePostural || [])}
                isLoading={data.groupSidePosturalLoading}
                columns ={GroupAssColumns}
                serverURL={serverURL}
                token={token}
                type="Group Side Postural"
            />
            }
            {data.groupSidePosturalError !== "" && <div>{data.groupSidePosturalError}</div>}
        </TabPanel>  */}
        <TabPanel value='8'>
            {data.groupSingleLegHopError === "" && <RSTAssessmentTable 
                rows ={(data.groupSingleLegHop || [])}
                isLoading={data.groupSingleLegHopLoading}
                columns ={GroupAssColumns}
                serverURL={serverURL}
                token={token}
                type="Group Single Leg Hop"
            />
            }
            {data.groupSingleLegHopError !== "" && <div>{data.groupSingleLegHopError}</div>}
        </TabPanel> 
        <TabPanel value='9'>
            {data.groupVerticalJumpError === "" && <RSTAssessmentTable  
                rows ={(data.groupVerticalJump || [])}
                isLoading={data.groupVerticalJumpLoading}
                columns ={GroupAssColumns}
                serverURL={serverURL}
                token={token}
                type="Group Vertical Jump"
            />
            }
            {data.groupVerticalJumpError !== "" && <div>{data.groupVerticalJumpError}</div>}
        </TabPanel> 
        <TabPanel value='10'>
            {data.groupWallAngelError === "" && <RSTAssessmentTable  
                rows ={(data.groupWallAngel || [])}
                isLoading={data.groupWallAngelLoading}
                columns ={GroupAssColumns}
                serverURL={serverURL}
                token={token}
                type="Group Wall Angel"
                />
            }
            {data.groupWallAngelError !== "" && <div>{data.groupWallAngelError}</div>}
        </TabPanel> 
        <TabPanel value='11'>
            {data.workflowError === "" && <RSTAssessmentTable  
                rows ={(data.workflow || [])}
                isLoading={data.workflowLoading}
                columns ={WorkflowColumns}
                serverURL={serverURL}
                token={token}
                type="Workflow"
                />
            }
            {data.workflowError !== "" && <div>{data.workflowError}</div>}
        </TabPanel> 
        
    </TabContext>
</Box>
)
}

export default AssessmentGroupControllersTables;