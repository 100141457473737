import * as React from 'react';
import {useState} from 'react';
// import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SSOTypes } from '../../data/ControlledLists';
import  Button  from '@mui/material/Button';
import GoogleOAuthData from '../../data/GoogleOAuthData';
import AzureSAMLData from '../../data/AzureSAMLData';
import GoogleOAuthSSO from './SSO/GoogleOAuthSSO';
import AzureSAMLSSO from './SSO/AzureSAMLSSO';
import HL7OAuthSSO from './SSO/HL7OAuthSSO';
import HL7OAuthData from '../../data/HL7OAuthData';

 const  OrganizationSettings = ({data, onFieldChange, onSave}) => {    //  const [row, setRow] = useState(LicenceData);
    console.log("Data Organization Settings: ",data);

    const [sso, setSso] = useState(data.ssoProvider !== "" ? data.ssoProvider : "None");
    // const [googleSSO,setGoogleSSO] = useState(false);
    // const [samlSSO,setSamlSSO] = useState(false);
    // const [HL7SSO,setHL7SSO] = useState(false);
    const [row, setRow] = useState(data);
    const [errorMessage, setErrorMessage] = useState(false);
    const [savedMessage, setSavedMessage] = useState(false);
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState("");

    const handleFieldUpdate = (event, value, id) => {
        var blob = "";
        switch(sso) {
            case "GoogleOAuth":
                console.log("Google: ",id, value);
                var r = "";

                console.log("Handle field update Blob: ",blob);
                if (row.ssoProviderSettingsBlob === "" || row.ssoProviderSettingsBlob === null) {
                    blob = GoogleOAuthData;
                } else {
                    try{
                        blob = JSON.parse(row.ssoProviderSettingsBlob);
                    } catch (e) {
                        blob = GoogleOAuthData;
                    }
                }
                console.log("Blob: ",blob);
                // var g = "";
                switch(id) {
                    case "name":
                       
                        break;
                    case "clientId":
                            blob = {...blob, clientId: value };
                            r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                            setRow(r);
                            onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    case "clientSecret":
                            blob = {...blob, clientSecret: value };
                            r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                            setRow(r);
                            onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                  
                    case "authorizedOrigin1":
                       
                        break;
                    case "authorizedOrigin2":
                       
                        break;
                    case "redirect1":
                       
                        break;
                    case "redirect2":
                            blob = {...blob, redirect2: value };
                            r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                            setRow(r);
                            onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                        case "googleLoginEndpoint":
                            blob = {...blob, googleLoginEndpoint: value };
                            r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                            setRow(r);
                            onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                        case "tokenCallback":
                            blob = {...blob, tokenCallback: value };
                            r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                            setRow(r);
                            onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                        case "googleAPIEndpoint":
                            blob = {...blob, googleAPIEndpoint: value };
                            r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                            setRow(r);
                            onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    default:
                        console.log("Google Missing: ",id)
                        break;
                }   
                break;
            case "AzureSAML20":
                console.log("AzureSAML: ",id, value);
                if (row.ssoProviderSettingsBlob === "" || row.ssoProviderSettingsBlob === null) {
                    blob = AzureSAMLData;
                } else {
                    try{
                        blob = JSON.parse(row.ssoProviderSettingsBlob);
                    } catch (e) {
                        blob = AzureSAMLData;
                    }
                }
                switch(id) {
                    case "name":
                      
                        break;
                    case "enableUserCreation":
                      
                        break;
                    case "enableUserOverride":
                       
                        break;
                    case "basicConfigurationIdentifier":
                       
                        break;
                    case "basicConfigurationRedirect":
                        blob = {...blob, basicConfigurationRedirect: value };
                        r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                        setRow(r);
                        onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    case "microsoftEntraIdentifier":
                        blob = {...blob, microsoftEntraIdentifier: value };
                        r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                        setRow(r);
                        onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    case "loginUrl":
                        blob = {...blob, loginUrl: value };
                        r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                        setRow(r);
                        onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    case "logoutUrl":
                        blob = {...blob, logoutUrl: value };
                        r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                        setRow(r);
                        onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    case "publicCertificate":
                        blob = {...blob, publicCertificate: value };
                        r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                        setRow(r);
                        onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    case "advanceAttributeEmail":
                        blob = {...blob, advanceAttributeEmail: value };
                        r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                        setRow(r);
                        onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    case "advanceAttributeFirstName":
                        blob = {...blob, advanceAttributeFirstName: value };
                        r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                        setRow(r);
                        onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                    case "advanceAttributeLastName":
                        blob = {...blob, advanceAttributeLastName: value };
                        r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                        setRow(r);
                        onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                  
                    default:
                        console.log("Azure SAML Missing: ",id)
                        break;
                }
                break;
                case "HL7OAuth":
                    // console.log("HL7OAuth: ",id, value);
                    // console.log("Handle field update Blob: ",blob);
                    if (row.ssoProviderSettingsBlob === "" || row.ssoProviderSettingsBlob === null) {
                        blob = HL7OAuthData;
                    } else {
                        try{
                            blob = JSON.parse(row.ssoProviderSettingsBlob);
                        } catch (e) {
                            blob = HL7OAuthData;
                        }
                    }
                    //console.log("Blob: ",blob);
                    // var g = "";
                    switch(id) {
                        case "name":
                           
                            break;
                        case "clientId":
                                blob = {...blob, clientId: value };
                                r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                                setRow(r);
                                onFieldChange( blob, "ssoProviderSettingsBlob");
                            break;
                        case "clientSecret":
                                blob = {...blob, clientSecret: value };
                                r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                                setRow(r);
                                onFieldChange( blob, "ssoProviderSettingsBlob");
                            break;
                        case "state":
                            blob = {...blob, state: value };
                            r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                            setRow(r);
                            onFieldChange( blob, "ssoProviderSettingsBlob");
                        break;
                        case "authorizedOrigin1":
                            break;
                        case "authorizedOrigin2":
                            break;
                        case "redirect1":
                            break;
                        case "redirect2":
                                blob = {...blob, redirect2: value };
                                r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                                setRow(r);
                                onFieldChange( blob, "ssoProviderSettingsBlob");
                            break;
                        case "hl7FHIRServerURL":
                            blob = {...blob, hl7FHIRServerURL: value };
                            r = {...row, ssoProviderSettingsBlob: JSON.stringify(blob) };
                            setRow(r);
                            onFieldChange( blob, "ssoProviderSettingsBlob");
                            break;
                            
                        default:
                            console.log("HL7 OAuth Missing: ",id)
                            break;
                    }   
                    break;
            default:
                console.log("None: ",id, value);
                break;
        }
    }

    const showGoogleOAuthSSO = () => {
        console.log("row Google:", row);
        return (<div>
                    <GoogleOAuthSSO
                    data={(row.ssoProviderSettingsBlob !== "" && row.ssoProviderSettingsBlob !== null) ? JSON.parse(row.ssoProviderSettingsBlob) : GoogleOAuthData}  
                    onFieldChange={(event, value, id) => handleFieldUpdate(event, value, id)}
                    />
                </div>);
    }

    const showSamlSSO = () => {
        console.log("row SAML:", row);
        return (<div>
                    <AzureSAMLSSO
                    data={(row.ssoProviderSettingsBlob !== "" && row.ssoProviderSettingsBlob !== null) ? JSON.parse(row.ssoProviderSettingsBlob) : AzureSAMLData}
                    onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                    />
                </div>);
    }

    const showHL7OAuthSSO = () => {
        console.log("row HL7 OAuth:", row);
        return (<div>
                    <HL7OAuthSSO
                    data={(row.ssoProviderSettingsBlob !== "" && row.ssoProviderSettingsBlob !== null) ? JSON.parse(row.ssoProviderSettingsBlob) : GoogleOAuthData}
                    onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                />
                </div>);
    }

    const handleSSOChange = (event, value) => {
        console.log("SSO: ",value);
        setSso(value);
        var r = {...row};
        if (value === "GoogleOAuth") {
            r = {...row, ssoProvider: value, ssoProviderSettingsBlob: JSON.stringify(GoogleOAuthData) };
        } else if (value === "AzureSAML20") {
            r = {...row, ssoProvider: value, ssoProviderSettingsBlob: JSON.stringify(AzureSAMLData)};
        } else if (value === "HL7OAuth") {
            r = {...row, ssoProvider: value, ssoProviderSettingsBlob: JSON.stringify(HL7OAuthData)};
        } else {
            r = {...row, ssoProvider: value, ssoProviderSettingsBlob: ""};
        }
        
        setRow(r);
        onFieldChange(value, "ssoProvider");
    }

    const handleSave = (event) => {
        event.preventDefault();
        console.log("Save: ",row);
        try{
            onSave();
            setSavedMessage(true);
            setErrorMessage(false);
            setTimeout(() => {
                setSavedMessage(false);
            }, 5000);
        } catch (error) {
            console.log("Error: ",error);
            setErrorMessage(true);
            setSavedMessage(false);
            setTimeout(() => {
                setErrorMessage(false);
            }, 5000);
        }
    }

    if (data.ssoProvider !== undefined) {
    return (
        <div>
        <form onSubmit={handleSave}>
            <div className="formInput"  Style={{margin:"10px", width:"700"}}>
                <FormControl style={{width:500, marginTop:20}} className='TextField'>
                    <InputLabel >SSO Type</InputLabel>
                    <Select
                        id="ssoType"
                        value={sso}
                        label="SSO Type"
                        onChange={(event, value) => handleSSOChange(event, event.target.value)}
                    >
                        {SSOTypes.map(row => {return (<MenuItem value={row}>{row}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
                <box Style={{width:500}}>
                     {sso === "GoogleOAuth" && showGoogleOAuthSSO()}
                     {sso === "AzureSAML20" && showSamlSSO()}
                     {sso === "HL7OAuth" && showHL7OAuthSSO()}
                </box>
            <div className="formButtons" style={{width:800}} >
                    <div className="left">
                        {errorMessage && <div className="error">An error occurred</div>}
                        {savedMessage && <div className="message">Organization SSO Settings Saved</div>}
                        {warningFlag && <div className="warning">{message}</div>}
                    </div>
                    <div className="right">
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                            >
                            Save
                        </Button>
                    </div>
                </div>
                            </div>
        </form>
        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default OrganizationSettings;