//import "./licencelist.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DeleteClinic from "../../components/clinics/DeleteClinic";
const DeleteClinicPage = (props) => {
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <DeleteClinic  />
      </div>
    </div>
  )
}

export default DeleteClinicPage