import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid, GridCellParams, gridClasses } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import { UsageProgressReportColumns } from "../../datatablesource";
import Box from '@mui/material/Box';
import LicenceUsageDialog from "../dialogs/LicenceUsageDialog";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const CustomToolbar= () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const UsageProgressionReportDataTable = (props) => {

    const [rows, setRows] = useState([]);
    const [id, setId] = useState();
    const [isLoading, setLoading] = useState(true);
    const [licenceUsage, setLicenceUsage] = useState(false);
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    
    // console.log("ROWS: ", rows)
    // console.log("Id: ",id);
    // console.log("Search Flag: ", search)
    useEffect (() => {
          try { 
            fetch(loginServerURL + "/api/Clinic/GetClinicUsageForPastThreeMonths" , {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => response.json())
            .then ((data) => {
              setRows(data);
              setLoading(false);
              console.log("Data: ", data);
            }).catch ((error) => {
                console.log(error);
            });  
          } catch(err) {
            console.log(err);
          }
    },[]);

    const handleCloseLicenceUsageDialog = () => {
        setLicenceUsage(false);
    }
    const handleLicenceUsage = (id) => {
      setLicenceUsage(true);
      setId(id);
    }

    const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        width: 160,
        renderCell: (params) => {
          return (
            <div className="cellAction">
             
             <DashboardCustomizeOutlinedIcon style={{color:"gray", cursor: "pointer"}} onClick={() => handleLicenceUsage(params.row.subscriptionId)}>Dashboard</DashboardCustomizeOutlinedIcon>
            </div>
          );
        },
      },
    ];
  

  return (
    <>
     <LicenceUsageDialog id={id} source="clinic" handleClose = {handleCloseLicenceUsageDialog} open={licenceUsage} />      
     <Box
      sx={{
        height: 300,
        width: 1400,
        [`.${gridClasses.cell}.green`]: {
          backgroundColor: '#00ff0020',
          color: '#1a3e72',
        },
        [`.${gridClasses.cell}.yellow`]: {
          backgroundColor: '#ffff0020',
          color: '#1a3e72',
        },
        [`.${gridClasses.cell}.red`]: {
          backgroundColor: '#ff000020',
          color: '#1a3e72',
        },
      }}
    >
     
    
    { isLoading &&
      <center style={{paddingTop:100}}>
          <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
      </center>
    }
    {!isLoading &&
        <div className="datatable" style={{height:660}}>
          <div className="datatableTitle">
          Usage Progression Report
           
          </div>
            <DataGrid
                className="datagrid"
                rows={rows} 
                columns={UsageProgressReportColumns.concat(actionColumn)}
                pageSize={15}
                rowsPerPageOptions={[15]}
                getCellClassName={(params: GridCellParams<any, any, number>) => {
                  if ((params.field !== 'totalAssessments90' && params.field !=='totalAssessments60' && params.field !=='totalAssessments30') || params.value == null) {
                    return '';
                  }
                  if (params.value >= 50) {
                    return 'green';
                  }
                  if (params.value >= 20 && params.value < 50) {
                    return 'yellow';
                  }
                  return "red";
                }}
                //checkboxSelection
                density='compact'
                slots={{
              toolbar: CustomToolbar,
            }}
            />
        </div>
    }
    </Box>
    </>
  );
};




export default UsageProgressionReportDataTable;
