import "./mergeClinic.scss";
import React from 'react';
import { useState, useEffect } from 'react';
import LoginPanel from '../RegionalServerTesting/LoginPanel';
import {ClinicData} from '../../data/ClinicData';
import WarningYesNoDialog from '../dialogs/WarningYesNoDialog';
import InfoOkDialog from '../dialogs/InfoOkDialog';
import ErrorOkDialog from '../dialogs/ErrorOkDialog';
import Button from '@mui/material/Button';

const AnonymizeClinic = () => {


  const [displayLoginMessageSource, setDisplayLoginMessageSource] = useState(false);
  const [displayLoginErrorSource, setDisplayLoginErrorSource] = useState(false);
  const [displaySection3, setDisplaySection3] = useState(false);
  const [sourceClinicId, setSourceClinicId] = useState("");
  const [sourceClinic, setSourceClinic] = useState(ClinicData);
  const [serverURL, setServerURL] = useState("");
  const [token, setToken] = useState("");
  const [sourceUsername, setSourceUsername] = useState("");
  const [sourcePassword, setSourcePassword] = useState("");
  const [sourceServername, setSourceServername] = useState("");
  const [message, setMessage] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [displayInfo, setDisplayInfo] = useState(false);
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [displayReconfirm, setDisplayReconfirm] = useState(false);
  const [displayProgress, setDisplayProgress] = useState(false);


  //
  // Handle the login to the source clinic
  //
  const handleLoginSourceClinic = (serverURL, username, password) => {
    console.log("Server URL: ", serverURL);
    setSourceUsername(username);
    setSourcePassword(password);
    setSourceServername(serverURL);
    setDisplayLoginErrorSource(false);
    setDisplayLoginMessageSource(false);
    setServerURL(serverURL);
    const httpBody = { username: username, 
        password: password, 
        version: "6.0.0.1", 
        app: "Kinetisense", 
        platform: "windows"};
     try {
      fetch(serverURL+'/api/login', {
        method: 'POST',
        headers:{"Content-type":"application/json"},
        body: JSON.stringify(httpBody)
      }).then ((response) => {
                if(response.status>= 200 && response.status < 300){
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
                })
      .then ((data) => {
        console.log(data.mobileServiceAuthenticationToken);
        setToken(data.mobileServiceAuthenticationToken);
        setSourceClinicId(data.practitioner.clinicId);
        setDisplayLoginMessageSource(true);
        setDisplayLoginErrorSource(false);
        console.log("login successful:", data);
      }).catch ((error) => {
        console.log(error);
        setDisplayLoginErrorSource(true);
        setDisplayLoginMessageSource(false);
      });
    } catch (error) {
      console.log(error);
      setDisplayLoginErrorSource(true);
      setDisplayLoginMessageSource(false);
    }
  }

  //
  // Retrieve the source clinic data
  //
  useEffect (() => {
    try {
        console.log("Useeffect Source Clinic ID:", sourceClinicId);
        if (token.length > 0) {
                var fetchURL = serverURL+ `/tables/clinic/?id=${sourceClinicId}`;
                console.log("Fetch URL:", fetchURL);
            fetch(fetchURL, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": token,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
                if(response.status>= 200 && response.status < 300){
                    return response.json();
                } else {
                    // throw new Error(response.status);
                    console.log(`Error retrieving clinic:${sourceClinicId}:`,response.status);
                }
            }).then ((data) => {
                console.log("Clinic Data:", data[0]);
                setSourceClinic(data[0]);
            }).catch ((error) => {
                console.log(error);
            });  
        } else {
        }
    } catch (error) {
        console.log(error);
    }

  }, [sourceClinicId]);


  const handleWarningDialogNo = () => {
    setDisplayConfirm(false);
    setDisplayReconfirm(false);
  }

  const handleWarningDialogYes = () => {
    setDisplayConfirm(false);
    setDisplayReconfirm(true);
  }

  const handleWarningReconfirmDialogYes = () => {
    setDisplayReconfirm(false);
    anonymizeClinic();
  }
  const handleWarningReconfirmDialodNo = () => {
    setDisplayReconfirm(false);
  }

  const handleInfoDialogClose = () => {
    setDisplayInfo(false);
  }
  const handleErrorDialogClose = () => {
    setDisplayError(false);
  }

  const handleMergeClinicsConfirmMessage = () => {
   
    if (sourceClinicId.length === 0) {
        setMessage("Please login into a clinics.");
        setDisplayError(true);
        return;
    }
    setMessage(`This will ANONYMIZE all the practitioners, patients for clinic: ${sourceClinic.name}. There is NO undo after this procedure. Are you sure that you want to proceed?`);
    setDisplayConfirm(true);
}

  const handleAnonymizeClinicsReconfirmMessage = () => {
    setDisplayConfirm(false);
    
    if (sourceClinicId.length === 0 ) {
        setMessage("Please login into a clinic.");
        setDisplayError(true);
        return;
    }
    setMessage(`Last Chance! You are about to anonymize the content of clinic: ${sourceClinic.name}. There is NO UNDO after this procedure. Are you Really sure that you want to proceed with this?`);
    setDisplayReconfirm(true);
}

  //
  // Anonymize Clinic
  //
  const anonymizeClinic = () => {
    try {
      if (token.length > 0) {
              var fetchURL = "" ;//serverURL+ `/api/Clinic/AnonymizeClinic?id=${sourceClinicId}`;
              console.log("Fetch URL:", fetchURL);
          fetch(fetchURL, {
              method: 'POST',
              headers:{
                  "X-ZUMO-AUTH": token,
                  "Content-type":"application/json"
              }
          }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                  //return response.json();
                  setMessage("Clinic has been anonymized successfully.");
                  setDisplayInfo(true);
                  setDisplaySection3(true);
              } else {
                  // throw new Error(response.status);
                  console.log(`Error anonymize clinics:${sourceClinicId} Status:${response.status}`);
                  setMessage(`Error anonymize clinics:${sourceClinicId} Status:${response.status}`);
                  setDisplayError(true);
              }
          }).then ((data) => {
              // console.log("Clinic Data:", data[0]);
              // setSourceClinic(data[0]);
          }).catch ((error) => {
              console.log(error);
          });  
      } else {
          setMessage(`No Token, Error anonymizing clinics:${sourceClinicId}`);
          setDisplayError(true);
      }
    } catch (error) {
        console.log(error);
        setMessage(`Error anonymizing clinics:${sourceClinicId} Error:${error}`);
        setDisplayError(true);
    }
  }

    return (
      <>
      <ErrorOkDialog open={displayError} message={message} handleClose={handleErrorDialogClose} />
        <WarningYesNoDialog open={displayConfirm} message={message} handleNo={handleWarningDialogNo} handleYes={handleWarningDialogYes} />
        <WarningYesNoDialog open={displayReconfirm} message={message} handleNo={handleWarningReconfirmDialodNo} handleYes={handleWarningReconfirmDialogYes} />
        <InfoOkDialog open={displayInfo} message={message} handleClose={handleInfoDialogClose} />
        <div><h1> Anonymize a Clinic</h1></div>
        <div style={{border:1}}>
            <div style={{marginTop:15, marginLeft:10, marginRight:30}}>This tool allows you to ANONYMIZE a clinic. There is NO undo option after you have anonymized the clinic.</div>
            {/* <div style={{marginTop:15, marginLeft:10, marginRight:30}}>Make sure to add <b>Source</b> at the end of the source clinic name and <b>Target</b> at then end of the target clinic name. That will make things easier for the first step. You can correct the target clinic name after you have completed the merge successfully.</div> */}
        </div>
        <div className="mergeBox" style={{marginTop:15, marginLeft:10}}>
            <b style={{marginTop:10, marginBottom:15, marginLeft:20, marginRight:30}}>1. Login in the clinic</b>
            <div>
                {/* <h3 style={{marginLeft:15}}>From: {sourceClinic.name}</h3> */}
                <LoginPanel 
                    handleLogin={handleLoginSourceClinic}
                    displayLoginMessage={displayLoginMessageSource}
                    displayLoginError={displayLoginErrorSource}
                    // reset={reset}
                    isCollapsed={false}
                />
            </div>
           
        </div>
        <div>
            {sourceClinicId.length > 0 &&
                <div className="mergeBox" style={{marginTop:15, marginLeft:10}}>
                    <b style={{marginLeft:20, marginRight:30, marginTop:15}}>2. Click <b>ANONYMIZE CLINIC</b></b>
                    <div style={{marginTop:15, marginLeft:10, marginRight:30}}>This will remove the name, addresses, and phone numbers from the practitioners and patients for the current clinic. There is NO undo option after you have anonymized the clinic. This does not affect any of the local copies but could affect them after a sync.</div>
                    {!displaySection3 && <Button style={{marginLeft:20, height:30, marginTop:20, marginBottom:15, width:300}} className="button" variant="contained" onClick={handleMergeClinicsConfirmMessage}> ANONYMIZE CLINIC</Button>}
                    {displaySection3 && <Button style={{marginLeft:20, height:30, marginTop:20, marginBottom:15, width:300}} disabled className="button" variant="contained" onClick={handleMergeClinicsConfirmMessage}> ANONYMIZE CLINICS</Button>}
                </div>
            }
      </div>  
      <div>
        {displaySection3 && 
            <div className="mergeBox" style={{marginTop:15, marginLeft:10, marginBottom:15}}>
                <b style={{marginLeft:20, marginRight:30, marginTop:15}}>3. Clinic Anonymize done!</b>
                <div style={{marginLeft:20, marginRight:30, marginTop:15, marginBottom:15}}>You can use the Regional Server Test Tool to verify that you have anonymized the clinic. Hopefully, it was the right clinic that you did that on.</div>
            </div>
        }

      </div>
    </>
    )
  }
  export default AnonymizeClinic;