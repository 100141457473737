import * as React from 'react';
import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';


 const  AzureSAMLSSO = ({data, onFieldChange, organizations}) => {    //  const [row, setRow] = useState(LicenceData);

    console.log("------", organizations)
   
        console.log("AzureSAML data", data)
        if (data.name !== undefined) {
        return (
            <div style={{width:800}}>
                <div style={{borderStyle:"solid", borderWidth: 1, borderRadius: 5, padding:10, backgroundColor:"#eeeeee", borderColor:"#eeeeee"}}>
                    <div>Step 1: login to your Azure web portal, go to the <i>Enterprise Application</i>.</div>
                    <div>Step 2: Click <b>All Application</b> in the left menu.</div>
                    <div>Step 3: Click <b>+ New Application</b></div>
                    <div>Step 4: Click <b>+ Create your own application</b></div>
                    <div>Step 5: In <i>Create your own application</i>, use the Name for the App Name:</div>
                    <div>
                        <TextField
                            style={{marginTop: 20}}
                            fullWidth
                            id="name" 
                            label="Name" 
                            variant="outlined"
                            className="TextField"
                            value={data.name}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>Step 6: Select <b>Integrate any other application you don't find in the gallery (Non-gallery)</b> and click <b>Create</b></div>
                </div>
                <div style={{borderStyle:"solid", borderWidth: 1, borderRadius: 5, padding:10, marginTop:10, backgroundColor:"#eeeeee", borderColor:"#eeeeee"}}>
                    <div>Step 7: Click <b>Set up single sign on</b> </div>
                    <div>Step 8: Click <b>SAML</b> </div>
                    <div>Step 9: In <i>Basic SAML Configuration</i>, click <b>Edit</b> </div>
                    <div>Step 10: Under <i>Identifier (Entity ID)</i>, click <b>Add Identifier</b> and enter the following information </div>
                    <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="basicConfigurationIdentifier" 
                            label="Basic Configuration identifier" 
                            variant="outlined"
                            className="TextField"
                            value={data.basicConfigurationIdentifier}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                    </div>
                    <div>Step 11: Under <i>Reply URL (Assertion Consumer Service URL)</i>, click <b>Add Reply URL</b> and enter the following information </div>
                    <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="basicConfigurationRedirect" 
                            label="Basic Configuration Redirection" 
                            variant="outlined"
                            className="TextField"
                            value={data.basicConfigurationRedirect}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>Step 12: Under <i>Basic SAML Configuration</i> in the Azure web portal, click <b>Save</b></div>
                </div>
                <div style={{borderStyle:"solid", borderWidth: 1, borderRadius: 5, padding:10,marginTop:10, backgroundColor:"#eeeeee", borderColor:"#eeeeee"}}>
                    <div>Step 13: Under <i>Setup Kinetisense 360</i> section in the Azure web portal, copy the <i>Microsoft Entra Identifier</i> in the field below </div>
                    <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="microsoftEntraIdentifier" 
                            label="Microsoft Entra Identifier" 
                            variant="outlined"
                            className="TextField"
                            value={data.microsoftEntraIdentifier}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>Step 14: Under <i>Setup Kinetisense 360</i> section in the Azure web portal, copy <i>Login URL</i> in the field below </div>
                    <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="loginUrl" 
                            label="Login URL" 
                            variant="outlined"
                            className="TextField"
                            value={data.loginUrl}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>Step 15: Under <i>Setup Kinetisense 360</i> section in the Azure web portal, copy <i>Logout URL</i> in the field below </div>
                    <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="logoutUrl" 
                            label="Logout URL" 
                            variant="outlined"
                            className="TextField"
                            value={data.logoutUrl}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                
                    <div>Step 16: Under <i>SAML Certificates</i> section, download <b>Certificate (Base64)</b> and copy the content of the certificate in the following information </div>
                    <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="publicCertificate" 
                            label="Public Certificate"
                            multiline
                            rows={18} 
                            variant="outlined"
                            className="TextField"
                            inputProps={{style: {fontSize: 14}}}
                            value={data.publicCertificate}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>
                <div style={{borderStyle:"solid", borderWidth: 1, borderRadius: 5, padding:10,marginTop:10, backgroundColor:"#eeeeee", borderColor:"#eeeeee"}}>
                    <div>Step 17: Under <i>Attributes & Claims</i> section, click <b>Edit</b></div>
                    {/* <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="advanceAttributeEmail" 
                            label="Attribute Email" 
                            variant="outlined"
                            className="TextField"
                            value={data.advanceAttributeEmail}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div> */}
                    <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="advanceAttributeFirstName" 
                            label="Attribute First Name" 
                            variant="outlined"
                            className="TextField"
                            value={data.advanceAttributeFirstName}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            style={{marginTop: 20}}
                            id="advanceAttributeLastName" 
                            label="Attribute Last Name" 
                            variant="outlined"
                            className="TextField"
                            value={data.advanceAttributeLastName}
                            onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>
                <div style={{marginTop:10}}>Step 18: Click <b>Save</b> (below) to save the information in your SSO information in Kinetisense.</div>
            </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default AzureSAMLSSO;