import "./Dialog.scss";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropertiesTable from "../datatable/PropertiesTable";

const ViewPropertiesDialog = ({open, windowTitle, handleClose, data, error}) => {
    return ( <Dialog 
        PaperProps={{
          sx: {
            minHeight: 700
          }
        }}
          className="editdialog" 
          open={open} 
          fullWidth={true}
          maxWidth={"md"}
          >
          <DialogTitle className="dialogtitle">
            <div className="left">
            {windowTitle}
            </div>
              <IconButton onClick={handleClose} className="right">
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent  className="dialogcontent">
            <PropertiesTable 
            data ={data}
            error={error}
            />
          </DialogContent>
        </Dialog>
    )

}

export default ViewPropertiesDialog;