import React from "react";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import RSTAssessmentTable from "../datatable/RSTAssessmentTable";
import { RSTCorrectiveColumns, RSTCorrectiveProtocolColumns, RSTEquipmentColumns, RSTEquipmentTextColumns, RSTExerciseColumns, RSTExerciseTextColumns, RSTProtocolColumns, RSTProtocolSessionColumns } from "../../datatablesource";

const ProtocolControllersTables = ({ data, serverURL, token }) => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("Protocol Data: ", data);

    return (
            <Box style={{width:1250}}>
             <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Equip" value="1" />
                        <Tab label="Equip Text" value="2" />
                        <Tab label="Exer" value="3" />
                        <Tab label="Exer Text" value="4" />
                        <Tab label="Correctives" value="5" />
                        <Tab label="Correctives Protocol" value="6" />
                        <Tab label="Protocol" value="7" />
                        <Tab label="Protocol Session" value="8" />
                    </Tabs>
                </Box>
                <TabPanel value='1'>
                    {data.equipmentError === "" && <RSTAssessmentTable 
                            rows ={(data.equipmentList || [])}
                            isLoading={data.equipmentLoading}
                            columns ={RSTEquipmentColumns}
                            serverURL={serverURL}
                            token={token}
                            type="Equipment"
                        />
                    }
                    {data.equipmentError !== "" && <div>{data.equipmentError}</div>}
                </TabPanel>
                    <TabPanel value='2'>
                        {data.equipmentTextError === "" && <RSTAssessmentTable 
                            rows ={(data.equipmentTextList || [])}
                            isLoading={data.equipmentTextLoading}
                            columns ={RSTEquipmentTextColumns}
                            serverURL={serverURL}
                            token={token}
                            type="Equipment Text"
                        />
                        }   
                        {data.equipmentTextError !== "" && <div>{data.equipmentTextError}</div>}
                </TabPanel>
                
                <TabPanel value='3'>
                {data.exerciseError === "" && <RSTAssessmentTable 
                        rows ={(data.exerciseList || [])}
                        isLoading={data.exerciseLoading}
                        columns ={RSTExerciseColumns}
                        serverURL={serverURL}
                        token={token}
                        type="Exercise"
                    />
                }
                    {data.exerciseError !== "" && <div>{data.exerciseError}</div>}
                </TabPanel> 
                <TabPanel value='4'>
                    {data.exerciseTextError === "" && <RSTAssessmentTable 
                        rows ={(data.exerciseTextList || [])}
                        isLoading={data.exerciseTextLoading}
                        columns ={RSTExerciseTextColumns}
                        serverURL={serverURL}
                        token={token}
                        type="Exercise Text"
                    />
                    }
                    {data.exerciseTextError !== "" && <div>{data.exerciseTextError}</div>}
                </TabPanel> 
                <TabPanel value='5'>
                    {data.correctivesError === "" && <RSTAssessmentTable 
                            rows ={(data.correctivesList || [])}
                            isLoading={data.correctivesLoading}
                            columns ={RSTCorrectiveColumns}
                            serverURL={serverURL}
                            token={token}
                            type="Corrective"
                        />
                    }
                    {data.correctivesError !== "" && <div>{data.correctivesError}</div>}
                </TabPanel> 
                <TabPanel value='6'>
                    {data.correctiveProtocolError === "" && <RSTAssessmentTable 
                        rows ={(data.correctiveProtocolList || [])}
                        isLoading={data.correctiveProtocolLoading}
                        columns ={RSTCorrectiveProtocolColumns}
                        serverURL={serverURL}
                        token={token}
                        type="Corrective Protocol"
                    />
                    }
                    {data.correctiveProtocolError !== "" && <div>{data.correctiveProtocolError}</div>}
                </TabPanel> 
                <TabPanel value='7'>
                    {data.protocolError === "" && <RSTAssessmentTable 
                        rows ={(data.protocolList || [])}
                        isLoading={data.protocolLoading}
                        columns ={RSTProtocolColumns}
                        serverURL={serverURL}
                        token={token}
                        type="Protocol"
                    />
                    }
                    {data.protocolError !== "" && <div>{data.protocolError}</div>}
                </TabPanel> 
                <TabPanel value='8'>
                    {data.protocolSessionError === "" && <RSTAssessmentTable  
                        rows ={(data.protocolSessionList || [])}
                        isLoading={data.protocolSessionLoading}
                        columns ={RSTProtocolSessionColumns}
                        serverURL={serverURL}
                        token={token}
                        type="Protocol Session"
                    />
                    }
                    {data.protocolSessionError !== "" && <div>{data.protocolSessionError}</div>}
                </TabPanel> 
            
            </TabContext>
        </Box>
    )
}

export default ProtocolControllersTables;