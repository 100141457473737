import './ODataFilter.scss';
import React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';

const ODataFilter = ({handleFilterUpdate, isCollapsed}) => {

    const [filter, setFilter] = useState("(UpdatedAt ge datetimeoffset'1970-01-01T00:00:00.00000-00:00')");
    const [top, setTop] = useState(1000);
    const [skip, setSkip] = useState(0);
    const [orderby, setOrderby] = useState("UpdatedAt");
    const [count, setCount] = useState(0);
    const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)

    // console.log("OData Filter: ", filter);
    // console.log("OData Top: ", top);
    // console.log("OData Skip: ", skip);
    // console.log("OData Orderby: ", orderby);

    const handleOnClick = () => {
        console.log("OData Filter Click");
        handleFilterUpdate(filter,top,skip,orderby,count)
    }

    const handleUpdate = (value, field) => {
        console.log("Field: ", field);
        console.log("Value: ", value);
        switch (field) {
            case 'filter':
                setFilter(value);
                break;
            case 'top':
                var t = value
                if (!isNumeric(t)) {
                    t = 1000;
                }
                if (t > 1000) {
                    setTop(1000);
                } else {
                    setTop(t);
                }
                break;
            case 'skip':
                var v = value;
                if (!isNumeric(v)) {
                    v = 0;
                }

                if (v > 1000000) { 
                    setSkip(1000000);
                } else {
                    setSkip(v);
                }
                break;
            case 'orderby':
                setOrderby(value);
                break;
            case 'count':
                setCount(value);
                break;
            default:
                break
        }
    }

return (
    <>
        {!isCollapsed && <div className='odataFilterBox'>
             {/* <form onClick={handleOnClick} className="odataFilterBox"> */}
                <div className="item">
                    <label>OData Filter</label>
                    <input style={{width:500}} type="text" id="filter" name="filter" value={filter} placeholder="Filter" onChange={(e) => handleUpdate(e.target.value, 'filter')}/>
                </div>
                <div className="item">
                    <label>Skip</label>
                    <input  style={{width:90}} type="number" id="skip" name="skip" min="0" max="1000000" value={skip}  onChange={(e) => handleUpdate(e.target.value, 'skip')} />
                </div>
            
                <div className="item">
                    <label>Top</label>
                    <input  style={{width:70}} type="number" id="top" name="top" min="0" max="1000" value={top} onChange={(e) => handleUpdate(e.target.value, 'top')}/>
                </div>
                <div className="item">
                    <label>Order By</label>
                    <input style={{width:100}} type="text" id="orderby" value={orderby} name="orderby" placeholder="Order By" onChange={(e) => handleUpdate(e.target.value, 'orderby')}/>
                </div>
                {/* <div className="item">
                    <label classname="label">Count</label>
                    <input className="input" type="text" id="count" name="count" placeholder="Count" onChange={(e) => handleUpdate(e.target.value, 'count')}/>
                </div> */}
                <div className="item">
                    <button style={{height:25, width:70, marginTop:22}} onClick={handleOnClick}>SEARCH</button>
                </div>
            {/* </form> */}
            </div>
    }
    </>

)
}

export default ODataFilter;