import React from "react";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import RSTAssessmentTable from "../datatable/RSTAssessmentTable";
import { AssessmentColumns, AssessmentFramesColumns, PermissionColumns } from "../../datatablesource";

const AssessmentControllersTables = ({ data, serverURL, token }) => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("Assessment Data: ", data);

    return (
    <Box style={{width:1250}}>
    <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Bal" value="1" />
                <Tab label="Funct" value="2" />
                <Tab label="OHS" value="4" />
                <Tab label="Rev Lunge" value="5" />
                <Tab label="ROM" value="6" />
                {/* <Tab label="Side Post" value="7" /> */}
                <Tab label="SLH" value="8" />
                <Tab label="VJ" value="9" />
                <Tab label="WA" value="10" />
                <Tab label="Perm" value="11" />
                <Tab label="Frame" value="12" />
            </Tabs>
        </Box>
        <TabPanel value='1'>
            {data.balanceError === "" && <RSTAssessmentTable 
                    rows ={(data.balance || [])}
                    isLoading={data.balanceLoading}
                    columns ={AssessmentColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Balance"
                />
            }
            {data.balanceError !== "" && <div>{data.balanceError}</div>}
        </TabPanel>
            <TabPanel value='2'>
                {data.functionalError === "" && <RSTAssessmentTable 
                    rows ={(data.functional || [])}
                    isLoading={data.functionalLoading}
                    columns ={AssessmentColumns}
                    serverURL={serverURL}
                    token={token}
                    type="Functional"
                />
                }   
                {data.functionalError !== "" && <div>{data.functionalError}</div>}
        </TabPanel>
        
        <TabPanel value='4'>
        {data.overheadSquatError === "" && <RSTAssessmentTable 
                rows ={(data.overheadSquat || [])}
                isLoading={data.overheadSquatLoading}
                columns ={AssessmentColumns}
                serverURL={serverURL}
                token={token}
                type="Overhead Squat"
            />
        }
            {data.overheadSquatError !== "" && <div>{data.overheadSquatError}</div>}
        </TabPanel> 
        <TabPanel value='5'>
            {data.reverseLungeError === "" && <RSTAssessmentTable 
                rows ={(data.reverseLunge || [])}
                isLoading={data.reverseLungeLoading}
                columns ={AssessmentColumns}
                serverURL={serverURL}
                token={token}
                type="Reverse Lunge"
            />
            }
            {data.reverseLungeError !== "" && <div>{data.reverseLungeError}</div>}
        </TabPanel> 
        <TabPanel value='6'>
            {data.romError === "" && <RSTAssessmentTable 
                    rows ={(data.rom || [])}
                    isLoading={data.romLoading}
                    columns ={AssessmentColumns}
                    serverURL={serverURL}
                    token={token}
                    type="ROM"
                />
            }
            {data.romError !== "" && <div>{data.romError}</div>}
        </TabPanel> 
        {/* <TabPanel value='7'>
            {data.sidePosturalError === "" && <RSTAssessmentTable 
                rows ={(data.sidePostural || [])}
                isLoading={data.sidePosturalLoading}
                columns ={AssessmentColumns}
                serverURL={serverURL}
                token={token}
                type="Side Postural"
            />
            }
            {data.sidePosturalError !== "" && <div>{data.sidePosturalError}</div>}
        </TabPanel>  */}
        <TabPanel value='8'>
            {data.singleLegHopError === "" && <RSTAssessmentTable 
                rows ={(data.singleLegHop || [])}
                isLoading={data.singleLegHopLoading}
                columns ={AssessmentColumns}
                serverURL={serverURL}
                token={token}
                type="Single Leg Hop"
            />
            }
            {data.singleLegHopError !== "" && <div>{data.singleLegHopError}</div>}
        </TabPanel> 
        <TabPanel value='9'>
            {data.verticalJumpError === "" && <RSTAssessmentTable  
                rows ={(data.verticalJump || [])}
                isLoading={data.verticalJumpLoading}
                columns ={AssessmentColumns}
                serverURL={serverURL}
                token={token}
                type="Vertical Jump"
            />
            }
            {data.verticalJumpError !== "" && <div>{data.verticalJumpError}</div>}
        </TabPanel> 
        <TabPanel value='10'>
            {data.wallAngelError === "" && <RSTAssessmentTable  
                rows ={(data.wallAngel || [])}
                isLoading={data.wallAngelLoading}
                columns ={AssessmentColumns}
                serverURL={serverURL}
                token={token}
                type="Wall Angel"
                />
            }
            {data.wallAngelError !== "" && <div>{data.wallAngelError}</div>}
        </TabPanel> 
        <TabPanel value='11'>
            {data.permissionError === "" && <RSTAssessmentTable 
                rows ={(data.permission || [])}
                isLoading={data.permissionLoading}
                columns ={PermissionColumns}
                serverURL={serverURL}
                token={token}
                type="Permission"
                />
            }
            {data.permissionError !== "" && <div>{data.permissionError}</div>}
        </TabPanel> 
        <TabPanel value='12'>
            {data.assessmentFrameError === "" && <RSTAssessmentTable 
                rows ={(data.assessmentFrame || [])}
                isLoading={data.assessmentFrameLoading}
                columns ={AssessmentFramesColumns}
                serverURL={serverURL}
                token={token}
                type="Assessment Frame"
                />
            }
            {data.assessmentFrameError !== "" && <div>{data.assessmentFrameError}</div>}
        </TabPanel> 
    </TabContext>
</Box>
)
}

export default AssessmentControllersTables;