import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
//import { CustomFieldsMetaDataColumns } from "../../datatablesource";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'; 
import DeleteCustomFieldDialog from "../dialogs/DeleteCustomFieldDialog";
import {assessmentGroups, modules} from "../../data/ControlledLists";
import {AssessmentGroups} from "../../data/ControlledLists";


const hideColumn = (name, array) => {
//replace element hidden in array for a specific name
  var array1 = array.forEach((el) => {
    if (el.field === name) {
      el.hideable = true;
    }
  });
  console.log("array1: ", array1);
  return array1;
}


const CustomFieldsDataTable = ({customFields, onValueChange, flag, hideAssessmentColumn, hideAssessmentGroupColumn, cfnames}) => {

    const [rows, setRows] = useState(customFields);
    const [row, setRow] = useState();
    const [del, setDelete] = useState(false);
    const [warning, setWarning] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
      assessmentType: false,
      workflowType: false
    });
    const [assessmentVisibilityModel, setAssessmentVisibilityModel] = useState({
      assessmentType: true,
      workflowType: false
    });
    const [assessmentGroupVisibilityModel, setAssessmentGroupVisibilityModel] = useState({
      assessmentType: false,
      workflowType: true
    });

    const modulesList = () => {
      return ["All",...modules];
    }
    const workflowList = () => {
      return ["All",...assessmentGroups];
    }
    //const apiRef = useGridApiRef();

    const CustomFieldsMetaDataColumns = [
      //{ field: "id", headerName: "ID", minWidth: 70, hideable: false, editable: false},
      { field: "type", 
        headerName: "Type", 
        minWidth: 100, 
        type:'singleSelect', 
        valueOptions: ['ControlledList','Text', 'Notes', 'Decimal', 'Integer', 'Boolean', 'Date'],
        editable: true
        // valueGetter: getCFMetadataListText 
      },
      { field: "order", headerName: "Order", minWidth: 70, type:'number' ,editable: true},
      { field: "groupName", headerName: "Group Name", type:'string', minWidth: 180 ,editable: true},
      { field: "assessmentType", 
        headerName: "Assessment Type", 
        type:'singleSelect', 
        minWidth: 150 ,
        valueOptions: modulesList(),
        editable: true
      },
      { field: "workflowType", 
        headerName: "Workflow Type", 
        type:'singleSelect', 
        minWidth: 150 ,
        valueOptions: workflowList(),
        editable: true
      },
      { field: "name", headerName: "Field Name", type:'string', minWidth: 150, editable: true },
      { field: "label", headerName: "Label", type:'string', minWidth: 200, editable: true },
      { field: "toolTip", headerName: "ToolTip", minWidth: 200, type:'string', editable: true },
      { field: "min", headerName: "Min", type:'number', minWidth: 70, editable: true },
      { field: "max", headerName: "Max", type:'number', minWidth: 70,editable: true },
      { field: "nbOfLines", headerName: "NbLines", type:'number', minWidth: 80, editable: true },
      { field: "options", headerName: "OptionList", type:'string', minWidth: 350,editable: true },
      { field: "isValidated", headerName: "Validate", minWidth: 100, type:'boolean',editable: true },
      { field: "isMandatory", headerName: "Mandatory", minWidth: 100, type:'boolean',editable: true },
      { field: "isMultiSelect", headerName: "MultiSelect", type:'boolean', minWidth: 100,editable: true },
      { field: "dateFormat", headerName: "Date Format", minWidth: 120, type:'singleSelect', valueOptions: ['MM/dd/yyyy', 'yyyy/MM/dd','dd/MM/yyyy'],editable: true },
      { field: "isConditional", headerName: "Cond Field", type:'boolean', minWidth: 100,editable: true },
      { field: "conditionalField", 
        headerName: "Cond Fieldname", 
        type:'singleSelect',
        valueOptions: cfnames,
        minWidth: 300,
        editable: true 
      },
      { field: "condition", 
        headerName: "Condition", 
        minWidth: 100, 
        type:'singleSelect', 
        valueOptions: [">", ">=", "<", "<=", "=", "!=", "Contains", "!Contains"],
        editable: true
      },
      { field: "conditionalValue", headerName: "Cond Value", type:'string', minWidth: 250,editable: true },
      { field: "conditionalStatus", 
        headerName: "Status", 
        minWidth: 100, 
        type:'singleSelect', 
        valueOptions: ["Disabled","Hidden"],
        editable: true
      },
    ]

    
    const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDelete(params.row)} />
          </div>
        );
      },
    },
  ];

  const GetFieldNames = () => {
    return cfnames;
  }

 const handleRowUpdate = (n) => {
    let i =0;
    let j = 0;
    setWarning(false);
    rows.forEach((el) => {
        if (el.id === n.id) {
            j=i;
        }
        i++;
    })
    const newList = [...rows];
      newList[j]={...newList[j], type:n.type};
      newList[j]={...newList[j], order:n.order};
      newList[j]={...newList[j], groupName:n.groupName};
      newList[j]={...newList[j], assessmentType:n.assessmentType};
      newList[j]={...newList[j], workflowType:n.workflowType};
      newList[j]={...newList[j], name:n.name};
      newList[j]={...newList[j], label:n.label};
      newList[j]={...newList[j], toolTip:n.toolTip};
      newList[j]={...newList[j], min:n.min};
      newList[j]={...newList[j], max:n.max};
      newList[j]={...newList[j], isValidated:n.isValidated};
      newList[j]={...newList[j], isMandatory:n.isMandatory};
      newList[j]={...newList[j], nbOfLines:n.nbOfLines};
      newList[j]={...newList[j], options:n.options};
      newList[j]={...newList[j], isMultiSelect:n.isMultiSelect};
      newList[j]={...newList[j], dateFormat:n.dateFormat};
      newList[j]={...newList[j], isConditional:n.isConditional};
      newList[j]={...newList[j], conditionalField:n.conditionalField};
      newList[j]={...newList[j], condition:n.condition};
      newList[j]={...newList[j], conditionalValue:n.conditionalValue};
      newList[j]={...newList[j], conditionalStatus:n.conditionalStatus};
    setRows(newList.sort((a, b) => (a.order > b.order) ? 1 : -1));
    onValueChange(newList.sort((a, b) => (a.order > b.order) ? 1 : -1));
    return n; // need this return to stop error message about missing id
 }



  const handleAddRows = (r) =>{

    try{
        let m=0;
        let mid=0;
        try{
        const maxid = rows.reduce((prev, current) => (prev.id > current.id) ? prev : current)
        const max = rows.reduce((prev, current) => (prev.order > current.order) ? prev : current)
        m = parseInt(max.order);
        mid = parseInt(maxid.id);
        }catch(e){
          console.log("Error: ", e);
        }
        const newList = [].concat((rows || []),{
          "id": mid + 1,
            "type": "Text",
            "order": m + 1,
            "groupName": "",
            "assessmentType": "All",
            "workflowType": "All",
            "name": "",
            "label": "",
            "toolTip": "",
            "min": 0,
            "max": 100,
            "isValidated": false,
            "isMandatory": false,
            "nbOfLines": 1,
            "options": "",
            "isMultiSelect": false,
            "dateFormat": "MM/dd/yyyy",
            "isConditional": false,
            "conditionalField": "",
            "condition": "=",
            "conditionalValue": "",
            "conditionalStatus": "Disabled"
          });
        setRows(newList);
        onValueChange(newList);
      }
      catch (e){
        console.log("Error: ",e);
        console.log("Rows: ", rows);
      }
  }

  const handleDelete = (row) => {
    setDelete(true);
    setRow(row)
  }


  const handleRemoveFromRows = (row) =>{
    setRows(rows.filter((r) => r.id !== row.id));
    onValueChange(rows.filter((r) => r.id !== row.id));
  }

  const handleCloseDeleteDialog = () => {
    setDelete(false);
  }

  const handleMessage = (message) => {
    console.log("handleMessage: ", message);
  }


  return (
    <>
      
      <DeleteCustomFieldDialog row={row} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
     
      <div className="datatable" style={{height:400, width:1000}}>
        <div className="datatableTitle">
              Custom Fields
                  <AddCircleOutlineOutlinedIcon onClick={handleAddRows} style={{color:"green", cursor:"pointer"}}>
                  New Custom Field
                  </AddCircleOutlineOutlinedIcon>
        </div>
        {(hideAssessmentColumn && hideAssessmentGroupColumn) && 
          <DataGrid
              className="datagrid"
              rows={rows}
              getRowId={(row) => row.id}
              columns={CustomFieldsMetaDataColumns.concat(actionColumn)}
              pageSize={8}
              editMode="row"
              rowsPerPageOptions={[8]}
              density='compact'
              onRowEditCommit={(params, event) => handleMessage("onRowCommit")}
              onRowModesModelChange={(params, event) => handleMessage("RowModel")}
              processRowUpdate={(n) => handleRowUpdate(n)}
              onProcessRowUpdateError={(error) => handleMessage(error)}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
          />
        }
        {(!hideAssessmentColumn && hideAssessmentGroupColumn) &&
          <DataGrid
              className="datagrid"
              rows={rows}
              getRowId={(row) => row.id}
              columns={CustomFieldsMetaDataColumns.concat(actionColumn)}
              pageSize={8}
              editMode="row"
              rowsPerPageOptions={[8]}
              density='compact'
              onRowEditCommit={(params, event) => handleMessage("onRowCommit")}
              onRowModesModelChange={(params, event) => handleMessage("RowModel")}
              processRowUpdate={(n) => handleRowUpdate(n)}
              onProcessRowUpdateError={(error) => console.log("RowUpdateError: ", error)}
              columnVisibilityModel={assessmentVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setAssessmentVisibilityModel(newModel)
              }
          />
        }
        {(hideAssessmentColumn && !hideAssessmentGroupColumn) &&
          <DataGrid
              className="datagrid"
              rows={rows}
              getRowId={(row) => row.id}
              columns={CustomFieldsMetaDataColumns.concat(actionColumn)}
              pageSize={8}
              editMode="row"
              rowsPerPageOptions={[8]}
              density='compact'
              onRowEditCommit={(params, event) => handleMessage("onRowCommit")}
              onRowModesModelChange={(params, event) => handleMessage("RowModel")}
              processRowUpdate={(n) => handleRowUpdate(n)}
              onProcessRowUpdateError={(error) => console.log("RowUpdateError: ", error)}
              columnVisibilityModel={assessmentGroupVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setAssessmentGroupVisibilityModel(newModel)
              }
          />
        }
      </div>
      
    </>
  );
};




export default CustomFieldsDataTable;
