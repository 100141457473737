import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId="106696802976-h4j3qu1n37quvr86kqp8i2fliapl3bn5.apps.googleusercontent.com">
          <DarkModeContextProvider>
              <AuthContextProvider>
                  <App />
              </AuthContextProvider>
          </DarkModeContextProvider>
      </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
