export const AssessmentListData = {
    "balance": [],
    "functional": [],
    "modifiedVeles": [],
    "overheadSquat": [],
    "reverseLunge": [],
    "rom": [],
    "sidePostural": [],
    "singleLegHop": [],
    "verticalJump": [],
    "wallAngel": [],
    "permission": [],
    "assessmentFrame": [],
    "balanceError": "",
    "functionalError": "",
    "modifiedVelesError": "",
    "overheadSquatError": "",
    "reverseLungeError": "",
    "romError": "",
    "sidePosturalError": "",
    "singleLegHopError": "",
    "verticalJumpError": "",
    "wallAngelError": "",
    "permissionError": "",
    "assessmentFrameError": "",
    "balanceLoading": false,
    "functionalLoading": false,
    "modifiedVelesLoading": false,
    "overheadSquatLoading": false,
    "reverseLungeLoading": false,
    "romLoading": false,
    "sidePosturalLoading": false,
    "singleLegHopLoading": false,
    "verticalJumpLoading": false,
    "wallAngelLoading": false,
    "permissionLoading": false,
    "assessmentFrameLoading": false,
}