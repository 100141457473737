import "./mergeClinic.scss";
import React from 'react';
import { useState, useEffect } from 'react';
import LoginPanel from '../RegionalServerTesting/LoginPanel';
import {ClinicData} from '../../data/ClinicData';
import WarningYesNoDialog from '../dialogs/WarningYesNoDialog';
import InfoOkDialog from '../dialogs/InfoOkDialog';
import ErrorOkDialog from '../dialogs/ErrorOkDialog';
import Button from '@mui/material/Button';
const MergeClinics = () => {

    const [displayLoginMessageSource, setDisplayLoginMessageSource] = useState(false);
    const [displayLoginErrorSource, setDisplayLoginErrorSource] = useState(false);
    const [displayLoginMessageTarget, setDisplayLoginMessageTarget] = useState(false);
    const [displayLoginErrorTarget, setDisplayLoginErrorTarget] = useState(false);
    const [displaySection3, setDisplaySection3] = useState(false);
    const [sourceClinicId, setSourceClinicId] = useState("");
    const [targetClinicId, setTargetClinicId] = useState("");
    const [sourceClinic, setSourceClinic] = useState(ClinicData);
    const [targetClinic, setTargetClinic] = useState(ClinicData);
    const [serverURL, setServerURL] = useState("");
    const [token, setToken] = useState("");
    const [tokenTarget, setTokenTarget] = useState("");
    const [sourceUsername, setSourceUsername] = useState("");
    const [sourcePassword, setSourcePassword] = useState("");
    const [targetUsername, setTargetUsername] = useState("");
    const [targetPassword, setTargetPassword] = useState("");
    const [sourceServername, setSourceServername] = useState("");
    const [targetServername, setTargetServername] = useState("");
    const [message, setMessage] = useState("");
    const [displayError, setDisplayError] = useState(false);
    const [displayWarning, setDisplayWarning] = useState(false);
    const [displayInfo, setDisplayInfo] = useState(false);
    const [reset, setReset] = useState(false);
    const [displayConfirm, setDisplayConfirm] = useState(false);
    const [displayReconfirm, setDisplayReconfirm] = useState(false);
    const [displayProgress, setDisplayProgress] = useState(false);

    // console.log("Source ClinicID:", sourceClinicId);
    // console.log("Target ClinicID:", targetClinicId);
    // console.log("Source Clinic:", sourceClinic);
    // console.log("Target Clinic:", targetClinic);
    // const handleReset = () => {
    //     setDisplayLoginMessageSource(false);
    //     setDisplayLoginErrorSource(false);
    //     setDisplayLoginMessageTarget(false);
    //     setDisplayLoginErrorTarget(false);
    //     setSourceClinic("");
    //     setTargetClinic("");
    //     setSourceClinicId("");
    //     setTargetClinicId("");
    //     setToken("");
    //     setTokenTarget("");
    //     setSourceUsername("");
    //     setSourcePassword("");
    //     setTargetUsername("");
    //     setTargetPassword("");
    //     setSourceServername("");
    //     setTargetServername("");
    // }

    
    const handleLoginSourceClinic = (serverURL, username, password) => {
        console.log("Server URL: ", serverURL);
        setSourceUsername(username);
        setSourcePassword(password);
        setSourceServername(serverURL);
        setDisplayLoginErrorSource(false);
        setDisplayLoginMessageSource(false);
        setServerURL(serverURL);
        const httpBody = { username: username, 
            password: password, 
            version: "6.0.0.1", 
            app: "Kinetisense", 
            platform: "windows"};
         try {
          fetch(serverURL+'/api/login', {
            method: 'POST',
            headers:{"Content-type":"application/json"},
            body: JSON.stringify(httpBody)
          }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                    })
          .then ((data) => {
            console.log(data.mobileServiceAuthenticationToken);
            setToken(data.mobileServiceAuthenticationToken);
            setSourceClinicId(data.practitioner.clinicId);
            setDisplayLoginMessageSource(true);
            setDisplayLoginErrorSource(false);
            console.log("login successful:", data);
          }).catch ((error) => {
            console.log(error);
            setDisplayLoginErrorSource(true);
            setDisplayLoginMessageSource(false);
          });
        } catch (error) {
          console.log(error);
          setDisplayLoginErrorSource(true);
          setDisplayLoginMessageSource(false);
        }
    }

    //
    // login to the target clinic
    //
    const handleLoginTargetClinic = (serverURL, username, password) => {
        console.log("Server URL: ", serverURL);
        setTargetUsername(username);
        setTargetPassword(password);
        setTargetServername(serverURL);
        setDisplayLoginErrorTarget(false);
        setDisplayLoginMessageTarget(false);
        console.log("Source Servername:", sourceServername);
        console.log("Target Servername:", serverURL);
        if (serverURL !== sourceServername) {
            setDisplayLoginErrorTarget(true);
            setDisplayLoginMessageTarget(false);
            setMessage("Source and Target clinics must be on the same server.");
            setDisplayError(true);
            setReset(true);
            return;
        }
        setServerURL(serverURL);
        const httpBody = { username: username, 
            password: password, 
            version: "6.0.0.1", 
            app: "Kinetisense", 
            platform: "windows"};
         try {
          fetch(serverURL+'/api/login', {
            method: 'POST',
            headers:{"Content-type":"application/json"},
            body: JSON.stringify(httpBody)
          }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                    })
          .then ((data) => {
            console.log(data.mobileServiceAuthenticationToken);
            setTokenTarget(data.mobileServiceAuthenticationToken);
            setTargetClinicId(data.practitioner.clinicId);
            setDisplayLoginMessageTarget(true);
            setDisplayLoginErrorTarget(false);
            console.log("login successful:", data);
          }).catch ((error) => {
            console.log(error);
            setDisplayLoginErrorTarget(true);
            setDisplayLoginMessageTarget(false);
          });
        } catch (error) {
          console.log(error);
          setDisplayLoginErrorTarget(true);
          setDisplayLoginMessageTarget(false);
        }
    }

    //
    // retrieve the source clinic data
    //
    useEffect (() => {
        try {
            console.log("Useeffect Source Clinic ID:", sourceClinicId);
            if (token.length > 0) {
                    var fetchURL = serverURL+ `/tables/clinic/?id=${sourceClinicId}`;
                    console.log("Fetch URL:", fetchURL);
                fetch(fetchURL, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        // throw new Error(response.status);
                        console.log(`Error retrieving clinic:${sourceClinicId}:`,response.status);
                    }
                }).then ((data) => {
                    console.log("Clinic Data:", data[0]);
                    setSourceClinic(data[0]);
                }).catch ((error) => {
                    console.log(error);
                });  
            } else {
            }
        } catch (error) {
            console.log(error);
        }
      
    }, [sourceClinicId]);


    //
    // retrieve the target clinic data
    //
    useEffect (() => {
        try {
            if (token.length > 0) {
                    var fetchURL = serverURL+ `/tables/clinic/?id=${targetClinicId}`;
                    console.log("Fetch URL:", fetchURL);
                fetch(fetchURL, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": tokenTarget,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        // throw new Error(response.status);
                        console.log(`Error retrieving clinic:${sourceClinicId}:`,response.status);
                    }
                }).then ((data) => {
                    console.log("Clinic Data:", data[0]);
                    setTargetClinic(data[0]);
                }).catch ((error) => {
                    console.log(error);
                });  
            } else {
            }
        } catch (error) {
            console.log(error);
        }
      
    }, [targetClinicId]);
   
    //
    // call the server endpoint to merge the two clinics
    //
  
    const handleWarningDialogNo = () => {
        setDisplayConfirm(false);
        setDisplayReconfirm(false);
    }
    
    const HandleWarningDialogYes = () => {
        setDisplayReconfirm(false);
        mergeClinics();
    }

    const handleInfoDialogClose = () => {
        setDisplayInfo(false);
    }
    const handleErrorDialogClose = () => {
        setDisplayError(false);
        setReset(false);
    }

    //
    // retrieve the source clinic data
    //
    const mergeClinics = () => {
        try {
            if (token.length > 0) {
                    var fetchURL = serverURL+ `/api/Clinic/MergeClinics?sourceId=${sourceClinicId}&targetId=${targetClinicId}`;
                    console.log("Fetch URL:", fetchURL);
                fetch(fetchURL, {
                    method: 'POST',
                    headers:{
                        "X-ZUMO-AUTH": token,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        //return response.json();
                        setMessage("Clinics have been merged successfully.");
                        setDisplayInfo(true);
                        setDisplaySection3(true);
                    } else {
                        // throw new Error(response.status);
                        console.log(`Error merging clinics:${sourceClinicId},${targetClinicId} Status:${response.status}`);
                        setMessage(`Error merging clinics:${sourceClinicId},${targetClinicId} Status:${response.status}`);
                        setDisplayError(true);
                    }
                }).then ((data) => {
                    // console.log("Clinic Data:", data[0]);
                    // setSourceClinic(data[0]);
                }).catch ((error) => {
                    console.log(error);
                });  
            } else {
                setMessage(`No Token, Error merging clinics:${sourceClinicId}, ${targetClinicId}`);
                setDisplayError(true);
            }
        } catch (error) {
            console.log(error);
            setMessage(`Error merging clinics:${sourceClinicId},${targetClinicId} Error:${error}`);
            setDisplayError(true);
        }
    };


    const handleMergeClinicsConfirmMessage = () => {
        if (sourceClinicId === targetClinicId) {
            setMessage("Source and Target clinics must be different.");
            setDisplayError(true);
            return;
        }
        if (sourceClinicId.length === 0 || targetClinicId.length === 0) {
            setMessage("Please login to both source and target  clinics.");
            setDisplayError(true);
            return;
        }
        setMessage(`This will transfer all the practitioners, patients and their assessments, and workflows from clinic: ${sourceClinic.name} to clinic: ${targetClinic.name}. There is NO undo after the merge. Are you sure that you want to proceed?`);
        setDisplayConfirm(true);
    }

    const handleMergeClinicsReconfirmMessage = () => {
        setDisplayConfirm(false);
        if (sourceClinicId === targetClinicId) {
            setMessage("Source and Target clinics must be different.");
            setDisplayError(true);
            return;
        }
        if (sourceClinicId.length === 0 || targetClinicId.length === 0) {
            setMessage("Please login to both source and target  clinics.");
            setDisplayError(true);
            return;
        }
        setMessage(`Last Chance! You are about to merge the content of clinic: ${sourceClinic.name} to clinic: ${targetClinic.name}. There is NO UNDO after the merge. Are you Really sure that you want to proceed with this?`);
        setDisplayReconfirm(true);
    }


  return (
    <>
        <ErrorOkDialog open={displayError} message={message} handleClose={handleErrorDialogClose} />
        <WarningYesNoDialog open={displayConfirm} message={message} handleNo={handleWarningDialogNo} handleYes={handleMergeClinicsReconfirmMessage} />
        <WarningYesNoDialog open={displayReconfirm} message={message} handleNo={handleWarningDialogNo} handleYes={HandleWarningDialogYes} />
        <InfoOkDialog open={displayInfo} message={message} handleClose={handleInfoDialogClose} />
        <div><h1> Merge Two Clinics</h1></div>
        <div style={{border:1}}>
            <div style={{marginTop:15, marginLeft:10, marginRight:30}}>This tool allows you to merge a clinic into another one. This option does not merge a clinic from one server to a different sever. The two clinics MUST BE on the same server. There is NO undo option after you have merged the clinics.</div>
            <div style={{marginTop:15, marginLeft:10, marginRight:30}}>Make sure to add <b>Source</b> at the end of the source clinic name and <b>Target</b> at then end of the target clinic name. That will make things easier for the first step. You can correct the target clinic name after you have completed the merge successfully.</div>
        </div>
        <div className="mergeBox" style={{marginTop:15, marginLeft:10}}>
            <b style={{marginTop:10, marginBottom:15, marginLeft:20, marginRight:30}}>1. Login in the source clinic (From clinic) and the target clinic (To clinic)</b>
            <div>
                <h3 style={{marginLeft:15}}>From: {sourceClinic.name}</h3>
                <LoginPanel 
                    handleLogin={handleLoginSourceClinic}
                    displayLoginMessage={displayLoginMessageSource}
                    displayLoginError={displayLoginErrorSource}
                    reset={reset}
                    isCollapsed={false}
                />
            </div>
            <div>
                <h3 style={{marginTop:20, marginLeft:15}}>To: {targetClinic.name}</h3>
                <LoginPanel 
                    handleLogin={handleLoginTargetClinic}
                    displayLoginMessage={displayLoginMessageTarget}
                    displayLoginError={displayLoginErrorTarget}
                    reset={reset}
                    isCollapsed={false}
                />
            </div>
        </div>
        <div>
            {sourceClinicId.length > 0 && targetClinicId.length > 0 &&
                <div className="mergeBox" style={{marginTop:15, marginLeft:10}}>
                    <b style={{marginLeft:20, marginRight:30, marginTop:15}}>2. Click <b>MERGE CLINICS</b></b>
                    <div style={{marginTop:15, marginLeft:10, marginRight:30}}>This will move all the workflows, practitioners, patients and their related information from the source clinic to the target clinic. There is NO undo option after you have merged the clinics. The users will not be able to login the source clinic after the merge since all the information will be in the target clinic.</div>
                    {!displaySection3 && <Button style={{marginLeft:20, height:30, marginTop:20, marginBottom:15, width:300}} className="button" variant="contained" onClick={handleMergeClinicsConfirmMessage}> MERGE CLINICS</Button>}
                    {displaySection3 && <Button style={{marginLeft:20, height:30, marginTop:20, marginBottom:15, width:300}} disabled className="button" variant="contained" onClick={handleMergeClinicsConfirmMessage}> MERGE CLINICS</Button>}
                </div>
            }
      </div>  
      <div>
        {displaySection3 && 
            <div className="mergeBox" style={{marginTop:15, marginLeft:10, marginBottom:15}}>
                <b style={{marginLeft:20, marginRight:30, marginTop:15}}>3. Update Clinic Information in the Practitioner App</b>
                <div style={{marginLeft:20, marginRight:30, marginTop:15, marginBottom:15}}>To see the new information, you will need to uninstall the Practitioner App and reinstall it (iOS) or delete the sandbox (Windows) to do a full sync. The system only moved the practitioners, the assessmentFrames, the workflows, and the protocols to the target clinic. The rest of the information is related to these objects. This process does not update all the UpdatedAt for all the information. The only way to sync is to clear the sandbox and rebuild it. After, the system will sync as before.</div>
            </div>
        }

      </div>
    </>
  )
}
export default MergeClinics;