import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { DataGrid } from "@mui/x-data-grid";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ViewPropertiesDialog from "../dialogs/ViewPropertiesDialog";
import { createArrayFromObject } from "../../Utilities/Utilities";


const RSTAssessmentTable = ({ rows, isLoading, columns, serverURL, token, type }) => {

    const [view, setView] = useState(false);
    const [id, setId] = useState("");
    const [row, setRow] = useState({});
    const [error, setError] = useState("");
    console.log("properties type: ", type);
    console.log("properties rows: ", rows);


    const getRowById = (id) => {
        var url="";
        switch(type) {
            case "Balance":
                url = `${serverURL}/tables/balanceassessment/${id}`;
                break;
            case "Functional":
                url = `${serverURL}/tables/functionalassessment/${id}`;
                break;
            case "Overhead Squat":
                url = `${serverURL}/tables/overheadsquatassessment/${id}`;
                break;
            case "Reverse Lunge":
                url = `${serverURL}/tables/reverselungeassessment/${id}`;
                break;
            case "ROM":
                url = `${serverURL}/tables/romassessment/${id}`;
                break;
            case "Side Postural":
                url = `${serverURL}/tables/sideposturalassessment/${id}`;
                break;
            case "Single Leg Hop":
                url = `${serverURL}/tables/singleleghopassessment/${id}`;
                break;
            case "Vertical Jump":
                url = `${serverURL}/tables/verticaljumpassessment/${id}`;
                break;
            case "Wall Angel":
                url = `${serverURL}/tables/WallAngelAssessment/${id}`;
                break;
            case "Assessment Frame":
                url = `${serverURL}/tables/assessmentFrame/${id}`;
                break;
            case "Assessment Group":
                url = `${serverURL}/tables/assessmentsGroup/${id}`;
                break;
            case "Group Balance":
                url = `${serverURL}/tables/GroupBalance/${id}`;
                break;
            case "Group Functional":
                url = `${serverURL}/tables/GroupFunctional/${id}`;
                break;
            case "Group Overhead Squat":
                url = `${serverURL}/tables/GroupOverheadsquat/${id}`;
                break;
            case "Group Reverse Lunge":
                url = `${serverURL}/tables/GroupReverseLunge/${id}`;
                break;
            case "Group ROM":
                url = `${serverURL}/tables/GroupROM/${id}`;
                break;
            case "Group Side Postural":
                url = `${serverURL}/tables/GroupSidePostural/${id}`;
                break;
            case "Group Single Leg Hop":
                url = `${serverURL}/tables/GroupSingleLegHop/${id}`;
                break;
            case "Group Vertical Jump":
                url = `${serverURL}/tables/GroupVerticalJump/${id}`;
                break;
            case "Group Wall Angel":
                url = `${serverURL}/tables/GroupWallAngel/${id}`;
                break;
            case "Address":
                url = `${serverURL}/tables/address/${id}`;
                break;
            case "Phone Number":
                url = `${serverURL}/tables/phone/${id}`;
                break;
            case "Subscription":
                url = `${serverURL}/tables/subscription/${id}`;
                break;
            case "UploadFile":
                url = `${serverURL}/tables/UploadFile/${id}`;
                break;
            case "Permission":
                url = `${serverURL}/tables/permissions/${id}`;
                break;
            case "Clinic":
                url = `${serverURL}/tables/clinic/${id}`;
                break;
            case "Practitioner":
                url = `${serverURL}/tables/practitioner/${id}`;
                break;
            case "Patient":
                url = `${serverURL}/tables/patient/${id}`;
                break;
            case "Equipment":
                url = `${serverURL}/tables/equipment/${id}`;
                break;
            case "Equipment Text":
                url = `${serverURL}/tables/EquipmentText/${id}`;
                break;
            case "Exercise":
                url = `${serverURL}/tables/exercise/${id}`;
                break;
            case "Exercise Text":
                url = `${serverURL}/tables/exerciseText/${id}`;
                break;
            case "Corrective":
                url = `${serverURL}/tables/corrective/${id}`;
                break;
            case "Corrective Protocol":
                url = `${serverURL}/tables/correctiveProtocol/${id}`;
                break;
            case "Protocol":
                url = `${serverURL}/tables/protocol/${id}`;
                break;
            case "Protocol Session":
                url = `${serverURL}/tables/protocolSession/${id}`;
                break;
            case "Workflow":
                url = `${serverURL}/tables/workflow/${id}`;
                break;
            case "Clinic CF":
                url = `${serverURL}/tables/clinicCustomField/${id}`;
                break;
            case "Practitioner CF":
                url = `${serverURL}/tables/PractitionerCustomField/${id}`;
                break;
            case "Patient CF":
                url = `${serverURL}/tables/PatientCustomField/${id}`;
                break;
            case "Assessment CF":
                url = `${serverURL}/tables/AssessmentCustomField/${id}`;
                break;
            case "Workflow CF":
                url = `${serverURL}/tables/AssessmentsGroupCustomField/${id}`;
                break;
            case "Organization":
                url = `${serverURL}/tables/Organization/${id}`;
                break;
            case "CF Metadata":
                url = `${serverURL}/tables/CustomFieldsMetaData/${id}`;
                break;
            default:
                break;
        }

       // Get ID from the controller
       console.log("property fetch:",url);
        try {
            fetch(url, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": token,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
                if(response.status>= 200 && response.status < 300){
                    return response.json();
                } else {
                    setError(response.status);
                }
                })
            .then ((data) => {
                setRow(data.queryable[0]);
                setError("");
                console.log("properties Data:, data")

            }).catch ((error) => {
                console.log(error);
                setError(error.message)
            });  
        } catch (error) {
            console.log(error);
            setError(error.message);
        }
          
    }

    const handleView = (row) => {
        setId(row.id);
        setView(true);
        getRowById(row.id);
    }

    const handleCloseViewDialog = () => {
        setView(false);
    }

   

    const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          width: 100,
           headerClassName: 'columnHeading',
          renderCell: (params) => {
    
            return (
                // {params !== null &&
              <div className="cellAction">
                {!params.row.isDeleted && <RemoveRedEyeOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handleView(params.row)} />}
              </div>
            // }
            );
          },
        },
      ];


    return (<>
       
        <ViewPropertiesDialog handleClose = {handleCloseViewDialog} open={view} data={createArrayFromObject(row)} windowTitle={type} error={error} />
        
      { (isLoading || rows === null) &&
        <center style={{paddingTop:100}}>
            <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
        </center>
      }
      { (!isLoading && rows != null) &&
          <div className="datatable" style={{height:540}}>
           {!isLoading &&
              <DataGrid
                  className="datagrid"
                  rows={rows} 
                  columns={columns.concat(actionColumn)}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  density='compact'
              />
           }
            {isLoading &&
            <center style={{paddingTop:100}}>
            <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
          </center>}
          </div>
      }
      </>

    )
}

export default RSTAssessmentTable;