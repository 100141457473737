import "./chart.scss";
import React, { useState, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";




const COLOURS = ['#ff6900', '#fcb900', '#78dcb5', '#00d084', '#8ed1fc', '#abb8c3', '#eb144c', '#f78da7', '#9900ef', '#2196f3', 'teal', '#964B00','#1976D2'];  
export default function LineChartLicG({series}) {
  const [opacity, setOpacity] = useState({
    Bal:     2,
    CliGait: 2,
    FXSTS:   2,
    Funct:   2,
    ROFGait: 2,
    HRaise:  2,
    KAMS:    2,
    MCtSib:  2,
    OHSQ:    2,
    Posture: 2,
    RevL:    2,
    ROM:     2,
    SLH:     2,
    STS:     2,
    TUAG:    2,
    VertJ:   2,
    PAngel:  2,
    WChair:  2,
    KAMSIL:  2,
    KAMSExp: 2,
    KAMSGolf: 2,
    KAMSGolfExp: 2,
    BridPos: 2,
    GlfPos: 2,
    GlfStPlvcRot: 2,
    GlfSwAn: 2,
    PrPlkPos: 2,
    SdPlkPos: 2

  });
  const [lineSize, setLineSize] = useState({
    Bal:     1,
    CliGait: 1,
    FXSTS:   1,
    Funct:   1,
    ROFGait: 1,
    HRaise:  1,
    KAMS:    1,
    MCtSib:  1,
    OHSQ:    1,
    Posture: 1,
    RevL:    1,
    ROM:     1,
    SLH:     1,
    STS:     1,
    TUAG:    1,
    VertJ:   1,
    PAngel:  1,
    WChair:  1,
    KAMSIL:  1,
    KAMSExp: 1,
    KAMSGolf: 1,
    KAMSGolfExp: 1,
    BridPos: 1,
    GlfPos: 1,
    GlfStPlvcRot: 1,
    GlfSwAn: 1,
    PrPlkPos: 1,
    SdPlkPos: 1
  });

  console.log("LineGraphG Data:",series);

  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;
console.log("dataKey: ",dataKey);
      //setOpacity({ ...opacity, [dataKey]: 1 });
      setLineSize({ ...lineSize, [dataKey]: 4 });
    },
    [opacity, setOpacity, lineSize, setLineSize]
  );

  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o;
      //setOpacity({ ...opacity, [dataKey]: 1 });
        setLineSize({ ...lineSize, [dataKey]: 1 });
    },
    [opacity, setOpacity, lineSize, setLineSize]
  );

  return (
    <div>
      <LineChart
      className="linechart"
        width={1000}
        height={300}
        data={series}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip contentStyle={{color: "darkgray",columns: 2, fontSize:"11px" }} />
        <Legend
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          
        />

        <Line
          type="monotone"
          dataKey="Bal"
          strokeOpacity={opacity.Bal}
          stroke={COLOURS[0]}
          activeDot={{ r: 8 }}
          strokeWidth={lineSize.Bal}
        />
         <Line
          type="monotone"
          dataKey="BridPos"
          strokeOpacity={opacity.BridPos}
          stroke={COLOURS[1]}
          activeDot={{ r: 8 }}
          strokeWidth={lineSize.BridPos}
        />
        <Line
          type="monotone"
          dataKey="CliGait"
          strokeOpacity={opacity.CliGait}
          stroke={COLOURS[2]}
          activeDot={{ r: 8 }}
          strokeWidth={lineSize.CliGait}
        />
          <Line
            type="monotone"
            dataKey="Funct"
            strokeOpacity={opacity.Funct}
            stroke={COLOURS[3]}
            strokeWidth={lineSize.Funct}
            activeDot={{ r: 8 }}
          />
        <Line
          type="monotone"
          dataKey="FXSTS"
          strokeOpacity={opacity.FXSTS}
          stroke={COLOURS[4]}
          activeDot={{ r: 8 }}
          strokeWidth={lineSize.FXSTS}
        />
         <Line
          type="monotone"
          dataKey="GlfPos"
          strokeOpacity={opacity.GlfPos}
          stroke={COLOURS[5]}
          activeDot={{ r: 8 }}
          strokeWidth={lineSize.GlfPos}
        />
         <Line
          type="monotone"
          dataKey="GlfStPlvcRot"
          strokeOpacity={opacity.GlfStPlvcRot}
          stroke={COLOURS[6]}
          activeDot={{ r: 8 }}
          strokeWidth={lineSize.GlfStPlvcRot}
        />
         <Line
          type="monotone"
          dataKey="GlfSwAn"
          strokeOpacity={opacity.GlfSwAn}
          stroke={COLOURS[7]}
          activeDot={{ r: 8 }}
          strokeWidth={lineSize.GlfSwAn}
        />
       
        <Line
          type="monotone"
          dataKey="HRaise"
          strokeOpacity={opacity.HRaise}
          stroke={COLOURS[8]}
          strokeWidth={lineSize.HRaise}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="KAMS"
          strokeOpacity={opacity.KAMS}
          stroke={COLOURS[9]}
          strokeWidth={lineSize.KAMS}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="KAMSIL"
          strokeOpacity={opacity.KAMSIL}
          stroke={COLOURS[10]}
          strokeWidth={lineSize.KAMSIL}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="KAMSExp"
          strokeOpacity={opacity.KAMSExp}
          stroke={COLOURS[11]}
          strokeWidth={lineSize.KAMSExp}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="KAMSGolf"
          strokeOpacity={opacity.KAMSGolf}
          stroke={COLOURS[12]}
          strokeWidth={lineSize.KAMSGolf}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="KAMSGolfExp"
          legendType="plainline"
          strokeOpacity={opacity.KAMSGolfExp}
          stroke={COLOURS[0]}
          strokeWidth={lineSize.KAMSGolfExp}
          strokeDasharray="3 3"
        />
         <Line
          type="monotone"
          dataKey="MCtSib"
          legendType="plainline"
          strokeOpacity={opacity.MCtSib}
          stroke={COLOURS[1]}
          strokeWidth={lineSize.MCtSib}
          strokeDasharray="3 3"
        />
        <Line
          type="monotone"
          dataKey="OHSQ"
          legendType="plainline"
          strokeOpacity={opacity.OHSQ}
          stroke={COLOURS[2]}
          strokeWidth={lineSize.OHSQ}
          strokeDasharray="3 3"
        />
         <Line
          type="monotone"
          dataKey="PAngel"
          legendType="plainline"
          strokeOpacity={opacity.PAngel}
          stroke={COLOURS[3]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.PAngel}
        />
        <Line
          type="monotone"
          dataKey="Posture"
          legendType="plainline"
          strokeOpacity={opacity.Posture}
          stroke={COLOURS[4]}
          strokeWidth={lineSize.Posture}
          strokeDasharray="3 3"
        />
        <Line
          type="monotone"
          dataKey="PrPlkPos"
          legendType="plainline"
          strokeOpacity={opacity.PrPlkPos}
          stroke={COLOURS[5]}
          strokeWidth={lineSize.PrPlkPos}
          strokeDasharray="3 3"
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="RevL"
          strokeOpacity={opacity.RevL}
          stroke={COLOURS[6]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.RevL}
        />
         <Line
          type="monotone"
          dataKey="ROF"
          legendType="plainline"
          strokeOpacity={opacity.ROF}
          stroke={COLOURS[7]}
          strokeWidth={lineSize.ROF}
          strokeDasharray="3 3"
        />
         <Line
          type="monotone"
          dataKey="ROFGait"
          legendType="plainline"
          strokeOpacity={opacity.ROFGait}
          stroke={COLOURS[8]}
          strokeWidth={lineSize.ROFGait}
          strokeDasharray="3 3"
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="ROM"
          strokeOpacity={opacity.ROM}
          stroke={COLOURS[9]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.ROM}
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="SdPlkPos"
          strokeOpacity={opacity.SdPlkPos}
          stroke={COLOURS[10]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.SdPlkPos}
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="SLH"
          strokeOpacity={opacity.SLH}
          stroke={COLOURS[11]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.SLH}
        />
         <Line
          type="monotone"
          legendType="plainline"
          dataKey="STS"
          strokeOpacity={opacity.STS}
          stroke={COLOURS[12]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.STS}
        />
         <Line
          type="monotone"
          legendType="circle"
          dataKey="TUAG"
          strokeOpacity={opacity.TUAG}
          stroke={COLOURS[0]}
          strokeDasharray="2 2"
          strokeWidth={lineSize.TUAG}
        />
        <Line
          type="monotone"
          legendType="circle"
          dataKey="VertJ"
          strokeOpacity={opacity.VertJ}
          stroke={COLOURS[1]}
          strokeDasharray="2 2"
          strokeWidth={lineSize.VertJ}
        />
       
        <Line
          type="monotone"
          legendType="circle"
          dataKey="WChair"
          strokeOpacity={opacity.WChair}
          stroke={COLOURS[2]}
          strokeDasharray="2 2"
          strokeWidth={lineSize.WChair}
        />

      </LineChart>
      
    </div>
  );
}
