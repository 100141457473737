import React from "react";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import {AssessmentCustomFieldColumns, ClinicCustomFieldColumns, PatientCustomFieldColumns, PractitionerCustomFieldColumns, WorkflowCustomFieldColumns, CustomFieldsMetaDataListColumns } from "../../datatablesource";
import RSTAssessmentTable from "../datatable/RSTAssessmentTable";

const CustomFieldControllersTables = ({ data, serverURL, token }) => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("Custom Field Controller Data: ", data);

    return (
    <Box style={{width:1250}}>
    <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Clinic" value="1" />
                <Tab label="Practitioner" value="2" />
                <Tab label="Patient" value="3" />
                <Tab label="Assessment" value="4" />
                <Tab label="Workflow" value="5" />
                <Tab label="Metadata" value="6" />
            </Tabs>
        </Box>
        <TabPanel value='1'>
        {data.clinicCFError === "" && <RSTAssessmentTable 
                rows ={(data.clinicCFList || [])}
                isLoading={data.clinicCFLoading}
                columns ={ClinicCustomFieldColumns}
                serverURL={serverURL}
                token={token}
                type="Clinic CF"
            />
        }
        {data.clinicCFError !== "" && <div>{data.clinicCFError}</div>}
        </TabPanel>
        <TabPanel value='2'>
            {data.practitionerCFError === "" && <RSTAssessmentTable 
                rows ={(data.practitionerCFList || [])}
                isLoading={data.practitionerCFLoading}
                columns ={PractitionerCustomFieldColumns}
                serverURL={serverURL}
                token={token}
                type="Practitioner CF"
                />
            }
            {data.practitionerCFError !== "" && <div>{data.practitionerCFError}</div>}
        </TabPanel>
        <TabPanel value='3'>
        {data.patientCFError === "" && <RSTAssessmentTable 
                rows ={(data.patientCFList || [])}
                isLoading={data.patientCFLoading}
                columns ={PatientCustomFieldColumns}
                serverURL={serverURL}
                token={token}
                type="Patient CF"
                />
        }
        {data.patientCFError !== "" && <div>{data.patientCFError}</div>}
        </TabPanel> 
        <TabPanel value='4'>
        {data.assessmentCFError === "" && <RSTAssessmentTable 
                rows ={(data.assessmentCFList || [])}
                isLoading={data.assessmentCFLoading}
                columns ={AssessmentCustomFieldColumns}
                serverURL={serverURL}
                token={token}
                type="Assessment CF"
            />
        }
        {data.assessmentCFError !== "" && <div>{data.assessmentCFError}</div>}
        </TabPanel> 
        <TabPanel value='5'>
        {data.workflowCFError === "" && <RSTAssessmentTable 
                rows ={(data.workflowCFList || [])}
                isLoading={data.workflowCFLoading}
                columns ={WorkflowCustomFieldColumns}
                serverURL={serverURL}
                token={token}
                type="Workflow CF"
            />
        }
        {data.workflowCFError !== "" && <div>{data.workflowCFError}</div>}
        </TabPanel> 
        <TabPanel value='6'>
        {data.cfMetadataError === "" && <RSTAssessmentTable 
                rows ={(data.cfMetadataList || [])}
                isLoading={data.cfMetadataLoading}
                columns ={CustomFieldsMetaDataListColumns}
                serverURL={serverURL}
                token={token}
                type="CF Metadata"
            />
        }
        {data.cfMetadataError !== "" && <div>{data.cfMetadataError}</div>}
        </TabPanel> 
    </TabContext>
</Box>
)
}

export default CustomFieldControllersTables;