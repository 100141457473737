import './loginPanel.scss';
import React from 'react';
import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { set } from 'date-fns';
import { blueGrey } from '@mui/material/colors';

const LoginPanel = ({handleLogin, displayLoginMessage, displayLoginError , reset, isCollapsed}) => {
    const [serverURL, setServerURL] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    // console.log("LoginPanel: reset: ", reset);
    // console.log("LoginPanel: serverURL: ", serverURL);
    // console.log("LoginPanel: username: ", username);
    // console.log("LoginPanel: password: ", password);
    useEffect(() => {
        console.log("LoginPanel: useeffect: ");
        if(reset){
            console.log("LoginPanel: useEffect:", reset);
            setServerURL("");
            setUsername("");
            setPassword("");
        }
    }, [reset]);


    const handleLoginClick = (e) => {
        e.preventDefault();
        handleLogin(serverURL,username, password);
    }

    return (
        <>
           {!isCollapsed && 
            <form onSubmit={handleLoginClick} className='loginBox' >
             
                <div className="text" style={{marginTop:5}}>
                    Regional Server Login
                </div>
                <div>
                    <input
                        value={username}
                        type="Text"
                        placeholder="Username"
                        onChange={(e) => {setUsername(e.target.value)}}
                    />
                </div>
                <div className='inputBox'>
                    <input
                        value={password}
                        type="password"
                        // className = "input"
                        placeholder="password"
                        onChange={(e) => {setPassword(e.target.value)}}
                    />
                </div>
                <div>
                    <select
                    value={serverURL}
                    className="input"
                    onChange={(e) => {setServerURL(e.target.value)}}
                    placeholder="Select a server"
                    >
                        <option value="">Select a server</option>
                        <option value="https://kinetisensecloudeducation.azurewebsites.net">Education</option>
                        <option value="https://kinetisensecloudDevApp.azurewebsites.net">DEV</option>
                        <option value="https://kinetisensecloudeastusapp.azurewebsites.net">East US</option>
                        <option value="https://kinetisensecloudcentralcanada.azurewebsites.net">Canada Central</option>
                        <option value="https://kinetisensecloudwestusapp.azurewebsites.net">West US</option>
                        <option value="https://kinetisensecloudeastasiaapp.azurewebsites.net">East Asia</option>
                        <option value="https://kinetisensecloudwesteurope.azurewebsites.net">West Europe</option>
                        <option value="https://kinetisensecloudsoutheastaustralia.azurewebsites.net">Australia</option>
                        <option value="http://localhost:5000">Local - Test Only</option>
                        <option value="https://kinetisensedotnet8testserver-h2dzgya2g6ema4b5.canadacentral-01.azurewebsites.net">.net 8 server</option>

                    </select>
                </div>
                <div>
                    <button style={{height:25, width:60, color:"white", backgroundColor:"dodgerblue"}} >LOGIN</button>
                    {displayLoginMessage && <span style={{marginTop:5, marginLeft:20}}>Login Successful</span>}
                    {displayLoginError && <span style={{marginTop:5, marginLeft:20}}>Login Failed</span>}
                </div>
            </form>
        }
        </>
    );
}

export default LoginPanel;