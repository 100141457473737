export const ClinicData = {
    "name": "",
    "logo": "",
    "vatNumber": "",
    "isUsingCloud": false,
    "whiteLabelBackgroundPath": "",
    "whiteLabelLogoMainPath": "",
    "whiteLabelLogoReportPath": "",
    "whiteLabelSplashPath": "",
    "whiteLabelVideoPath": null,
    "whiteLabelColor1": "#",
    "whiteLabelColor2": "#",
    "whiteLabelLogoLoginPath": "",
    "whiteLabelClinicLogo": "",
    "whiteLabelDefaultPatientLogoPath": null,
    "whiteLabelWebsiteUrl": "",
    "websiteUrl": null,
    "bookingWebsiteUrl": null,
    "patientGroups": "",
    "isDeleted": false,
    "errorCode": null,
    "errorMessage": null,
    "addresses": [ ],
    "phones": [ ],
    "subscriptions": [ ],
    "practitioners": [],
    "workflows": [],
    "id": "",
    "version": "",
    "createdAt": "",
    "updatedAt": "",
    "deleted": false
}