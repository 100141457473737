// import "./datatable.scss";
// import "./licenceDataTable.scss";

import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import CustomFieldsDataTable from "../datatable/CustomFieldsDataTable";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import SuccessOkDialog from "../dialogs/SuccessOkDialog";
import CustomFieldsMetadata from "../../data/CustomFieldsMetadata";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import uuid from "react-uuid";
import { PatientFields, ClinicFields, PractitionerFields, AssessmentFields, AssessmentGroupFields } from "../../data/ControlledLists";
import { set } from "date-fns";
import WarningOkDialog from "../dialogs/WarningOkDialog";

const CustomFieldsEdit = ({ id, handleClose, handleSave}) => {

    const [data, setData] = useState(CustomFieldsMetadata);
    const [value, setValue] = useState('5');
    const [warningFlag, setWarningFlag] = useState(false);
    const [errorFlag, setErrorFlag] = useState("");
    const [savedFlag, setSavedFlag] = useState(false);
    const [message, setMessage] = useState("");
    const [metadata, setMetadata] = useState([]);
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    //console.log("Edit Data: ", data);
    console.log("metadata", metadata);
    // console.log("Flag: ",value)

    useEffect (() => {

      if (id === "new") {
      //convert json string to object
      
              setData(CustomFieldsMetadata);
              try{
                // setMetadata(JSON.parse(""));
                setMetadata(JSON.parse(CustomFieldsMetadata.metaData));
                setValue("1");
              }catch(e){
                console.log(e);
              }
      }
        else{
          try { 
            const sqlCommand = loginServerURL + "/tables/CustomFieldsMetaData/"+id;
            console.log(sqlCommand);
            fetch(sqlCommand, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                return response.json();
              } else {
                throw new Error(response.status);
              }
            })
            .then ((data) => {
                console.log("Organization fetched: ",data.queryable[0]);
                setMetadata(JSON.parse(data.queryable[0].metaData));
                setData(data.queryable[0])
                setValue("1");
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
      }

    },[id]);

   
    const handleOnSubmit = (event) => {
      event.preventDefault()
      setData();
    //   setSearch(!search);
    }

  const handleFieldUpdate = ( newValue, id) => {
    console.log(" Handle field Update Case: ",id,  newValue)
    console.log("Data: ", data);
    setErrorFlag(false);
    setSavedFlag(false);
    const updatedDate = Date.UTC;
    if (id ==='featureName') {
      console.log("I am HERE!");
        setData({...data, featureName: newValue });
    } else if (id ==='clinicCustomFieldTemplate') {
        const newmd = {...metadata, clinicCustomFieldTemplate: newValue, updatedAt: updatedDate };
        setMetadata(newmd);
        setData({...data, metaData: JSON.stringify(newmd), updatedAt: updatedDate })
      } else if (id ==='practitionerCustomFieldTemplate') {
        const newmd = {...metadata, practitionerCustomFieldTemplate: newValue, updatedAt: updatedDate };
        setMetadata(newmd);
        setData({...data, metaData: JSON.stringify(newmd), updatedAt: updatedDate })
      } else if (id ==='patientCustomFieldTemplate') {
        const newmd = {...metadata, patientCustomFieldTemplate: newValue, updatedAt: updatedDate };
        setMetadata(newmd);
        setData({...data, metaData: JSON.stringify(newmd), updatedAt: updatedDate })
      } else if (id ==='assessmentCustomFieldTemplate') {
        const newmd = {...metadata, assessmentCustomFieldTemplate: newValue, updatedAt: updatedDate };
        setMetadata(newmd);
        setData({...data, metaData: JSON.stringify(newmd), updatedAt: updatedDate })
      } else if (id ==='assessmentGroupCustomFieldTemplate') {
        const newmd = {...metadata, assessmentGroupCustomFieldTemplate: newValue, updatedAt: updatedDate };
        setMetadata(newmd);
        setData({...data, metaData: JSON.stringify(newmd), updatedAt: updatedDate })
    }  else {
        console.log("Missing: ",id)
    }
  }
    // this is for the tab panel
    const handleChange = (event, newValue) => {
      setValue(newValue);
    }

    const validateData = () => {
    console.log("Validate Data: ",metadata);
    console.log("Clinic: ",metadata.clinicCustomFieldTemplate);
        // if (!metadata.clinicCustomFieldTemplate === undefined) {
            if (!validateCustomFields(metadata.clinicCustomFieldTemplate, "Clinic")) {
              setWarningFlag(true);
              return false;
            }
        // }
        console.log("Practitioner: ",metadata.practitionerCustomFieldTemplate);
        // if (!metadata.practitionerCustomFieldTemplate === undefined) {
            if (!validateCustomFields(metadata.practitionerCustomFieldTemplate, "Practitioner")) {
              setWarningFlag(true);
              return false;
            }
        // }
        console.log("Patient: ",metadata.patientCustomFieldTemplate);
        // if (!metadata.patientCustomFieldTemplate === undefined) {
            if (!validateCustomFields(metadata.patientCustomFieldTemplate, "Patient")) {
              setWarningFlag(true);
              return false;
            }
        // }
        console.log("Assessment: ",metadata.assessmentCustomFieldTemplate);
        // if (!metadata.assessmentCustomFieldTemplate === undefined) {
            if (!validateCustomFields(metadata.assessmentCustomFieldTemplate, "Assessment")) {
              setWarningFlag(true);
              return false;
            }
        // }
        var metadata1 = metadata;
        if (metadata.assessmentGroupCustomFieldTemplate === undefined) {
          metadata1 = {...metadata1, assessmentGroupCustomFieldTemplate: []};
          setMetadata(metadata1);
        }
        console.log("AssessmentGroup: ",metadata1.assessmentGroupCustomFieldTemplate);
        // if (!metadata.assessmentCustomFieldTemplate === undefined) {
            if (!validateCustomFields(metadata1.assessmentGroupCustomFieldTemplate, "AssessmentGroup")) {
              setWarningFlag(true);
              return false;
            }
        // }

        return true;
    }

    const validateCustomFields = (cf, cfname) => {
      var success = true;
      setMessage("");
      setWarningFlag(false);
      console.log("Validate Custom Fields: ",cfname);
      cf.forEach((field) => {
        console.log("Field: ",field);
        if ((field.name === undefined || field.name === "") && success) {
          setMessage(cfname +": Name is required");
          success = false;
        }
        if ((field.type === undefined || field.type === "") && success) {
          setMessage(cfname + ": Type is required for " + field.name);
          success = false;
        }
        if ((field.label === undefined || field.label === "") && success) {
          setMessage(cfname+ ": Label is required for " + field.name);
          success = false;
        }
        if ((field.order === undefined || field.order === "") && success) {
          setMessage(cfname + ": Order is required for " + field.name);
          success = false;
        }
        // if ((field.groupName === undefined || field.groupName === "") && success) {
        //   setMessage(cfname + ": Group Name is required for " + field.name);
        //   success = false;
        // }
        if ((field.Type === "ControlledList" && field.options === "") && success) {
          setMessage(cfname + ": Options are required for " + field.name);
          success = false;
        }
        if (field.isValidated  && success) {
            if (field.min === undefined || field.min === "") {
              setMessage(cfname + ": Min is required for " + field.name);
              success = false;
            }
            if (field.max === undefined || field.max === "") {
              setMessage(cfname + ": Max is required for " + field.name);
              success = false;
            }
            if (field.min > field.max) {
              setMessage(cfname + ": Min is greater than Max for " + field.name);
              success = false;
            }
        }

        if (field.isConditional && success) {
          if (field.conditionalField === undefined || field.conditionalField === "") {
            setMessage(cfname + ": Conditional Field is required for " + field.name);
            success = false;
          }
          if (field.condition === undefined || field.condition === "") {
            setMessage(cfname + ": Condition is required for " + field.name);
            success =  false;
          }
          if (field.conditionalValue === undefined || field.conditionalValue === "") {
            setMessage(cfname + ": Conditional Value is required for " + field.name);
            success = false;
          }
        }

      });
      console.log("Success: ",success);
    return success;
    }
    const handleAdd = async (e) => {
      e.preventDefault();
      console.log("Save");
      setErrorFlag(false);
      setSavedFlag(false);
      if (validateData()) {
        var newMetadata = metadata;
        if (metadata.assessmentGroupCustomFieldTemplate === undefined) {
          newMetadata = {...newMetadata, assessmentGroupCustomFieldTemplate: []};
        }

        let action = "POST";
        let URL = loginServerURL + "/tables/CustomFieldsMetaData"
  
        console.log("Save CustomFieldsMetadata: ",data)
        const rec = data;
        if ((rec.id === null) || (rec.id === "") || (rec.id === undefined)){
          rec.id = uuid();
        }else{
          action = "PATCH";
          URL= URL +"/"+rec.id;
        }
        console.log("URL: ",URL);
        rec.metaData = JSON.stringify(newMetadata);
        try { 
            fetch(URL, {
                method: action,
                body: JSON.stringify(rec),
                headers:{
                  "X-ZUMO-AUTH": currentToken,
                  "Content-type":"application/json"
            }
            }).then ((data) => {
                console.log("CustomFields: ",data);
                if(data.status >= 200 && data.status < 300) {
                    setSavedFlag(true);
                    setErrorFlag(false);
                    handleSave();
                    handleClose();
                } else {
                    setErrorFlag(true);
                    setSavedFlag(false);
                }
            }).catch ((error) => {
                console.log(error);
                setErrorFlag(true);
                setSavedFlag(false);
            });  
        } catch(err) {
            console.log(err);
            setErrorFlag(true);
        }
      }
    };

    // build a list of allows fields to using the conditional custom fields
    const GetNames = (templates) => {
      console.log("Templates: ",templates);
      let names = [];
      templates.forEach((template) => {
        switch(template.name) {
          case "Clinic.CF.":
            ClinicFields.forEach((el) => {
              names.push(el.name);
            })
            break;
          case "Practitioner.CF.":
            PractitionerFields.forEach((el) => {
              names.push(el.name);
            })
            break;
          case "Patient.CF.":
            PatientFields.forEach((el) => {
              names.push(el.name);
            })
            break
          case "Assessment.CF.":
            AssessmentFields.forEach((el) => {
              names.push(el.name);
            })
            break;
          case "AssessmentGroup.CF.":
            AssessmentGroupFields.forEach((el) => {
              names.push(el.name);
            })
            break;
          default:
            break;
        }
       if (template.value !== undefined) {
          template.value.forEach((field) => {
              names.push(template.name + field.name);
          });
        }
      });
      return names;
  }

  // build the list toi do the field type validation for the conditional custom fields
  const BuildNameTypeList = () => {
      let names = [];
      ClinicFields.forEach((el) => {
        names.push({name: el.name, type: el.type});
      });
      PractitionerFields.forEach((el) => {
        names.push({name: el.name, type: el.type});
      });
      PatientFields.forEach((el) => {
        names.push({name:el.name, type: el.type});
      });
      AssessmentFields.forEach((el) => {
        names.push({name: el.name, type:el.type});
      });
      if (metadata.clinicCustomFieldTemplate !== undefined) {
          metadata.clinicCustomFieldTemplate.forEach((row) => {
              names.push({name: row.name, type: row.type});
          });
      }
      if (metadata.patientCustomFieldTemplate !== undefined) {
          metadata.patientCustomFieldTemplate.forEach((row) => {
            names.push({name: row.name, type: row.type});
          });
      }
      if (metadata.practitionerCustomFieldTemplate !== undefined) {
          metadata.practitionerCustomFieldTemplate.forEach((row) => {
              names.push({name: row.name, type: row.type});
          });
      }
      if (metadata.assessmentCustomFieldTemplate !== undefined) {
          metadata.assessmentCustomFieldTemplate.forEach((row) => {
            names.push({name: row.name, type: row.type});
          });
      }
      if (metadata.assessmentGroupCustomFieldTemplate !== undefined) {
          metadata.assessmentGroupCustomFieldTemplate.forEach((row) => {
            names.push({name: row.name, type: row.type});
          });
      }
      
    return names;
  }

  const handleCloseDisplayMessage = () => {
    setWarningFlag(false);
  }

  return (
    <>
        <WarningOkDialog open={warningFlag} message={message} handleClose={handleCloseDisplayMessage}/>
        <form onSubmit={handleAdd}>
            <div className="formInput" >
            <TextField
                style={{marginTop: 10, width: 400}}
                    required
                    id="featureName" 
                    label="Feature Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.featureName}
                    onChange={(event, value) => handleFieldUpdate(event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
              />
            <Box style={{width:1050}}>
                  <TabContext value={value} >
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="Clinic" value="1" />
                          <Tab label="Practitioner" value="2" />
                          <Tab label="Patient" value="3" />
                          <Tab label="Assessment" value="4" />
                          <Tab label="Workflow" value="5" />
                          </Tabs>
                      </Box>
                      <TabPanel value='1'>
                          <CustomFieldsDataTable 
                              customFields ={(metadata.clinicCustomFieldTemplate || [])}
                              onValueChange={(value) => handleFieldUpdate(value, "clinicCustomFieldTemplate")}
                              flag={value}
                              hideAssessmentColumn ={true}
                              hideAssessmentGroupColumn ={true}
                              cfnames={GetNames([{name: "Clinic.CF.", value: metadata.clinicCustomFieldTemplate}])}
                              />
                      </TabPanel>
                          <TabPanel value='2'>
                          <CustomFieldsDataTable 
                              customFields={(metadata.practitionerCustomFieldTemplate || [])}
                              onValueChange={(value) => handleFieldUpdate(value, "practitionerCustomFieldTemplate")}
                              flag={value}
                              hideAssessmentColumn ={true}
                              hideAssessmentGroupColumn ={true}
                              cfnames={GetNames([{name: "Clinic.CF.", value: metadata.clinicCustomFieldTemplate}, {name: "Practitioner.CF.", value: metadata.practitionerCustomFieldTemplate}])}
                              />
                      </TabPanel>
                      <TabPanel value='3'>
                          <CustomFieldsDataTable 
                              customFields={(metadata.patientCustomFieldTemplate || [])}
                              onValueChange={(value) => handleFieldUpdate(value, "patientCustomFieldTemplate")} 
                              flag={value}
                              hideAssessmentColumn ={true}
                              hideAssessmentGroupColumn ={true}
                              cfnames={GetNames([{name:"Clinic.CF.",value:metadata.clinicCustomFieldTemplate}, {name: "Practitioner.CF.", value: metadata.practitionerCustomFieldTemplate},{name: "Patient.CF.", value: metadata.patientCustomFieldTemplate}])}
                              />
                      </TabPanel> 
                      <TabPanel value='4'>
                          <CustomFieldsDataTable 
                              customFields ={(metadata.assessmentCustomFieldTemplate || [])}
                              onValueChange={(value) => handleFieldUpdate(value, "assessmentCustomFieldTemplate")} 
                              flag={value}
                              hideAssessmentColumn ={false}
                              hideAssessmentGroupColumn ={true}
                              cfnames={GetNames([{name:"Clinic.CF.",value:metadata.clinicCustomFieldTemplate},{name:"Practitioner.CF.",value: metadata.practitionerCustomFieldTemplate},{name:"Patient.CF.",value: metadata.patientCustomFieldTemplate}, {name:"Assessment.CF.",value:metadata.assessmentCustomFieldTemplate}])}
                          />
                      </TabPanel> 
                      <TabPanel value='5'>
                          <CustomFieldsDataTable 
                              customFields ={(metadata.assessmentGroupCustomFieldTemplate || [])}
                              onValueChange={(value) => handleFieldUpdate(value, "assessmentGroupCustomFieldTemplate")} 
                              flag={value}
                              hideAssessmentColumn ={true}
                              hideAssessmentGroupColumn ={false}
                              cfnames={GetNames([{name:"Clinic.CF.",value:metadata.clinicCustomFieldTemplate},{name:"Practitioner.CF.",value: metadata.practitionerCustomFieldTemplate},{name:"Patient.CF.",value: metadata.patientCustomFieldTemplate} ,{name:"AssessmentGroup.CF.",value:metadata.assessmentGroupCustomFieldTemplate}])}
                          />
                      </TabPanel> 
                  </TabContext>
              </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorFlag && <div className="error">An error occured</div>}
                        {savedFlag && <div className="message">Account Saved</div>}
                        {warningFlag && <div className="warning">{message}</div>}
                    </div>
                    <div className = "right">
                    <Button  
                            type="button"
                            variant="contained"
                            className="button"
                            onClick={handleClose}
                            >
                            Cancel
                        </Button>
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                            >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </>
  );
};




export default CustomFieldsEdit;
