import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { LicenceColumns, LicenceRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ViewLicenceDialog from "../dialogs/ViewLicencesDialog";
import EditLicenceDialog from "../dialogs/EditLicenceDialog";
import DeleteLicenceDialog from "../dialogs/DeleteLicenceDialog";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useSession } from "../../context/SessionContext";
import { RotatingLines } from "react-loader-spinner";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EmojiFlagsOutlinedIcon from '@mui/icons-material/EmojiFlagsOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LicenceUsageDialog from "../dialogs/LicenceUsageDialog";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Tooltip from '@mui/material/Tooltip';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const CustomToolbar= () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const LicenceDatatable = (props) => {

    const [rows, setRows] = useState([]);
    const [id, setId] = useState();
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [del, setDelete] = useState(false);
    const [licenceUsage, setLicenceUsage] = useState(false);
    const [searchString, setSearchString] = useState("");
    const session = useSession();
    const [search, setSearch] = useState(false);
    const [skip, setSkip] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [clinicId, setClinicId] = useState("");
    const [checked, setChecked] = useState(false);
    const [hideTestLicence, setHideTestLicence] = useState(false);

    console.log("CBActive: ", checked);

   
    
    // console.log("ROWS: ", rows)
    // console.log("Id: ",id);
    // console.log("Search Flag: ", search)
    useEffect (() => {
          console.log("UseEffect Session: ",session);
          console.log("Search flag: ", search);
          var ss = "";
          if (searchString=== "" || searchString === null){
            ss = "*empty*";
          }  else {
              ss = searchString;
          }
            let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=${skip}&top=1000&search=${ss}`
            console.log("queryData: ",queryString)
          try { 
            fetch(session.subscriptionServer + "/tables/subscription/" + queryString, {
                method: 'GET',
                headers:{"Content-type":"application/json"}
            }).then ((response) => response.json())
            .then ((data) => {
              setRows(rows.concat(data));
              setSkip(skip + data.length);
              if (data.length === 0) {
                setLoading(false);
              }
            }).catch ((error) => {
                console.log(error);
            });  
          } catch(err) {
            console.log(err);
          }
    },[search, skip]);

    const handleClearSearchString = () =>{
      
      setSearchString("");
    }

    const handleOnSubmit = (event) => {
      event.preventDefault()
      setRows([]);
      setSearch(!search);
      setSkip(0);
      setLoading(true);
    }

  const handleCloseEditDialog = () => {
    setEdit(false);
  }
  const handleCloseViewDialog = () => {
    setView(false);
  }
  const handleCloseDeleteDialog = () => {
    setDelete(false);
  }
  const handleCloseLicenceUsageDialog = () => {
    setLicenceUsage(false);
  }

  const handleEdit = async (id) =>{
    setEdit(true);
    setId(id);
    
  }
  const handleView = (id) => {
    setView(true);
    setId(id);
  }

  const handleDelete = (id) => {
    setDelete(true);
    setId(id);
  }

  const handleLicenceUsage = (id) => {
    setLicenceUsage(true);
    setId(id);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <DashboardCustomizeOutlinedIcon style={{color:"gray", cursor: "pointer"}} onClick={() => handleLicenceUsage(params.row.id)}>Dashboard</DashboardCustomizeOutlinedIcon>
            <RemoveRedEyeOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handleView(params.row.id)}>View</RemoveRedEyeOutlinedIcon>
              <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
            <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDelete(params.row.id)}>Delete</DeleteOutlineOutlinedIcon>
          </div>
        );
      },
    },
  ];

  const handleRemoveFromRows = (id) =>{
    const newList = rows.filter((r) => r.id !== id );
    setRows(newList);
  }

  const handleUpdateRows = (r) =>{
    console.log("handleUpdateRows: ", r)
      let i =0;
      let j = 0;
      rows.forEach((el) => {
          if (el.id === r.id) {
              j=i;
          }
          i++;
      })
      const newList = [...rows];
      newList[j]=r;
      setRows(newList);
  }

  const handleCBActiveChange = (event) => {
    setChecked(event.target.checked);
    console.log("checked: ", checked);
  }

  
const handleHideTestLicenceChange = (event) => {  
  setHideTestLicence(event.target.checked);
}

  const filterRows = (rows) => {
    var date = new Date();
    var date1 = date.toISOString().split("T")[0];
    console.log("date1: ", date1.split("T")[0]);
    return rows.filter((row) => row.expirationDate > date.toISOString().split("T")[0]);
  }

  const filterTestLicence = (rows, hide) => {
    if (hide) {
    return rows.filter((row) => row.isTestLicence === false);
    } else {
      return rows;
    }
  } 


  return (
    <>
      <EditLicenceDialog id={id} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows}/>
      <ViewLicenceDialog id={id} handleClose = {handleCloseViewDialog} open={view} />
      <DeleteLicenceDialog id={id} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
      <LicenceUsageDialog id={id} source="licence" handleClose = {handleCloseLicenceUsageDialog} open={licenceUsage} />      
    <div>
      <form onSubmit={handleOnSubmit} >
        <div className="search">
          <input type="text" 
              value={searchString}
              placeholder="Search..." 
              onChange ={event => setSearchString(event.target.value)}
          />
          <div>
          <Tooltip title="Clear" arrow enterDelay={1000}>
              <button type="button" onClick={handleClearSearchString} className="button">
                <HighlightOffOutlinedIcon />
              </button>
          </Tooltip>
          </div>
          <div>
          <Tooltip title="Search" arrow enterDelay={1000}>
              <button type="submit" className="button">
                <SearchOutlinedIcon />
              </button>
          </Tooltip>
          </div>
          <div>
            <Tooltip title="Only active licences" arrow enterDelay={1000}>
            <Checkbox
              id='ActiveOnly'
              checked={checked}
              onChange={handleCBActiveChange}
              icon={<FilterAltOutlinedIcon style={{color:"lightgray"}}/>}
              checkedIcon={<FilterAltOutlinedIcon style={{color:"green"}} />}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Hide Test Licences" arrow enterDelay={1000}>
            <Checkbox
              id='HideTestLicence'
              checked={hideTestLicence}
              onChange={handleHideTestLicenceChange}
              icon={<EmojiFlagsOutlinedIcon style={{color:"lightgray"}}/>}
              checkedIcon={<EmojiFlagsOutlinedIcon style={{color:"red"}} />}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
       
         
    
       
    { (isLoading || rows === null) &&
      <center style={{paddingTop:100}}>
          <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
      </center>
    }
    { (!isLoading && rows != null) &&
        <div className="datatable" style={{height:660}}>
          <div className="datatableTitle">
          Licences
            <Link to="/licences/new" style={{border: "0px"}}>
              <AddCircleOutlineOutlinedIcon style={{color:"green", cursor: "pointer"}} >Add Licence</AddCircleOutlineOutlinedIcon>
            </Link>
          </div>
          {!checked && <DataGrid
            className="datagrid"
            rows={filterTestLicence(rows, hideTestLicence)} 
            columns={LicenceColumns.concat(actionColumn)}
            pageSize={15}
            rowsPerPageOptions={[15]}
            //checkboxSelection
            density='compact'
            slots={{
              toolbar: CustomToolbar,
            }}
          />}
           {checked && <DataGrid
            className="datagrid"
            rows={filterTestLicence(filterRows(rows),hideTestLicence)} 
            columns={LicenceColumns.concat(actionColumn)}
            pageSize={15}
            rowsPerPageOptions={[15]}
            //checkboxSelection
            density='compact'
            slots={{
              toolbar: CustomToolbar,
            }}
          />}
          
        </div>
    }
    </>
  );
};




export default LicenceDatatable;
